import styled from 'styled-components';

import { Tabs as tabs } from 'antd';

const tabPane = tabs.TabPane;

export const Tabs = styled(tabs)`
  height: auto;

  .ant-tabs-bar {
    border: 0 none;
  }

  .ant-tabs-tab {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #fff;
    color: #fff;
    padding: 8px !important;
    margin: 0 15px !important;

    &-active {
      color: #fff !important;
    }
    padding: 8px !important;
    margin: 0 15px !important;

    &-active,
    &:hover {
      color: #fff !important;
    }
  }

  .ant-tabs-ink-bar {
    background-color: #fff;
    height: 4px;
  }
`;

export const TabPane = styled(tabPane)``;

export const Container = styled.div`
  width: 100%;
  z-index: 3;
  height: 48px;
  justify-content: center;
  align-items: center;
`;
