import React, { createContext, useState } from 'react';

type SearchScopeType = 0 | 1;

interface NavbarContextData {
  searchCount: number;
  setSearchCount: (arg: number) => void;
  totalActiveFilters: number;
  getActiveFilters: (arg: number) => void;
  dateIntervalScope: string;
  getDateIntervalScope: (arg: string) => void;
  searchOpen: boolean;
  setSearchOpen: (arg: boolean) => void;
  searchString: string;
  setSearchString: (value: string) => void;
  searchScope: number;
  setSearchScope: (value: SearchScopeType) => void;
}

export const NavbarContext = createContext<NavbarContextData>(
  {} as NavbarContextData
);

export const NavbarProvider: React.FC = ({ children }) => {
  const [searchCount, setSearchCount] = useState<number>(0);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [totalActiveFilters, setTotalActiveFilters] = useState(0);
  const [searchScope, setSearchScope] = useState<SearchScopeType>(0);
  const [searchString, setSearchString] = useState<string>('');
  const [dateIntervalScope, setDateIntervalScope] =
    useState('Em todas as datas');

  const getActiveFilters = (v: any) => setTotalActiveFilters(v);
  const getDateIntervalScope = (v: any) => setDateIntervalScope(v);

  return (
    <NavbarContext.Provider
      value={{
        searchCount,
        setSearchCount,
        searchOpen,
        setSearchOpen,
        totalActiveFilters,
        getActiveFilters,
        dateIntervalScope,
        getDateIntervalScope,
        searchString,
        setSearchString,
        searchScope,
        setSearchScope
      }}>
      {children}
    </NavbarContext.Provider>
  );
};
