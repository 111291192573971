import { Button, Icon, Modal } from 'antd';
import React from 'react';
import { FolderSelectionModal } from '../../../../../components/ContentMenu/FolderSelection/FolderSelectionModal';
import { ContentType } from '../../../../../enums/ContentType';
import { ContentSearchResult } from '../../../../../models/ContentSearchResult';
import { ArticleType } from '../../../../pesquisa/enums/ArticleType';

export const AddToFolder = ({ options }) => {
  const getResult = (it: any) => {
    if (!Object.keys(it).length) return;

    const base = `https://${process.env.REACT_APP_PUBLIC_BUCKET}.s3-sa-east-1.amazonaws.com`;
    const idSplited = String(it.id || '').split('-');
    const periodicNumId = idSplited && idSplited[0] ? idSplited[0] : '';
    const editionNumId = idSplited && idSplited[1] ? idSplited[1] : '';
    const imgUrl = `${base}/periodics/cover/${periodicNumId}/${periodicNumId}.jpg`;

    return {
      url: '',
      subTitle: '',
      formattedDate: '',
      total: 0,
      parentId: periodicNumId,
      editionId: editionNumId,
      relevantWords: null,
      contentType: ContentType.PERIODIC,
      articleType: ArticleType.TODOS,
      id: periodicNumId,
      title: it.text_1,
      text: it.text_2,
      tags: [{ text: 'Revista', color: 'red' }],
      img: imgUrl,
      author: it.tags
        .filter((e) => String(e.id).startsWith('author'))
        .map((e) => e.title)
        .join(', '),
      areasInterest: it.tags
        .filter((e) => String(e.id).startsWith('area-interest'))
        .map((e) => e.title)
        .join(', ')
    };
  };

  const close = () => {
    options.setShowAddToFolder(false);
  };

  const footer = [
    <Button type={'primary'} key={'btn-1'} onClick={close}>
      <Icon type="close" />
      Fechar
    </Button>
  ];

  const result: ContentSearchResult = getResult(options.folderItem);

  return (
    <Modal
      maskClosable={true}
      title="Adicionar à pasta"
      visible={options.showAddToFolder}
      onCancel={close}
      onOk={close}
      footer={footer}
      maskStyle={{ background: 'rgba(0,0,0,.2)' }}>
      <FolderSelectionModal
        result={result}
        visible={options.showAddToFolder}
        onCancel={close}
      />
    </Modal>
  );
};
