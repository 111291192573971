import React from 'react';
import { ContentSearchResult } from '../models/ContentSearchResult';
import { DefaultContentListItem } from './DefaultContentListItem/DefaultContentListItem';
import { ContentType } from '../enums/ContentType';

interface IProps {
  result: ContentSearchResult;
}

export const InformativeCoverListItem = ({ result, ...props }: IProps) => {
  function getCoverImage() {
    const canvas = document.createElement('canvas');
    canvas.width = 180 * 4;
    canvas.height = 260 * 4;
    const context = canvas.getContext('2d')!;
    context.rect(0, 0, 180 * 4, 260 * 4);
    context.fillStyle = '#024D92';
    context.fill();
    context.font = 'bold 73px "Segoe UI", sans-serif';
    context.fillStyle = 'white';
    const textSplited = String(result.title).split(': ');
    context.fillText(textSplited[0], 6 * 4, 110 * 4);
    context.fillText(textSplited[1], 45 * 4, 140 * 4);
    return canvas.toDataURL('image/png');
  }

  return (
    <DefaultContentListItem
      {...props}
      linkTo={`${process.env.REACT_APP_BASE_UI_URL}${result.url}`}
      linkToParent={`${process.env.REACT_APP_BASE_UI_URL}${result.url}`}
      result={{
        ...result,
        img: getCoverImage(),
        tags: []
      }}
    />
  );
};
