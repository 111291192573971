import React, { useContext } from 'react';
import { CodesFiltersContext } from '../../CodesFiltersContext';
import { TimedFetch } from './use-cases/TimedFetch';

export const CodesFilter = ({}) => {
  const {
    codesVisible,
    codesSearchValue,
    codesLoading,
    codesLoaded,
    codesError,
    codesData,
    codesRI,
    setCodesVisible,
    setCodesSearchValue,
    setCodesLoading,
    setCodesLoaded,
    setCodesError,
    setCodesData,
    setCodesRI,
    updateData,
    setPage
  } = useContext(CodesFiltersContext);

  return (
    <TimedFetch
      segment="codes"
      placeholder="Códigos"
      visible={codesVisible}
      searchValue={codesSearchValue}
      loading={codesLoading}
      loaded={codesLoaded}
      error={codesError}
      data={codesData}
      ri={codesRI}
      setVisible={setCodesVisible}
      setSearchValue={setCodesSearchValue}
      setLoading={setCodesLoading}
      setLoaded={setCodesLoaded}
      setError={setCodesError}
      setData={setCodesData}
      setRI={setCodesRI}
      updateData={updateData}
      setPage={setPage}
      fetchOnMount={true}
    />
  );
};
