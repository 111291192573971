import React, { useState } from 'react';
import { Book } from '../../interfaces/Book';

import VoiceIcon from '../../../../../assets/images/icon-voice.svg';
import { BookInfo, BookInfoPopover, BookInnerFields } from '../../styles';

export const Author = ({ item }) => {
  const [authorPopover, setAuthorPopover] = useState(false);
  const handleAuthorPopover = () => setAuthorPopover(!authorPopover);

  const author = (el: Book) =>
    el.tags
      .filter((e) => String(e.id).includes('author'))
      .map((e) => e.title)
      .join(', ');
  const authors = author(item);

  return authors ? (
    <BookInnerFields>
      <BookInfo
        className="info-authors"
        onMouseEnter={handleAuthorPopover}
        onMouseLeave={handleAuthorPopover}>
        <img src={VoiceIcon} />
        <span>
          {authors.length > 150 ? `${authors.slice(0, 150)}...` : authors}
        </span>
        {authorPopover && <BookInfoPopover>{authors}</BookInfoPopover>}
      </BookInfo>
    </BookInnerFields>
  ) : (
    <></>
  );
};
