import React, { createContext, useState } from 'react';
import { OrderType } from '../../../components/v2/searchHelpers';

interface InformativesFiltersContextData {
  submit: number;
  updateData: () => void;

  order: OrderType;
  setOrder: (v: OrderType) => void;

  page: Number;
  setPage: (v: Number) => void;

  advancedVisible: boolean;
  advancedData: any;
  setAdvancedVisible: (v: boolean) => void;
  setAdvancedData: (v: object[]) => void;

  dateVisible: boolean;
  dateLoading: boolean;
  dateLoaded: boolean;
  dateError: boolean;
  dateData: any;
  dateRI: number;
  setDateVisible: (v: boolean) => void;
  setDateLoading: (v: boolean) => void;
  setDateLoaded: (v: boolean) => void;
  setDateError: (v: boolean) => void;
  setDateData: (v: any) => void;
  setDateRI: (v: number) => void;

  informativesVisible: boolean;
  informativesSearchValue: string;
  informativesLoading: boolean;
  informativesLoaded: boolean;
  informativesError: boolean;
  informativesData: any;
  informativesRI: number;
  setInformativesVisible: (v: boolean) => void;
  setInformativesSearchValue: (v: string) => void;
  setInformativesLoading: (v: boolean) => void;
  setInformativesLoaded: (v: boolean) => void;
  setInformativesError: (v: boolean) => void;
  setInformativesData: (v: any) => void;
  setInformativesRI: (v: number) => void;
}

export const InformativesFiltersContext =
  createContext<InformativesFiltersContextData>(
    {} as InformativesFiltersContextData
  );

export const InformativesFiltersProvider: React.FC = ({ children }) => {
  const [submit, setSubmit] = useState<number>(0);
  const [order, setOrder] = useState<OrderType>('relevance');
  const [page, setPage] = useState<Number>(1);

  const [advancedVisible, setAdvancedVisible] = useState<boolean>(false);
  const [advancedData, setAdvancedData] = useState<object[]>([
    { type: 'AND', text: '', exact: false, searchInTitle: false }
  ]);

  const [informativesVisible, setInformativesVisible] =
    useState<boolean>(false);
  const [informativesSearchValue, setInformativesSearchValue] =
    useState<string>('');
  const [informativesLoading, setInformativesLoading] =
    useState<boolean>(false);
  const [informativesLoaded, setInformativesLoaded] = useState<boolean>(false);
  const [informativesError, setInformativesError] = useState<boolean>(false);
  const [informativesData, setInformativesData] = useState<any>([]);
  const [informativesRI, setInformativesRI] = useState<number>(0);

  const [dateVisible, setDateVisible] = useState<boolean>(false);
  const [dateLoading, setDateLoading] = useState<boolean>(false);
  const [dateLoaded, setDateLoaded] = useState<boolean>(false);
  const [dateError, setDateError] = useState<boolean>(false);
  const [dateData, setDateData] = useState<any>([]);
  const [dateRI, setDateRI] = useState<number>(0);

  const updateData = () => setSubmit(submit + 1);

  return (
    <InformativesFiltersContext.Provider
      value={{
        submit,
        updateData,

        order,
        setOrder,

        page,
        setPage,

        advancedData,
        advancedVisible,
        setAdvancedData,
        setAdvancedVisible,

        dateData,
        dateError,
        dateLoaded,
        dateLoading,
        dateRI,
        dateVisible,
        setDateData,
        setDateError,
        setDateLoaded,
        setDateLoading,
        setDateRI,
        setDateVisible,

        informativesData,
        informativesError,
        informativesLoaded,
        informativesLoading,
        informativesRI,
        informativesSearchValue,
        informativesVisible,
        setInformativesData,
        setInformativesError,
        setInformativesLoaded,
        setInformativesLoading,
        setInformativesRI,
        setInformativesSearchValue,
        setInformativesVisible
      }}>
      {children}
    </InformativesFiltersContext.Provider>
  );
};
