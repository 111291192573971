/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable indent */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-throw-literal */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */

import React from 'react';
import _ from 'lodash';
import { useHistory, Route } from 'react-router';
import { PeriodicContextProvider } from '../pages/periodico/PeriodicContextProvider';
import ReadArticlePage from '../pages/periodico/ReadArticlePage';
import { PeriodicPage } from '../pages/periodico/PeriodicPage';
import ReadEspecialPage from '../pages/periodico/ReadEspecialPage';

export const PeriodicRoutes = (props: any) => {
  const history = useHistory();
  const { REACT_APP_NEW_PERIODIC_VERSION } = process.env;
  const newPeriodicVersion = String(REACT_APP_NEW_PERIODIC_VERSION) === 'true';
  return (
    <Route
      path={`/periodico/:periodicId/:editionId?/:articleId?`}
      render={(props: any) => {
        const periodicId = _.get(props, 'match.params.periodicId');
        const editionId = _.get(props, 'match.params.editionId');
        const itemId = _.get(props, 'match.params.articleId');
        const newPeriodicId =
          periodicId && !String(periodicId).includes('P')
            ? `P${periodicId}`
            : periodicId;

        const newEditionId =
          editionId && !String(editionId).includes('E')
            ? `E${editionId}`
            : editionId;

        const url = `/v2/revista/${newPeriodicId}${
          editionId ? `/${newEditionId}` : ''
        }${itemId ? `/${itemId}` : ''}`;
        if (newPeriodicVersion) history.push(url);
        return (
          <PeriodicContextProvider {...props}>
            <>
              {itemId && <ReadArticlePage {...props} />}

              <PeriodicPage {...props} />
            </>
          </PeriodicContextProvider>
        );
      }}
    />
  );
};

export const PeriodicSpecialPageRoutes = (props: any) => (
  <Route
    path="/:type/periodico/:periodicId"
    render={(props: any) => {
      const type = _.get(props, 'match.params.type');

      return (
        <PeriodicContextProvider {...props}>
          <>
            {type === 'expediente' || type === 'normas' ? (
              <ReadEspecialPage type={type} {...props} />
            ) : (
              <p></p>
            )}
          </>
        </PeriodicContextProvider>
      );
    }}
  />
);
