import { PeriodicEditionItemModel } from '../../../../models/periodic/PeriodicEditionItemModel';
import { http_client } from '../../../../util/http_client';
import { RequestUtil } from '../../../../util/RequestUtil';
import { PeriodicEditionInfoModel } from '../../../../models/periodic/PeriodicEditionInfoModel';

export class PeriodicRequests {
  static async getPeriodicEditions(periodicId: string) {
    const searchUrl = `${process.env.REACT_APP_CONTENT_PERIODIC_API}/periodics`;

    const result = await http_client.get<any>(`${searchUrl}/${periodicId}`);

    if (result.status !== 200) {
      return result.status;
    }
    const periodic = result.data;

    const editions = await http_client.get<any>(
      `${searchUrl}/${periodicId}/editions`
    );

    const editionsArray: any[] =
      editions && editions.data && editions.data.length ? editions.data : [];

    const data =
      result && result.data && result.data.numId ? editionsArray : [];

    const dataOrder = data.sort((a, b) =>
      a.number < b.number ? 1 : b.number < a.number ? -1 : 0
    );

    return dataOrder.map((edition) => {
      const startMonth = new Date(edition.startMonth);
      let monthStart = startMonth.toLocaleString('pt-BR', {
        month: 'short',
        timeZone: 'UTC'
      });
      monthStart = monthStart[0].toUpperCase() + monthStart.slice(1);
      const endMonth = new Date(edition.endMonth);
      let monthEnd = endMonth.toLocaleString('pt-BR', {
        month: 'short',
        timeZone: 'UTC'
      });
      monthEnd = monthEnd[0].toUpperCase() + monthEnd.slice(1);
      const year = startMonth.getFullYear();
      const isMontly = edition.frequency === 'Mensal';
      const editionDate =
        monthStart === monthEnd || isMontly
          ? ` Vol. ${year}, ${monthStart} - ${year}`
          : ` Vol. ${year}, ${monthStart}/${monthEnd} - ${year}`;

      return new PeriodicEditionInfoModel(
        edition.numId || '',
        edition.title || '',
        edition.number || '',
        edition.startMonth || '',
        edition.endMonth || '',
        edition.startYear || '',
        edition.frequency || '',
        editionDate,
        periodic.numId || '',
        periodic.publishType || '',
        periodic.acronym || '',
        periodic.title || '',
        periodic.description || '',
        periodic.cdu || '',
        periodic.cdd || '',
        periodic.issn || '',
        periodic.issnImpresso || '',
        periodic.qualisSeal || '',
        periodic.tags || [],
        periodic.expedientKey || '',
        periodic.publicationStandardKey || ''
      );
    });
  }

  static async getEditionItems(periodicId: string, editionId: string) {
    const searchUrl = `${process.env.REACT_APP_CONTENT_PERIODIC_API}/periodics/${periodicId}`;

    const result = await http_client.get<ItemDto[]>(
      `${searchUrl}/editions/${editionId}/items`
    );

    if (result.status !== 200) {
      return result.status;
    }

    let data: any = [];
    if (result.data.length) {
      data = result.data.map((c: any) => ({
        id: c.numId || '',
        title: c.title || '',
        itemType: c.itemType || '',
        order: c.order,
        author: c && c.authors ? c.authors.map((a) => a.title).join(', ') : '',
        pdfName: c.articleKey || ''
      }));
    }

    const dataOrder = data.sort((a, b) =>
      a.order < b.order ? 1 : a.order > b.order ? -1 : 0
    );

    return dataOrder.map(
      (item: any) =>
        new PeriodicEditionItemModel(
          item.id,
          item.title,
          this.GetItemType(item.itemType),
          item.author,
          item.pdfName
        )
    );
  }

  static async getItemSignedUrl(
    periodicId: string,
    editionId: string,
    itemId: string
  ) {
    const searchUrl = `${process.env.REACT_APP_CONTENT_PERIODIC_API}/periodics/${periodicId}`;

    const result = await http_client.get<ItemDto[]>(
      `${searchUrl}/editions/${editionId}/items/${itemId}/signed-url`
    );

    return result.data;
  }

  static async postLogItem(itemId: string) {
    return RequestUtil.createPostRequest(
      `api/periodic/postLogViewItem/${itemId}`
    )();
  }

  private static GetItemType(itemType: string) {
    switch (itemType) {
      case 'jurisprudence':
        return 'Jurisprudência';
      case 'doctrine':
        return 'Doutrina';
      case 'jurisprudential-trends':
        return 'Tendências Jurisprudenciais';
    }
    return 'Artigo';
  }
}

type Tag = {
  id: string;
  title: string;
};

type ItemDto = {
  id?: string;
  numId: string;
  editionNumId: string;
  title: string;
  itemType: string;
  keywords: Tag[];
  authors: Tag[];
  themes: Tag[];
  order: number;
  articleKey?: string;
};
