/* eslint-disable prefer-destructuring */
/* eslint-disable prettier/prettier */
import React, { useCallback, useContext, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { ContentNotAvailable } from '../../../components/ContentNotAvailable';
import { ErrorMessage } from '../../../components/ErrorMessage';
import { StartReadingButton } from '../../../components/StartReadingButton';
import { MultipleFilteredSelectParser } from '../../../components/_inputs/MultipleFilteredSelect';
import { BackToTopButton } from '../../../components/_shell/BackToTopButton';
import { ContentBelowHeader } from '../../../components/_templates/ContentBelowHeader';
import { ContentPageTemplate } from '../../../components/_templates/ContentPageTemplate/ContentPageTemplate';
import { ContentType } from '../../../enums/ContentType';
import { useReturnUrlFromPdf } from '../../../Hooks/useReturnUrlFromPdf';
import { BookContextModel } from '../../../models/book/BookContextModel';
import { ContentSearchResult } from '../../../models/ContentSearchResult';
import { SearchFilter } from '../../../models/search/SearchFilter';
import { BookContext } from './BookContextProvider';
import { BookEditionChapterList } from './BookEditionChapterList';
import _ from 'lodash';
import slug from '../_shared/slug';

import 'react-image-lightbox/style.css';

export const BookView = ({ match, history }: RouteComponentProps) => {
  const { returnToHereFromPdf } = useReturnUrlFromPdf();
  const ctx = useContext<{ state: BookContextModel }>(BookContext as any);
  const { bookEditionsRequest, editionChaptersRequest } = ctx.state;
  const editions =
    bookEditionsRequest.result !== 204 ? bookEditionsRequest.result || [] : [];
  const editionIdParam = _.get(match, 'params.editionId', null) || '';
  const editionId = editionIdParam.replace('L', 'E');
  const chapterId = _.get(match, 'params.chapterId') || '';

  const book = editions.filter(
    (edition: { editionId: string }) =>
      String(edition.editionId) === String(editionId)
  )[0];

  const bookId: string = _.get(match, 'params.bookId') || '';
  const firstChapterOfEdition = _.get(editionChaptersRequest, 'result[0]');

  useEffect(() => {
    if (chapterId && Number.isInteger(Number(chapterId))) {
      return;
    }
    if (editionId) {
      if (!editions || !editions.length) return;
      history.replace(
        `/v2/livro/${bookId}/${editions[0].editionId}${
          editions[0].title ? `/${slug(editions[0].title)}` : ''
        }`
      );
      return;
    }

    if (editions.length > 0) {
      history.replace(
        `/v2/livro/${bookId}/${editions[0].editionId}${
          editions[0].title ? `/${slug(editions[0].title)}` : ''
        }`
      );
    }
  }, [editions]);

  useEffect(() => {
    if (!chapterId || !Number.isInteger(Number(chapterId))) {
      setTimeout(() => returnToHereFromPdf(), 250);
    }
  }, [bookEditionsRequest.loading, editionId]);

  function getEditionTitle(editionId: number) {
    let currentEdition;

    if (editions) {
      currentEdition = editions.filter(
        (item) => item.editionId === editionId
      )[0];
      if (currentEdition) {
        return `Conteúdo da ${currentEdition.editionNumber}ª edição, Ano ${currentEdition.year}`;
      }
    }
  }

  const onSearch = useCallback(
    (words: string) => {
      const filter = new SearchFilter(words);
      filter.bookIdList = MultipleFilteredSelectParser.encodeValue({
        value: bookId,
        text: book.title
      });
      const e =
        editions && editions.length
          ? editions.find((f) => f.editionId === editionId)
          : undefined;
      history.push(
        `/v2/livro/${bookId}/${editionId}${e ? `/${slug(e.title)}` : ''}`
      );
    },
    [book, bookId]
  );
  const stage = process.env.REACT_APP_STAGE || 'staging';
  const bookImageSrc = `https://bid1006-${stage}-public.s3.sa-east-1.amazonaws.com/books/cover/${bookId}/editions/${editionId}.jpg`;

  const convertBookToContentSearchResult = useCallback(
    () =>
      !book
        ? ContentSearchResult.Empty
        : new ContentSearchResult(
            ContentType.BOOK,
            bookId,
            book.title,
            book.subtitle,
            book.description,
            null,
            editionId,
            bookId,
            bookImageSrc,
            book.author,
            null,
            `${book.year}`
          ),
    [bookId, book, editionId]
  );

  if (bookEditionsRequest.error) {
    return (
      <ContentBelowHeader fullscreen={false}>
        <ErrorMessage error={bookEditionsRequest.error} />
      </ContentBelowHeader>
    );
  }

  if (
    bookEditionsRequest.result === 204 ||
    editionChaptersRequest.result === 204
  ) {
    return <ContentNotAvailable />;
  }

  return (
    <>
      <ContentPageTemplate
        result={convertBookToContentSearchResult()}
        loading={bookEditionsRequest.loading}
        title={_.get(book, 'title')}
        imageSrc={bookImageSrc}
        editions={editions.map((e) => ({ ...e, id: e.editionId } || []))}
        selectedEditionId={editionId}
        contentType="Livro"
        subtitle={_.get(book, 'subtitle')}
        onEditionClick={(editionId: number) => {
          const e =
            editions && editions.length
              ? editions.find((f) => f.editionId === editionId)
              : undefined;
          history.push(
            `/v2/livro/${bookId}/${editionId}${e ? `/${slug(e.title)}` : ''}`
          );
          window.scroll(0, 0);
        }}
        DescriptionContent={<p>{_.get(book, 'description')}</p>}
        onSearch={onSearch}
        buttonSearchText="Pesquisar neste livro"
        DetailsContent={
          <>
            <ul>
              <li>
                <b>Autor(es):</b> {_.get(book, 'author')}
              </li>
              <li>
                <b>CDD:</b> {_.get(book, 'cdd')}
              </li>
              <li>
                <b>CDU: </b> {_.get(book, 'cdu')}
              </li>
              <li>
                <b>Nº de páginas: </b> {_.get(book, 'numberOfPages')}
              </li>
              <li>
                <b>ISBN: </b> {_.get(book, 'isbn')}
              </li>
              <li>
                <b>Ano: </b> {_.get(book, 'year')}
              </li>
              <li>
                <b>{_.get(book, 'serie')}</b>
              </li>
              <li>
                <b>{`${_.get(book, 'editionNumber')}ª`} Edição</b>
              </li>
            </ul>

            <div className="buttons-container">
              <StartReadingButton
                disabled={!firstChapterOfEdition}
                onClick={() => {
                  if ('localStorage' in window) {
                    localStorage.setItem(
                      'pdfReturnTo',
                      `/v2/livro/${bookId}/${editionId}`
                    );
                  }
                  history.push(
                    `/v2/livro/${bookId}/${editionId}/${firstChapterOfEdition.id}`
                  );
                }}>
                Iniciar leitura
              </StartReadingButton>
            </div>
          </>
        }
        BottomContent={
          <>
            <h2>{getEditionTitle(editionId)}</h2>

            {editionId && <BookEditionChapterList {...{ book, editionId }} />}
          </>
        }
      />
      <BackToTopButton />
    </>
  );
};
