import React from 'react';
import { ContainerNoAccess } from './styles';

export const NoAccessToAnyContent: React.FC = () => (
  <ContainerNoAccess>
    <h1>Nenhum conteúdo contratado</h1>
    <p>
      Não encontramos nenhum produto contratado para este perfil. Em caso de
      dúvidas, contate o gestor de sua instituição.
    </p>
  </ContainerNoAccess>
);
