import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

interface HotjarProps {
  hjid: number;
  hjsv: number;
}

interface HotjarWindow extends Window {
  hj?: {
    (method: string, ...args: any[]): void;
    q?: any[];
    identify?: (...args: any[]) => void;
    tagRecording?: (...args: any[]) => void;
    stateChange?: (...args: any[]) => void;
    trigger?: (...args: any[]) => void;
    vp?: number;
    _vars?: any[];
  };
}

const hotjarWindow: HotjarWindow = window;

const useHotjar = ({ hjid, hjsv }: HotjarProps) => {
  const history = useHistory();

  useEffect(() => {
    // Instale o script do Hotjar
    const hotjarScript = document.createElement('script');
    hotjarScript.innerHTML = `   (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:1883039,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

    document.head.appendChild(hotjarScript);

    // Inicialize o Hotjar
    hotjarWindow.hj =
      hotjarWindow.hj ||
      function () {
        (hotjarWindow.hj.q = hotjarWindow.hj.q || []).push(arguments);
      };

    // Rastreie a mudança de rota
    history.listen((location) => {
      hotjarWindow.hj('vpv', location.pathname);
    });
  }, [hjid, hjsv, history]);

  const trackEvent = (
    category: string,
    action: string,
    label?: string,
    value?: number
  ) => {
    hotjarWindow.hj('trigger', category, { action, label, value });
  };

  return { trackEvent };
};

export default useHotjar;
