import styled, { keyframes } from 'styled-components';
import { Input as input, Select as select, Radio } from 'antd';
import { device } from '../../styledComponent';

const Radiogroup = Radio.Group;

export const zoomInOut = keyframes`
	0% {
		transform: scale(1, 1);
	}

	50% {
		transform: scale(1.1, 1.04);
	}

	100% {
		transform: scale(1, 1);
	}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 22px;
  order: 3;
  width: 100%;

  &.closed {
    display: none;
  }

  @media ${device.sm} {
    &.closed {
      display: flex;
    }
  }

  @media ${device.lg} {
    width: auto;
    order: 2;
    margin: 0 auto;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

export const WrapMobile = styled.div`
  display: flex;
`;

export const Form = styled.div`
  display: flex;
`;

export const Button = styled.button`
  width: 44px;
  height: 40px;
  background-color: transparent;
  border-radius: 8px;
  border: none;
  margin-left: 8px;
  border: 2px solid #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:active {
    animation: ${zoomInOut} 0.3s ease-in-out;
  }
`;

export const SearchButtonMobile = styled.button`
  width: 38px;
  height: 36px;
  background-color: #d7282f;
  border-radius: 8px;
  border: none;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-direction: column;
  cursor: pointer;
  order: 1;

  &:active {
    animation: ${zoomInOut} 0.3s ease-in-out;
  }

  @media ${device.sm} {
    display: none;
  }
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

export const Input = styled(input)`
  &.ant-input {
    background-color: #f1f3f5;
    color: #848384;
    min-width: 240px;
    border-radius: 8px !important;
    height: 40px !important;
    margin-bottom: 8px !important;
    border: 0 none;
    transition: all ease-in-out 1s;

    @media ${device.md} {
      min-width: 360px;
    }

    @media ${device.xl} {
      min-width: 500px;
    }

    @media ${device.xl2} {
      min-width: 700px;
    }
  }
`;

export const RadioGroup = styled(Radiogroup)`
  .ant-radio-checked .ant-radio-inner {
    border-color: #003a70 !important ;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: #003a70;
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: #003a70;
  }
  .ant-radio-wrapper {
    color: #fff;
    font-size: 12px;
  }
  .ant-radio {
    margin-right: -4px;
  }
`;

export const GlobalNavBarAdvanced = styled.div`
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  width: 642px;
  padding: 5px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding-bottom: 8px;

  span {
    white-space: pre;
    margin-right: 0px;
  }

  input[type='text'] {
    width: 300px;
  }

  .pointer {
    cursor: pointer;
    user-select: none;
  }

  .label {
    padding-top: 8px;
    font-weight: 500;
  }

  .mt-5 {
    margin-top: 10px;
  }

  .ant-checkbox-wrapper {
    width: 112px;
    margin-left: 10px;

    .ant-checkbox {
      margin: 0;

      input,
      + span,
      .ant-checkbox-inner {
        margin: 0;
      }
    }
  }
`;

export const GlobalNavBarContainer = styled.div`
  .filter-btn,
  .filter-btn-alter {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35.2px;
    font-size: 16px;
    font-weight: 600;
    margin-right: 16px;
    border: 2px solid #004c92;
    border-radius: 8px;
    background-color: #fff;
    cursor: pointer;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 16px;
    padding-right: 16px;
    color: #004c92;

    &:hover {
      background-color: #004c92;
      border: 2px solid #004c92;
      color: #fff;
    }

    &:focus {
      border-color: #fff;
    }

    .ant-badge {
      position: absolute;
      top: -11px;
      right: -11px;

      p {
        color: #fff;
        font-size: 12px;
        font-weight: 700;
      }
    }
  }

  .filter-btn-alter {
    background-color: #004c92;
    color: #fff;
    border-color: #004c92;

    &:hover,
    &:focus,
    &:active {
      background-color: #004c92;
      border-color: #004c92;
    }
  }
`;

export const Select = styled(select)`
  .ant-select-selection {
    border: 0 none;
  }
  .ant-select-selection:focus {
    border: 0 none;
  }
  .ant-select-arrow {
    display: none;
  }
  .ant-select-selection-selected-value {
    text-overflow: clip;
    font-size: 13px;
  }
`;

export const ButtonOk = styled.button`
  padding: 0;
  padding-left: 3px;
  text-align: center;
  padding-right: 3px;
  width: 25px;
  height: 21px;
  font-size: 10px;
  margin-left: 8px;
`;
