import { Icon, Badge as badge } from 'antd';
import React, { useContext } from 'react';
import { AdvancedFilter } from './components/filters/AdvancedFilter';
import { AreaFilter } from './components/filters/AreaFilter';
import { AuthorFilter } from './components/filters/AuthorFilter';
import { SerieFilter } from './components/filters/SerieFilter';
import { DateFilter } from './components/filters/DateFilter';
import { BookNavBarClear } from './styles';
import { BookFiltersContext } from './BookFiltersContext';
import { NavbarContext } from '../../../components/_shell/NavbarFilters/NavbarContext';
import { useHistory } from 'react-router';
import styled, { keyframes } from 'styled-components';
import { GlobalFiltersContext } from '../home/GlobalFiltersContext';
import { BookFilter } from './components/filters/BookFilter';

const Container = styled.div`
  display: flex;
`;

const clickAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
`;

export const BookNavBarFilters: React.FC = () => {
  const history = useHistory();

  const {
    areaData,
    authorData,
    serieData,
    bookData,
    dateData,
    setAreaData,
    setAuthorData,
    setSerieData,
    updateData,
    setAreaSearchValue,
    setAuthorSearchValue,
    setSerieSearchValue,
    setBookData,
    setDateData,
    setOrder,
    setPage
  } = useContext(BookFiltersContext);

  const { setSearchString } = useContext(NavbarContext);

  const { advancedData, setAdvancedData } = useContext(GlobalFiltersContext);

  const activeFilters = [
    ...(areaData || []),
    ...(authorData || []),
    ...(serieData || [])
  ].filter((e) => e.checked).length;
  const dateFilter = (dateData || []).filter((e) => e.start || e.end).length
    ? 1
    : 0;
  const advancedFilters = (advancedData || []).filter(
    (e) => e.text.length > 0
  ).length;
  const activeCount = advancedFilters + activeFilters + dateFilter;

  const checkFalse = (e) => ({ ...e, checked: false });
  const checkRangeFalse = (e) => ({ ...e, start: false, end: false });
  const clearFilters = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('search');
    history.replace({ search: queryParams.toString() });

    // Global
    setSearchString('');
    // Book
    setAreaSearchValue('');
    setAuthorSearchValue('');
    setSerieSearchValue('');
    setAreaData(areaData.map(checkFalse));
    setAuthorData(authorData.map(checkFalse));
    setSerieData(serieData.map(checkFalse));
    setBookData(bookData.map(checkFalse));
    setDateData(dateData.map(checkRangeFalse));
    setPage(1);
    updateData();
  };

  return (
    <Container>
      <AreaFilter />
      <AuthorFilter />
      <SerieFilter />
      <BookFilter />
      <DateFilter />
      <BookNavBarClear>
        <div
          onClick={clearFilters}
          style={{
            cursor: 'pointer'
          }}>
          <Icon
            type="delete"
            style={{ fontSize: 16, marginTop: 8 }}
            className="pointer"
          />{' '}
          <span
            style={{
              fontSize: 12,
              color: '#004c92',
              fontWeight: 'bold',
              cursor: 'pointer'
            }}>
            Limpar filtros à esquerda
          </span>
        </div>
      </BookNavBarClear>
    </Container>
  );
};
