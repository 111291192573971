import { RequestUtil } from '../../util/RequestUtil';
import { http_client } from '../../util/http_client';

export const lambdaBaseUrl = process.env.REACT_APP_BIBLIOGRAPHIC_REFERENCE;

export class QuoteRequests {
  // public static async getArticleQuote(contentId: number | string) {
  //   const result = await RequestUtil.createGetRequest(
  //     `api/periodic/getArticleQuote/${contentId}`
  //   )();

  //   return result.data;
  // }

  public static async getArticleQuote(
    contentId: number | string,
    editionId: number | string,
    itemId: number | string
  ) {
    const url = `${lambdaBaseUrl}/periodics/${contentId}/editions/${editionId}/items/${itemId}/reference`;
    const result = await http_client.get<any>(url);
    return result.data.citation;
  }

  public static async getPeriodicQuote(
    contentId: number | string,
    editionId: number | string
  ) {
    const url = `${lambdaBaseUrl}/periodics/${contentId}/editions/${editionId}/reference`;
    const result = await http_client.get<any>(url);
    return result.data.citation;
  }

  public static async getVideoQuote(contentId: number | string) {
    const result = await RequestUtil.createGetRequest(
      `api/video/getVideoQuote/${contentId}`
    )();

    return result.data;
  }

  public static async getBookQuote(
    contentId: number | string,
    editionId: number | string
  ) {
    const url = `${lambdaBaseUrl}/books/${contentId}/editions/${editionId}/reference`;
    const result = await http_client.get<any>(url);
    return result.data.citation;
  }

  public static async getBookChapterQuote(
    contentId: number | string,
    editionId: number | string,
    chapterId: number | string
  ) {
    const url = `${lambdaBaseUrl}/books/${contentId}/editions/${editionId}/chapters/${chapterId}/reference`;
    const result = await http_client.get<any>(url);
    return result.data.citation;
  }
}
