import React from 'react';
import { Image as SCImage } from '../../styles';

type IImgError = React.SyntheticEvent<HTMLImageElement, Event>;

export const Image = ({ item }) => {
  const base = `https://${process.env.REACT_APP_PUBLIC_BUCKET}.s3-sa-east-1.amazonaws.com`;
  const placeholder = `${base}/periodics/cover/no-cover.jpg`;
  const onImgError = (e: IImgError) => (e.currentTarget.src = placeholder);
  const img = item && item.img ? item.img : '';
  const imgArray = img.split('/');
  const imgPath = imgArray.reverse()[0];
  const imgUrl = `${base}/codes/${imgPath}`;
  return <SCImage src={`${imgUrl}`} alt={item.title} onError={onImgError} />;
};
