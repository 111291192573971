import React, { ReactNode, useEffect } from 'react';
import ErrorBoundary from './components/_shell/ErrorBoundary';
import { ConfigProvider, message } from 'antd';
import { createBrowserHistory, History } from 'history';
import { Route, Router } from 'react-router';
import { GoogleAnalyticsLoader } from './components/_shell/GoogleAnalyticsLoader';
import { AuthProvider } from './features/auth_v2/AuthContext';
import { SessionRefresher } from './features/auth_v2/components/SessionRefresher';
import { GlobalStyle } from './GlobalStyle';
import { Routes } from './Routes';
import { HttpClient } from './util/http_client';
import { UserSession } from './features/auth_v2/model/UserSession';
import { NavbarProvider } from './components/_shell/NavbarFilters/NavbarContext';
import { VideoFiltersProvider } from './pages/v2/video/VideoFiltersContext';
import { BookFiltersProvider } from './pages/v2/book/BookFiltersContext';
import { PeriodicFiltersProvider } from './pages/v2/periodic/PeriodicFiltersContext';
import { CodesFiltersProvider } from './pages/v2/codes/CodesFiltersContext';
import './features/auth_v2/AuthContext.css';
import { GlobalFiltersProvider } from './pages/v2/home/GlobalFiltersContext';
import { InformativesFiltersProvider } from './pages/v2/informatives/InformativesFiltersContext';

interface IProps {
  history?: History;
  test_session?: UserSession;
  test_children?: ReactNode;
}

const browserHistory = createBrowserHistory();

const App: React.FC<IProps> = ({
  history = browserHistory,
  test_session,
  test_children
}) => {
  useEffect(() => {
    // Redirect de dominio sem www para com www.
    if (
      process.env.REACT_APP_ENVIRONMENT === 'production' &&
      !window.location.origin.match('www')
    ) {
      window.location.href = process.env.REACT_APP_BASE_UI_URL;
    }

    HttpClient.on('error', () => {
      message.error(<p>erro inesperado</p>);
    });
  }, []);

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return unlisten;
  }, [history]);

  return (
    <ErrorBoundary>
      <GlobalFiltersProvider>
        <NavbarProvider>
          <PeriodicFiltersProvider>
            <BookFiltersProvider>
              <VideoFiltersProvider>
                <CodesFiltersProvider>
                  <InformativesFiltersProvider>
                    <Router
                      {...{
                        history
                      }}>
                      <GoogleAnalyticsLoader />
                      <Route
                        {...{
                          path: '*',
                          render: () => (
                            <AuthProvider
                              {...{
                                test_session
                              }}>
                              {test_children ? (
                                test_children
                              ) : (
                                <ConfigProvider>
                                  <GlobalStyle />
                                  <SessionRefresher />
                                  <Routes />
                                </ConfigProvider>
                              )}
                            </AuthProvider>
                          )
                        }}
                      />
                    </Router>
                  </InformativesFiltersProvider>
                </CodesFiltersProvider>
              </VideoFiltersProvider>
            </BookFiltersProvider>
          </PeriodicFiltersProvider>
        </NavbarProvider>
      </GlobalFiltersProvider>
    </ErrorBoundary>
  );
};

export default App;
