/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable indent */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-throw-literal */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */

import React from 'react';
import _ from 'lodash';
import { Route } from 'react-router';
import { PeriodicContextProvider } from '../pages/v2/periodic/PeriodicContextProvider';
import { ReadItemView } from '../pages/v2/periodic/ReadItemView';
import { PeriodicView } from '../pages/v2/periodic/PeriodicView';

export const NewPeriodicRoutes = (props: any) => (
  <Route
    path={`/v2/revista/:periodicId/:editionId?/:itemId?/:itemTitle?`}
    render={(props) => {
      const itemId = _.get(props, 'match.params.itemId');
      const isValidItemId = Number(itemId);
      return (
        <PeriodicContextProvider {...props}>
          <>
            {itemId && Number.isInteger(isValidItemId) && <ReadItemView />}
            <PeriodicView {...props} />
          </>
        </PeriodicContextProvider>
      );
    }}
  />
);
