import React from 'react';
import { ContentSearchResult } from '../../../../models/ContentSearchResult';
import { DefaultContentListItem } from '../../../../components/DefaultContentListItem/DefaultContentListItem';

interface IProps {
  result: ContentSearchResult;
}

export const PeriodicListItem = ({ result, ...props }: IProps) => {
  function getPeriodicCoverImage(periodicId: number | string) {
    if (result && result.id && !result.parentId) {
      const id = String(result.id).replace('P', '');
      return `https://bid1006-production-public.s3.sa-east-1.amazonaws.com/periodics/cover/P${id}/P${id}.jpg`;
    }
    if (result && result.parentId) {
      const parentId = String(result.parentId).replace('P', '');
      return `https://bid1006-production-public.s3.sa-east-1.amazonaws.com/periodics/cover/P${parentId}/P${parentId}.jpg`;
    }
    const isNewPeriodic = String(periodicId).includes('P');
    if (isNewPeriodic) {
      const stage = process.env.REACT_APP_STAGE || 'staging';
      return `https://bid1006-${stage}-public.s3.sa-east-1.amazonaws.com/periodics/cover/${periodicId}/${periodicId}.jpg`;
    }
    return `https://www.forumconhecimento.com.br/Uploads/ImgPeriodico/imgPeriodicoListagem_${periodicId}_record.jpg`;
  }

  return (
    <DefaultContentListItem
      {...props}
      linkTo={`/periodico/${result.id}`}
      result={{
        ...result,
        img: getPeriodicCoverImage(result.id),
        tags: [{ text: 'Revista', color: 'purple' }]
      }}
    />
  );
};
