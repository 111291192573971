import React from 'react';
import { ContentSearchResult } from '../models/ContentSearchResult';
import { DefaultContentListItem } from './DefaultContentListItem/DefaultContentListItem';

interface IProps {
  result: ContentSearchResult;
}

export const VideoListItem = ({ result, ...props }: IProps) => {
  function getCoverImage() {
    if (result && result.id && !result.parentId) {
      const id = String(result.id);
      return `https://bid1006-production-public.s3-sa-east-1.amazonaws.com/videos/thumbs/${id}-1.jpg`;
    }
    return (result.img as string).match('http')
      ? result.img
      : `https://bid1006-production-public.s3-sa-east-1.amazonaws.com${result.img}`;
  }

  return (
    <DefaultContentListItem
      {...props}
      linkTo={`/conteudo/videos/${result.id}`}
      result={{
        ...result,
        img: getCoverImage(),
        tags: [{ text: 'Vídeo', color: 'red' }]
      }}
    />
  );
};
