import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 16px 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;

  .title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
  }

  a {
    span {
      background: none;
      border: 0 none;
      margin-top: 12px;
      font-weight: 500;
      color: #004c92;
    }
  }

  .swiper {
    width: 100%;
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }

  .swiper-slide {
    width: 440px;
    padding: 18px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .swiper-pagination-progressbar {
    background-color: #a23147; /* Cor de fundo da barra de progresso */
    height: 5px; /* Altura da barra de progresso */
    border-radius: 5px;
  }

  .swiper-pagination-progressbar-fill {
    border: 2px solid #004c92;
    background-color: #004c92 !important;
    border-radius: 0px !important;
  }

  .link-filter {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: transparent 1px solid;
  }

  .icon-filter {
    margin-right: 4px;
  }

  .link:visited {
    text-decoration: none;
    color: #e43339;
  }

  .call-to-action {
    height: 100%;
  }

  .call-to-action-text {
    text-decoration: none;
    color: #e43339;
  }

  .spin {
    margin-top: 176px;
    margin-bottom: 176px;
  }

  .ant-spin-dot-item {
    background-color: #d7282f;
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-color: rgba(255, 255, 255, 0);
    right: 10px;
    padding: 30px;
    color: #d7282f !important;
    fill: #d7282f !important;
    stroke: #d7282f !important;
    transition: all 0.3s ease;
  }

  .swiper-button-disabled {
    color: rgba(255, 255, 255, 0) !important;
    fill: rgba(255, 255, 255, 0) !important;
    stroke: rgba(255, 255, 255, 0) !important;
  }

  .error-message {
    color: red;
  }

  .message-wrapper {
    margin-top: 200px;
    margin-bottom: 200px;
  }
`;
