import { Icon } from 'antd';
import React, { useEffect, useState } from 'react';
import Joyride from 'react-joyride';
import SwitchIcon from '../assets/images/icon-switch.png';
import styled from 'styled-components';

const IconImg = styled.img`
  width: 36px;
  margin: 0 6px 0 0;
`;

const MyJoyride: React.FC = () => {
  const steps = [
    {
      target: '#advanced-bar',
      disableBeacon: true,
      content: (
        <div>
          <h3 style={{ color: '#004C92' }}>
            As pesquisas agora estão muito mais fáceis!
          </h3>
          <hr />
          <span style={{ color: '#004C92' }}>
            Veja a seguir as últimas atualizações:
          </span>
          <hr />
          <div style={{ textAlign: 'left', marginTop: 10 }}>
            <h5 style={{ marginBottom: 15 }}>
              <b>Função: Que contenha | Que não contenha</b>
              <br />
              <span>Inclua ou exclua termos específicos na sua busca.</span>
            </h5>
            <h5 style={{ marginBottom: 15 }}>
              <b>Termo Exato</b>
              <br />
              {'  '}Obtenha resultados que coincidam perfeitamente com os termos
              inseridos.
            </h5>
            <h5>
              <b>Filtros combinados</b> <br />
              {'  '}Combine múltiplos filtros para uma busca sob medida e
              precisa.
            </h5>
          </div>
        </div>
      )
    },
    {
      target: '#select-type-filter',
      disableBeacon: true,
      content: (
        <div>
          <h3 style={{ color: '#004C92' }}>Que contenha / Que não contenha</h3>
          <hr />
          <div style={{ textAlign: 'left', marginTop: 15 }}>
            <h5 style={{ marginBottom: 15 }}>
              <b>Função: Que contenha</b>
              <br />
              <span style={{ textAlign: 'justify' }}>
                Se quiser resultados que contenham um termo específico,
                selecione a opção "Que contenha" e digite o termo.
              </span>
            </h5>
            <h5>
              <b>
                Função: Que <u>não</u> contenha
              </b>
              <br />
              <span style={{ textAlign: 'justify' }}>
                Para excluir resultados que contenham um termo específico,
                escolha a opção "Que não contenha" e insira o termo que deseja
                omitir.
              </span>
            </h5>
          </div>
        </div>
      )
    },
    {
      target: '#exact-filter',
      disableBeacon: true,
      content: (
        <div>
          <h3 style={{ color: '#004C92' }}>Termo Exato</h3>
          <hr />
          <div>
            <IconImg src={SwitchIcon} />
            <h5 style={{ textAlign: 'left', marginTop: 10 }}>
              {'  '}Marque a opção "<b>Termo exato</b>" para resultados que
              correspondam exatamente à sua frase ou palavra-chave, garantindo
              uma busca mais precisa e direcionada.
            </h5>
          </div>
        </div>
      )
    },
    {
      target: '#icon-plus-filter',
      disableBeacon: true,
      content: (
        <div>
          <h3 style={{ color: '#004C92' }}>Filtros Combinados</h3>
          <hr />
          <div>
            <Icon type="plus-circle" style={{ fontSize: '36px' }} />
            <h5 style={{ textAlign: 'left', marginTop: 10 }}>
              Expanda sua busca clicando no <b>ícone "+"</b>. Combine múltiplos
              critérios de inclusão e exclusão de termos. Tenha uma experiência
              de pesquisa totalmente personalizada e detalhada.
            </h5>
          </div>
        </div>
      )
    }
  ];

  const [tour, setTour] = useState(false);

  useEffect(() => {
    const tourSeen = localStorage.getItem('tourSeenV2');

    if (!tourSeen) {
      setTour(true);
      localStorage.setItem('tourSeenV2', 'true');
    }
  }, []);

  return (
    <>
      {tour && (
        <Joyride
          run
          steps={steps}
          showProgress
          showSkipButton
          continuous
          disableScrolling
          locale={{
            back: 'Anterior',
            close: 'Fechar',
            last: 'Concluir',
            next: 'Próximo',
            skip: 'Pular'
          }}
          styles={{
            options: {
              arrowColor: '#fff',
              backgroundColor: '#fff',
              overlayColor: 'rgba(0, 0, 0, 0.5)',
              textColor: '#004C92',
              primaryColor: '#D72930'
            }
          }}
        />
      )}
    </>
  );
};

export default MyJoyride;
