import { Skeleton } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { NewsletterList } from './NewsletterList';
import { NewsletterSearchList } from './NewsletterSearchList';
import { useSearchFilter } from '../../../hooks/useSearchFilter';
import { GlobalFiltersContext } from '../../../../v2/home/GlobalFiltersContext';

export const NewsletterSearchResult = () => {
  const { filter } = useSearchFilter();
  const { advancedData: advancedDataGlobal, submit } =
    useContext(GlobalFiltersContext);
  const [search, setSearch] = useState(filter.words || '');

  const firstAdvancedSearch = () => {
    const filters =
      advancedDataGlobal && advancedDataGlobal.filter
        ? advancedDataGlobal.filter((e) => e.text.length > 0)
        : [];
    return filters.length > 0 ? filters[0].text : '';
  };

  useEffect(() => {
    const s = firstAdvancedSearch();
    setSearch(s || '');
  }, [submit]);

  useEffect(() => {
    const s = firstAdvancedSearch();
    if (!s) setSearch('');
  }, [advancedDataGlobal]);

  return search !== '' ? <NewsletterSearchList /> : <NewsletterList />;
};

export const Loader = () => (
  <div>
    <Skeleton />
    <Skeleton />
  </div>
);
