import React, { useContext, useEffect, useState } from 'react';
import LogoIcon from '../../../assets/images/logo-forum-white.svg';
import NavbarFilters from '../NavbarFilters';
import TabContentSelection from '../TabContentSelection';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import { FooterLogoImg } from '../../globalStyles';
import { NavbarSearch } from '../NavbarSearch';
import { NavbarUserLogin } from '../NavbarUserLogin';
import { Container, Navbar } from './styles';
import { SearchFilter } from '../../../models/search/SearchFilter';
import { useSearchFilter } from '../../../pages/pesquisa/hooks/useSearchFilter';
import { VideoFiltersContext } from '../../../pages/v2/video/VideoFiltersContext';
import { BookFiltersContext } from '../../../pages/v2/book/BookFiltersContext';
import { NavbarContext } from '../NavbarFilters/NavbarContext';
import { PeriodicFiltersContext } from '../../../pages/v2/periodic/PeriodicFiltersContext';
import { GlobalFiltersContext } from '../../../pages/v2/home/GlobalFiltersContext';

export const topbarHeight = 0;

const Topbar = () => {
  const [isSearch, setIsSearch] = useState<boolean>(false);

  let isInHomePage = false;
  let myLocation = '';
  let splittedUrl = [];

  const isInVideos = location.pathname.startsWith('/conteudo/videos');
  const isInRevistasV2 = location.pathname.startsWith('/conteudo/revistas');
  const isInCodesV2 = location.pathname.startsWith('/conteudo/codigos');
  const isInLivros = location.pathname.startsWith('/conteudo/livros');
  const isInRevistas = location.pathname.startsWith('/pesquisa/revista');
  const isInInformativo = location.pathname.startsWith('/pesquisa/informativo');
  const isInInformativoV2 = location.pathname.startsWith(
    '/conteudo/informativos'
  );
  const isInCodigos = location.pathname.startsWith('/pesquisa/codigos');
  const isInTodos = location.pathname.startsWith('/pesquisa/todos');
  const isInOutros =
    isInRevistas || isInInformativo || isInCodigos || isInTodos;

  const { setSearchString, setSearchScope } = useContext(NavbarContext);
  const {
    setAdvancedData,
    updateData: updateDataGlobal,
    advancedData,
    submit
  } = useContext(GlobalFiltersContext);

  const firstAdvancedSearch = () => {
    const filters =
      advancedData && advancedData.filter
        ? advancedData.filter((e) => e.text.length > 0)
        : [];
    return filters.length > 0 ? filters[0].text : '';
  };

  useEffect(() => {
    const search = firstAdvancedSearch() || '';
    setIsSearch(!!search);
  }, [submit]);

  const HomeLogoButtonVideo = () => {
    const history = useHistory();
    const {
      areaData,
      authorData,
      eventData,
      serieData,
      dateData,
      setDateData,
      setAreaData,
      setAuthorData,
      setEventData,
      setSerieData,
      updateData,
      setAreaSearchValue,
      setAuthorSearchValue,
      setEventSearchValue,
      setSerieSearchValue,
      setOrder
    } = useContext(VideoFiltersContext);

    const checkFalse = (e) => ({ ...e, checked: false });
    const checkRangeFalse = (e) => ({ ...e, start: false, end: false });

    const clearFiltersVideo = () => {
      setAreaSearchValue('');
      setAuthorSearchValue('');
      setEventSearchValue('');
      setSerieSearchValue('');
      setAdvancedData([{ type: 'AND', text: '', exact: false }]);
      setAreaData(areaData.map(checkFalse));
      setAuthorData(authorData.map(checkFalse));
      setEventData(eventData.map(checkFalse));
      setSerieData(serieData.map(checkFalse));
      setDateData(dateData.map(checkRangeFalse));
      setOrder('date:desc');
      updateData();
      setTimeout(() => {
        history.push(`/pesquisa/todos`);
      }, 200);
    };

    return <FooterLogoImg src={LogoIcon} onClick={clearFiltersVideo} />;
  };

  const HomeLogoButtonBook = () => {
    const history = useHistory();

    const {
      areaData,
      authorData,
      serieData,
      dateData,
      setAreaData,
      setAuthorData,
      setSerieData,
      updateData,
      setAreaSearchValue,
      setAuthorSearchValue,
      setSerieSearchValue,
      setDateData,
      setOrder
    } = useContext(BookFiltersContext);

    const checkFalse = (e) => ({ ...e, checked: false });
    const checkRangeFalse = (e) => ({ ...e, start: false, end: false });
    const clearFiltersBook = () => {
      setAreaSearchValue('');
      setAuthorSearchValue('');
      setSerieSearchValue('');
      setAdvancedData([{ type: 'AND', text: '', exact: false }]);
      setAreaData(areaData.map(checkFalse));
      setAuthorData(authorData.map(checkFalse));
      setSerieData(serieData.map(checkFalse));
      setDateData(dateData.map(checkRangeFalse));
      setOrder('date:desc');
      updateData();
      setTimeout(() => {
        history.push(`/pesquisa/todos`);
      }, 200);
    };

    return <FooterLogoImg src={LogoIcon} onClick={clearFiltersBook} />;
  };

  const HomeLogoButtonPeriodicV2 = () => {
    const history = useHistory();

    const {
      areaData,
      authorData,
      dateData,
      setAreaData,
      setAuthorData,
      updateData,
      setAreaSearchValue,
      setAuthorSearchValue,
      setDateData,
      setOrder
    } = useContext(PeriodicFiltersContext);

    const checkFalse = (e) => ({ ...e, checked: false });
    const checkRangeFalse = (e) => ({ ...e, start: false, end: false });
    const clearFiltersPeriodic = () => {
      setAreaSearchValue('');
      setAuthorSearchValue('');
      setAdvancedData([{ type: 'AND', text: '', exact: false }]);
      setAreaData(areaData.map(checkFalse));
      setAuthorData(authorData.map(checkFalse));
      setDateData(dateData.map(checkRangeFalse));
      setOrder('date:desc');
      updateData();
      setTimeout(() => {
        history.push(`/pesquisa/todos`);
      }, 200);
    };

    return <FooterLogoImg src={LogoIcon} onClick={clearFiltersPeriodic} />;
  };

  const HomeLogoButtonCodesV2 = () => {
    const history = useHistory();

    const { updateData, setOrder } = useContext(PeriodicFiltersContext);
    const clearFiltersPeriodic = () => {
      setAdvancedData([{ type: 'AND', text: '', exact: false }]);
      setOrder('relevance');
      updateData();
      setTimeout(() => {
        history.push(`/pesquisa/todos`);
      }, 200);
    };

    return <FooterLogoImg src={LogoIcon} onClick={clearFiltersPeriodic} />;
  };

  const HomeLogoButton = () => {
    const history = useHistory();
    const { changeFilter } = useSearchFilter();
    const { setOrder: setVideoOrder } = useContext(VideoFiltersContext);
    const { setOrder: setBookOrder } = useContext(BookFiltersContext);
    const { setOrder: setPeriodicOrder } = useContext(PeriodicFiltersContext);

    const handleClear = () => {
      const newFilter = new SearchFilter();
      changeFilter(newFilter);
      setSearchString('');
      setSearchScope(0);
      setVideoOrder('date:desc');
      setBookOrder('date:desc');
      setPeriodicOrder('date:desc');
      setAdvancedData([{ type: 'AND', text: '', exact: false }]);
      window.scrollTo(0, 0);
      updateDataGlobal();
      setTimeout(() => history.push(`/pesquisa/todos`), 250);
    };
    return (
      <FooterLogoImg id="element-2" src={LogoIcon} onClick={handleClear} />
    );
  };

  let HomeLogoButtonToRender = null;

  if (
    isInVideos &&
    !isInLivros &&
    !isInOutros &&
    !isInRevistasV2 &&
    !isInCodesV2
  ) {
    HomeLogoButtonToRender = <HomeLogoButtonVideo />;
  } else if (
    !isInVideos &&
    isInLivros &&
    !isInOutros &&
    !isInRevistasV2 &&
    !isInCodesV2
  ) {
    HomeLogoButtonToRender = <HomeLogoButtonBook />;
  } else if (
    !isInVideos &&
    !isInLivros &&
    !isInOutros &&
    isInRevistasV2 &&
    !isInCodesV2
  ) {
    HomeLogoButtonToRender = <HomeLogoButtonPeriodicV2 />;
  } else if (
    !isInVideos &&
    !isInLivros &&
    !isInOutros &&
    !isInRevistasV2 &&
    isInCodesV2
  ) {
    HomeLogoButtonToRender = <HomeLogoButtonCodesV2 />;
  } else if (!isInVideos && !isInLivros && !isInRevistasV2 && !isInCodesV2) {
    HomeLogoButtonToRender = <HomeLogoButton />;
  }

  return (
    <div
      style={{
        zIndex: 9,
        position: 'sticky',
        width: '100%',
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        backdropFilter: isInInformativoV2 ? undefined : 'blur(10px)',
        paddingBottom: isInInformativoV2 ? undefined : '32px'
      }}>
      <Container id="element-1">
        {splittedUrl.length >= 5 && !myLocation.includes('/v2/livro') ? null : (
          <>
            <Navbar>
              {HomeLogoButtonToRender}
              <NavbarUserLogin />
              {!isInHomePage && <NavbarSearch />}
            </Navbar>
            {!isInHomePage && <TabContentSelection {...{ user: {} }} />}
          </>
        )}
      </Container>
      {!isInHomePage &&
        (!isInInformativoV2 || (isInInformativoV2 && isSearch)) && (
          <NavbarFilters />
        )}
    </div>
  );
};

export default withRouter(Topbar);
