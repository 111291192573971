import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import iconAuthor from '../../../../assets/icon-author.svg';
import iconDate from '../../../../assets/images/calendar-icon-4.svg';

const Container = styled.div`
  width: 360px;

  .link {
    text-decoration: none;
    display: block;
  }

  .cover {
    width: 100%;
    height: 227px;
    margin-bottom: 16px;
    border-radius: 8px;
  }

  .title {
    font-size: 16px;
  }

  .info-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .info-date {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }

  .info-author {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const InfoPopover = styled.div`
  position: absolute;
  z-index: 1;
  padding: 8px;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 8px;
`;

const Title = styled.h2`
  height: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;

  &:hover {
    ${InfoPopover} {
      display: block;
    }
  }
`;
const Info = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 4px;

  img {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    ${InfoPopover} {
      display: block;
    }
  }
`;

export const Image = styled.img`
  border-radius: 12px;
  max-width: 360px;
`;

type IImgError = React.SyntheticEvent<HTMLImageElement, Event>;

export const Card = ({
  cover,
  title,
  date,
  author,
  link
}: {
  id: string;
  title: string;
  cover: string;
  date: string;
  author: string;
  desc: string;
  link: string;
}) => {
  const [titlePopover, setTitlePopover] = useState(false);
  const [datePopover, setDatePopover] = useState(false);
  const [authorPopover, setAuthorPopover] = useState(false);

  const handleTitlePopover = () => setTitlePopover(!titlePopover);
  const handleDatePopover = () => setDatePopover(!datePopover);
  const handleAuthorPopover = () => setAuthorPopover(!authorPopover);

  const base = `https://${process.env.REACT_APP_PUBLIC_BUCKET}.s3-sa-east-1.amazonaws.com`;
  const placeholder = `${base}/videos/thumbs/placeholder.jpg`;
  const onImgError = (e: IImgError) => (e.currentTarget.src = placeholder);

  function converterData(data: string): string {
    const [ano, mes, dia] = data.split('-');
    return `${dia}/${mes}/${ano}`;
  }

  return (
    <Container>
      <Link className="link" to={link}>
        <Image
          className="cover"
          src={`${base}/${cover}`}
          alt={title}
          onError={onImgError}
        />
      </Link>
      <Link className="link" to={link}>
        <Title
          onMouseEnter={handleTitlePopover}
          onMouseLeave={handleTitlePopover}>
          {title.length > 58
            ? `${title.slice(0, 58).toUpperCase()}...`
            : title.toUpperCase()}
          {title.length > 58 && titlePopover && (
            <InfoPopover>{title}</InfoPopover>
          )}
        </Title>
      </Link>
      <div className="info-wrapper">
        {author !== '' && date !== '' && (
          <Info
            className="info-date"
            onMouseEnter={handleDatePopover}
            onMouseLeave={handleDatePopover}>
            <img src={iconDate} alt={title} />
            <span>
              {date.length > 10
                ? `${converterData(date).slice(0, 10)}...`
                : converterData(date)}
            </span>
            {date.length > 6 && datePopover && (
              <InfoPopover>{converterData(date)}</InfoPopover>
            )}
          </Info>
        )}
        {author === '' && date !== '' && (
          <Info
            className="info-date"
            onMouseEnter={handleDatePopover}
            onMouseLeave={handleDatePopover}>
            <img src={iconDate} alt={title} />
            <span>
              {date.length > 10
                ? `${converterData(date).slice(0, 10)}...`
                : converterData(date)}
            </span>
            {date.length > 6 && datePopover && (
              <InfoPopover>{converterData(date)}</InfoPopover>
            )}
          </Info>
        )}
        {author !== '' && (
          <Info
            className="info-author"
            onMouseEnter={handleAuthorPopover}
            onMouseLeave={handleAuthorPopover}>
            <img src={iconAuthor} alt={title} />
            <span>
              {author.length > 10 ? `${author.slice(0, 10)}...` : author}
            </span>
            {authorPopover && <InfoPopover>{author}</InfoPopover>}
          </Info>
        )}
      </div>
    </Container>
  );
};
