/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable indent */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-throw-literal */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */

import _ from 'lodash';
import React, { useCallback, useContext } from 'react';
import { withRouter } from 'react-router';
import { AsyncComponent } from '../../../components/AsyncComponent';
import { DotsContentMenu } from '../../../components/ContentMenu/DotsContentMenu';
import { ContentType } from '../../../enums/ContentType';
import { PeriodicContextModel } from '../../../models/periodic/PeriodicContextModel';
import { PeriodicEditionItemModel } from '../../../models/periodic/PeriodicEditionItemModel';
import { ContentSearchResult } from '../../../models/ContentSearchResult';
import { ArticleListItem } from '../../periodico/components/ArticleListItem';
import { PeriodicContext } from './PeriodicContextProvider';
import { PeriodicEditionInfoModel } from '../../../models/periodic/PeriodicEditionInfoModel';

interface IProps {
  selectedItemId?: number;
  match?: any;
  history?: any;
  periodic: PeriodicEditionInfoModel;
  editionId: number;
}

const _PeriodicEditionItemList = ({
  selectedItemId,
  match,
  history,
  periodic
}: IProps) => {
  const { periodicId, editionId } = match.params;
  const ctx = useContext<{ state: PeriodicContextModel }>(
    PeriodicContext as any
  );
  const { articlesRequest }: any = ctx.state;

  const createContentSearchResultFromItem = useCallback(
    (item: PeriodicEditionItemModel) => {
      if (!periodic) return ContentSearchResult.Empty;

      const stage = process.env.REACT_APP_STAGE || 'staging';
      const periodicImageSrc = `https://bid1006-${stage}-public.s3.sa-east-1.amazonaws.com/periodics/cover/${periodicId}/${periodicId}.jpg`;
      let editionInfo = '';
      if (periodic) {
        const startMonth = new Date(periodic.editionStartMonth);
        let monthStart = startMonth.toLocaleString('pt-BR', {
          month: 'short',
          timeZone: 'UTC'
        });
        monthStart = monthStart[0].toUpperCase() + monthStart.slice(1);
        const endMonth = new Date(periodic.editionEndMonth);
        let monthEnd = endMonth.toLocaleString('pt-BR', {
          month: 'short',
          timeZone: 'UTC'
        });
        monthEnd = monthEnd[0].toUpperCase() + monthEnd.slice(1);
        const year = startMonth.getFullYear();
        const isMontly = periodic.editionFrequency === 'Mensal';
        const editionDate =
          monthStart === monthEnd || isMontly
            ? `${monthStart} - ${year}`
            : `${monthStart}/${monthEnd} - ${year}`;
        // TODO remover mock
        editionInfo = ['E52373', 'E52282'].includes(periodic.editionNumId)
          ? `Vol. ${String(periodic.editionTitle)}, No. ${String(
              periodic.editionTitle
            )}, ${
              String(periodic.editionTitle) === '1'
                ? 'Janeiro/Junho 2023'
                : 'Julho/Dezembro 2023'
            }`
          : `${`Número ${String(periodic.editionTitle)
              .replaceAll('Edição ', '')
              .replaceAll('Número ', '')}`}, Ano ${year}, ${editionDate}`;
      }

      return new ContentSearchResult(
        ContentType.ARTICLE,
        item.id,
        item.title,
        periodic.periodicTitle,
        periodic.periodicDescription,
        null,
        editionId,
        periodic.periodicNumId,
        periodicImageSrc,
        item.author,
        null,
        editionInfo,
        null,
        item.articleType,
        null,
        createItemLink(item.id),
        String(item.id)
      );
    },
    [periodic]
  );

  const createItemLink = useCallback(
    (itemId: any) =>
      `/v2/revista/${
        periodicId || periodic.periodicNumId
      }/${editionId}/${itemId}`,
    [periodic, periodicId, editionId]
  );

  return (
    <AsyncComponent
      error={
        articlesRequest.error &&
        _.get(articlesRequest.error, 'response.status') === 500
      }
      loading={articlesRequest.loading}
      renderContent={() => {
        if (!articlesRequest.result) return null;
        const items = articlesRequest.result || [];
        return (
          <div>
            {items.map((item: PeriodicEditionItemModel, index: number) => (
              <ArticleListItem
                key={`${item.id}-${index}`}
                article={item}
                link={createItemLink(item.id)}
                active={selectedItemId === item.id}
                onClick={() => history.push(createItemLink(item.id))}
                Menu={
                  <DotsContentMenu
                    result={createContentSearchResultFromItem(item)}
                  />
                }
              />
            ))}
          </div>
        );
      }}
    />
  );
};

//@ts-ignore
export const PeriodicEditionItemList = withRouter(
  //@ts-ignore
  _PeriodicEditionItemList
);
