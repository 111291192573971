/* eslint-disable no-console */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import IconChapter from '../../../../assets/images/quote.svg';
import IconDate from '../../../../assets/images/calendar-icon-4.svg';
import { useSearchFilter } from '../../../pesquisa/hooks/useSearchFilter';
import {
  CardContainer,
  Info,
  InfoPopover,
  ModalInner,
  ModalResults,
  ModalStyled,
  ResultsButton,
  Title
} from './styles';
import { OccurrenceContents } from './types/occurrences';

type IImgError = React.SyntheticEvent<HTMLImageElement, Event>;

interface IParams {
  id: string;
  title: string;
  desc: string;
  link: string;
  contents: OccurrenceContents[];
  highlights: any;
  ref?: any;
  number: string;
  startMonth: string;
  endMonth: string;
}

export const Card = ({
  id,
  title,
  link,
  contents,
  highlights,
  number,
  startMonth,
  endMonth
}: IParams) => {
  const { filter } = useSearchFilter();
  const [titlePopover, setTitlePopover] = useState(false);
  const [authorPopover, setAuthorPopover] = useState(false);

  const handleTitlePopover = () => setTitlePopover(!titlePopover);
  const handleAuthorPopover = () => setAuthorPopover(!authorPopover);

  const [visible, setVisible] = useState(false);
  const showModal = () => setVisible(true);
  const handleOk = () => setVisible(false);

  const base = `https://${process.env.REACT_APP_PUBLIC_BUCKET}.s3-sa-east-1.amazonaws.com`;
  const placeholder = `${base}/periodics/cover/no-cover.jpg`;
  const onImgError = (e: IImgError) => (e.currentTarget.src = placeholder);
  const itemId = id.split('-');
  const periodicId = String(itemId[0] || 0);
  const coverSrc = `${base}/periodics/cover/${periodicId}/${periodicId}.jpg`;

  const year = (str: string) => new Date(str).getUTCFullYear();
  const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);
  const startMonthFn = (str: string) =>
    capitalize(
      String(
        new Date(str).toLocaleString('pt-BR', {
          month: 'short',
          timeZone: 'UTC'
        })
      )
    );
  const endMonthFn = (str: string) =>
    capitalize(
      String(
        str
          ? new Date(str).toLocaleString('pt-BR', {
              month: 'short',
              timeZone: 'UTC'
            })
          : ''
      )
    );

  function converterData(): string {
    return `${
      number.includes('Número') ? number : `Número ${number}`
    }, Ano ${year(startMonth)}, ${startMonthFn(startMonth)}
    ${endMonthFn(endMonth) ? `/${endMonthFn(endMonth)}` : ''}
     - ${year(startMonth)}`;
  }

  const getTitle = (item: any) => {
    try {
      return contents.filter((c) => c.chapter_id === item.chapter_id)[0]
        .chapter_title;
    } catch (error) {
      return '';
    }
  };

  let dateFormatted = converterData();
  // TODO remover mock
  if (id === 'P275-E52282') {
    dateFormatted = 'Vol. 1, No. 1, Janeiro/Junho 2023';
  }
  if (id === 'P275-E52373') {
    dateFormatted = 'Vol. 1, No. 2, Julho/Dezembro 2023';
  }

  return (
    <CardContainer>
      <Link className="cover" to={link}>
        <img src={coverSrc} alt={title} onError={onImgError} />
      </Link>
      <Link className="link" to={link}>
        <Title
          onMouseEnter={handleTitlePopover}
          onMouseLeave={handleTitlePopover}>
          {title.length > 30 ? `${title.slice(0, 26)}...` : title.toUpperCase()}
          {titlePopover && <InfoPopover>{title}</InfoPopover>}
        </Title>
      </Link>
      <div className="info-wrapper">
        <Info
          className="info-date"
          onMouseEnter={handleAuthorPopover}
          onMouseLeave={handleAuthorPopover}>
          <img src={IconDate} alt={title} />
          <span className="formatted">{dateFormatted}</span>
        </Info>
      </div>
      {filter.words !== '' && highlights ? (
        <>
          <ResultsButton onClick={showModal}>
            Ver {highlights.length} ocorrências
          </ResultsButton>
          <ModalStyled
            title={'Ocorrências'}
            closable={false}
            visible={visible}
            onOk={handleOk}
            okText="Fechar"
            cancelButtonProps={{ style: { display: 'none' } }}
            width={800}
            bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
            <ModalInner>
              {highlights.map((e: any, i: number) => (
                <div key={i}>
                  <Link
                    to={`/v2/revista/${id.replace('-', '/')}/${
                      e.chapter_id
                    }?searchpage=1`}>
                    <h4 className="title-chapter">
                      <img className="icon-chapter" src={IconChapter} alt="" />
                      {getTitle(e)}
                    </h4>
                  </Link>
                  {e.highlight.map((ee: any, ii: number) => (
                    <ModalResults key={ii}>
                      <span key={ii} dangerouslySetInnerHTML={{ __html: ee }} />
                      <span>...</span>
                    </ModalResults>
                  ))}
                </div>
              ))}
            </ModalInner>
          </ModalStyled>
        </>
      ) : null}
    </CardContainer>
  );
};
