import { http_client } from './http_client';

export const globalFiltersRequest = async (
  url: string,
  query: any
): Promise<any> => {
  try {
    return await http_client.get<any>(url, query);
  } catch (e) {
    return [];
  }
};
