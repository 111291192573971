/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable indent */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-throw-literal */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */

import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';

const Container = styled.ul<any>`
  max-width: 1024px;

  .ant-spin-dot-item {
    background-color: red;
  }
`;

interface IProps {
  loading: boolean;
  children: any;
}

export const SearchResultList = ({ loading, children }: IProps) => {
  const childrenCount = _.get(children, 'length', 0);

  return (
    <Container
      className={loading || childrenCount === 0 ? '' : 'result-renderer'}>
      {loading ? (
        <div style={{ marginTop: 140, marginBottom: 640 }}>
          <Spin tip="Carregando" size="large" style={{ color: 'red' }}></Spin>
          {/* Old skeleton from antd, need changes */}
          {/* <DefaultContentListItemPlaceholder /> */}
        </div>
      ) : (
        children
      )}
    </Container>
  );
};
