import React from 'react';
import { ContentSearchResult } from '../models/ContentSearchResult';
import { DefaultContentListItem } from './DefaultContentListItem/DefaultContentListItem';
import { ContentType } from '../enums/ContentType';

interface IProps {
  result: ContentSearchResult;
}

export const CodeListItem = ({ result, ...props }: IProps) => {
  function getCoverImage() {
    const splited = String(result.img).split('/');
    const finalUrl = splited.at(-1);
    if (
      String(finalUrl).includes('.png') ||
      String(finalUrl).includes('.jpg')
    ) {
      return `https://bid1006-production-public.s3-sa-east-1.amazonaws.com/codes/${finalUrl}`;
    }
    return `${result.img}`.match('http')
      ? result.img
      : `https://bid1006-production-public.s3-sa-east-1.amazonaws.com${result.img}`;
  }

  return (
    <DefaultContentListItem
      {...props}
      linkTo={
        result.contentType === ContentType.CODE
          ? `https://codigos.forumconhecimento.com.br/${result.editionId}.html`
          : `https://codigos.forumconhecimento.com.br/itenscodigo${result.url}`
      }
      linkToParent={`https://codigos.forumconhecimento.com.br/${result.editionId}.html`}
      result={{
        ...result,
        img: getCoverImage(),
        tags: []
      }}
    />
  );
};
