import React, { useContext } from 'react';
import { BookFiltersContext } from '../../BookFiltersContext';
import { TimedFetch } from './use-cases/TimedFetch';

export const BookFilter = ({}) => {
  const {
    bookVisible,
    bookSearchValue,
    bookLoading,
    bookLoaded,
    bookError,
    bookData,
    bookRI,
    setBookVisible,
    setBookSearchValue,
    setBookLoading,
    setBookLoaded,
    setBookError,
    setBookData,
    setBookRI,
    updateData,
    setPage
  } = useContext(BookFiltersContext);

  return (
    <TimedFetch
      segment="book"
      placeholder="Livros"
      visible={bookVisible}
      searchValue={bookSearchValue}
      loading={bookLoading}
      loaded={bookLoaded}
      error={bookError}
      data={bookData}
      ri={bookRI}
      setVisible={setBookVisible}
      setSearchValue={setBookSearchValue}
      setLoading={setBookLoading}
      setLoaded={setBookLoaded}
      setError={setBookError}
      setData={setBookData}
      setRI={setBookRI}
      updateData={updateData}
      setPage={setPage}
      fetchOnMount={true}
    />
  );
};
