import React, { createContext, useState } from 'react';
import { OrderType } from '../../../components/v2/searchHelpers';

interface CodesFiltersContextData {
  submit: number;
  updateData: () => void;

  order: OrderType;
  setOrder: (v: OrderType) => void;

  page: Number;
  setPage: (v: Number) => void;

  advancedVisible: boolean;
  advancedData: any;
  setAdvancedVisible: (v: boolean) => void;
  setAdvancedData: (v: object[]) => void;

  codesVisible: boolean;
  codesSearchValue: string;
  codesLoading: boolean;
  codesLoaded: boolean;
  codesError: boolean;
  codesData: any;
  codesRI: number;
  setCodesVisible: (v: boolean) => void;
  setCodesSearchValue: (v: string) => void;
  setCodesLoading: (v: boolean) => void;
  setCodesLoaded: (v: boolean) => void;
  setCodesError: (v: boolean) => void;
  setCodesData: (v: any) => void;
  setCodesRI: (v: number) => void;
}

export const CodesFiltersContext = createContext<CodesFiltersContextData>(
  {} as CodesFiltersContextData
);

export const CodesFiltersProvider: React.FC = ({ children }) => {
  const [submit, setSubmit] = useState<number>(0);
  const [order, setOrder] = useState<OrderType>('relevance');
  const [page, setPage] = useState<Number>(1);

  const [advancedVisible, setAdvancedVisible] = useState<boolean>(false);
  const [advancedData, setAdvancedData] = useState<object[]>([
    { type: 'AND', text: '', exact: false, searchInTitle: false }
  ]);

  const [codesVisible, setCodesVisible] = useState<boolean>(false);
  const [codesSearchValue, setCodesSearchValue] = useState<string>('');
  const [codesLoading, setCodesLoading] = useState<boolean>(false);
  const [codesLoaded, setCodesLoaded] = useState<boolean>(false);
  const [codesError, setCodesError] = useState<boolean>(false);
  const [codesData, setCodesData] = useState<any>([]);
  const [codesRI, setCodesRI] = useState<number>(0);

  const updateData = () => setSubmit(submit + 1);

  return (
    <CodesFiltersContext.Provider
      value={{
        submit,
        updateData,

        order,
        setOrder,

        page,
        setPage,

        advancedData,
        advancedVisible,
        setAdvancedData,
        setAdvancedVisible,

        codesData,
        codesError,
        codesLoaded,
        codesLoading,
        codesRI,
        codesSearchValue,
        codesVisible,
        setCodesData,
        setCodesError,
        setCodesLoaded,
        setCodesLoading,
        setCodesRI,
        setCodesSearchValue,
        setCodesVisible
      }}>
      {children}
    </CodesFiltersContext.Provider>
  );
};
