export class PeriodicEditionInfoModel {
  constructor(
    public editionNumId: string,
    public editionTitle: string,
    public editionNumber: string,
    public editionStartMonth: string,
    public editionEndMonth: string,
    public editionStartYear: string,
    public editionFrequency: string,
    public editionDate: string,
    public periodicNumId: string,
    public periodicPublishType: string,
    public periodicAcronym: string,
    public periodicTitle: string,
    public periodicDescription: string,
    public periodicCdu: string,
    public periodicCdd: string,
    public periodicIssn: string,
    public periodicIssnImpresso: string,
    public periodicQualisSeal: string,
    public periodicTags: any[],
    public periodicExpedient: string,
    public periodicPublicationStandard: string
  ) {}
}
