import { useState, useRef, useContext } from 'react';
import { Codes } from '../../interfaces/Codes';
import { CodesFiltersContext } from '../../CodesFiltersContext';
import { NavbarContext } from '../../../../../components/_shell/NavbarFilters/NavbarContext';
import { http_client } from '../../../../../util/http_client';
import {
  Pager,
  getUrlParamsForSearch
} from '../../../../../components/v2/searchHelpers';
import { GlobalFiltersContext } from '../../../home/GlobalFiltersContext';

export const useFiltersRequest = () => {
  const defaultPager = { currentPage: 0, pages: 0, perPage: 0, total: 0 };
  const areasLoaded = useRef<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [pager, setPager] = useState<Pager>(defaultPager);
  const [data, setData] = useState<Codes[]>([]);
  const { searchScope } = useContext(NavbarContext);
  let { codesData, order, page, setPage } = useContext(CodesFiltersContext);
  let { advancedData } = useContext(GlobalFiltersContext);

  const request = async () => {
    setLoading(true);
    codesData = codesData && codesData.length ? codesData : [];
    advancedData = advancedData && advancedData.length ? advancedData : [];
    const searchInTitle = searchScope === 0 ? false : true;

    const body = {
      page,
      order,
      filters: {
        codes: codesData.filter((e) => e.checked).map((e) => e.key),
        advanced: advancedData
          .filter((e) => e.text.length > 0)
          .map((e) => ({ ...e, searchInTitle }))
      }
    };

    history.pushState(
      {},
      null,
      getUrlParamsForSearch('/conteudo/codigos', {
        search: advancedData.length > 0 ? advancedData[0].text : '',
        searchScope: searchScope ? 1 : 0,
        sortBy: order
      })
    );

    try {
      const res = await http_client.post<any>(
        `${process.env.REACT_APP_ENTITY_SEARCH}/codes`,
        body
      );
      setData(res.data.hits);
      setPager({
        currentPage: res.data.currentPage || 0,
        pages: res.data.pages || 0,
        perPage: res.data.perPage || 0,
        total: res.data.total || 0
      });
      setLoading(false);
      window.scrollTo(0, 0);
    } catch (e) {
      setData([]);
      setPage(1);
      setPager({ currentPage: 0, pages: 0, perPage: 0, total: 0 });
      setLoading(false);
    }

    areasLoaded.current = true;

    return data;
  };

  const loadFilters = async () => request();

  return {
    data,
    pager,
    page,
    error,
    loading,
    setData,
    setPager,
    setPage,
    setError,
    loadFilters,
    setLoading
  };
};

export default useFiltersRequest;
