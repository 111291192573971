import React, { useContext, useEffect, useRef, useState } from 'react';
import useFiltersRequest from './hooks/search/useFiltersRequest';
import { Divider, Row, Col, Pagination, Spin } from 'antd';
import { Container, ContentContainer } from './components/LayoutFilter';
import { List } from './components/common/List';
import { CodesFiltersContext } from './CodesFiltersContext';
import { OrderFilter } from './components/filters/OrderFilter';
import { SubFilters, CodesResultEmpty } from './styles';
import { useQuery } from '../_shared/useQuery';
import { GlobalFiltersContext } from '../home/GlobalFiltersContext';

export const CodesList: React.FC = () => {
  const mountedRef = useRef(false);
  const [searchBanner, setSearchBanner] = useState([]);

  const { submit, updateData } = useContext(CodesFiltersContext);

  const { advancedData, submit: submitGlobal } =
    useContext(GlobalFiltersContext);

  const { data, error, page, pager, loading, setPage, loadFilters } =
    useFiltersRequest();

  const firstAdvancedSearch = () => {
    const filters =
      advancedData && advancedData.filter
        ? advancedData.filter((e) => e.text.length > 0)
        : [];
    return filters.length > 0 ? filters[0].text : '';
  };

  const loadData = async () => loadFilters();
  const search = useQuery().get('search') || firstAdvancedSearch() || '';

  const setSBanner = () => {
    const plural = pager.total > 1 ? 's' : '';
    const strOrderBy = `ordenado${plural} por: `;
    const subFiltersHasSearchTpl = [
      `${pager.total} registro${plural} encontrado${plural} para a busca <span class=b>"${search}"</span> em <span class=r>códigos</span>, `,
      strOrderBy
    ];

    setSearchBanner(
      search ? subFiltersHasSearchTpl : [`${pager.total} itens ${strOrderBy}`]
    );
  };

  useEffect(() => {
    if (mountedRef.current) {
      loadData();
    } else {
      mountedRef.current = true;
    }
  }, [page, submit, submitGlobal]);

  useEffect(() => {
    if (search) {
      updateData();
    } else {
      loadData();
    }

    mountedRef.current = true;
  }, []);

  const errorTpl = <p>Houve um erro ao carregar os códigos...</p>;
  const loadingTpl = (
    <div className="loading-container">
      <Spin tip="Carregando" size="large" />
    </div>
  );

  useEffect(() => {
    setSBanner();
  }, [pager.total, submit, submitGlobal]);

  if (!pager.total && !loading && !error) {
    return (
      <Container>
        <ContentContainer>
          <CodesResultEmpty>
            Nenhum resultado encontrado para esta consulta.
          </CodesResultEmpty>
        </ContentContainer>
      </Container>
    );
  }

  return (
    <Container>
      {loading ? (
        loadingTpl
      ) : (
        <ContentContainer>
          <Col>
            {error ? (
              errorTpl
            ) : (
              <>
                <SubFilters>
                  <p>
                    {searchBanner.map((info, index) => (
                      <span
                        key={index}
                        className={`search-${index}-${searchBanner.length}`}
                        dangerouslySetInnerHTML={{ __html: info }}
                      />
                    ))}
                  </p>
                  <OrderFilter />
                </SubFilters>
                <List items={data} />
              </>
            )}
          </Col>
          <Row type="flex" justify="center">
            <Col>
              <Divider />
              <Pagination
                current={pager.currentPage}
                pageSize={pager.perPage}
                total={pager.total}
                onChange={setPage}
              />
              <br />
            </Col>
          </Row>
        </ContentContainer>
      )}
    </Container>
  );
};
