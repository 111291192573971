import React from 'react';
import { Periodic } from '../../interfaces/Periodic';

import CalendarIcon from '../../../../../assets/images/calendar-icon-4.svg';
import { PeriodicInnerFields, PeriodicInfoImg } from '../../styles';

export const Info = ({ item }) => {
  const year = (el: Periodic) => new Date(el.startMonth).getUTCFullYear();
  const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);
  const startMonth = (el: Periodic) =>
    capitalize(
      String(
        new Date(el.startMonth).toLocaleString('pt-BR', {
          month: 'short',
          timeZone: 'UTC'
        })
      )
    );
  const endMonth = (el: Periodic) =>
    capitalize(
      String(
        el.endMonth
          ? new Date(el.endMonth).toLocaleString('pt-BR', {
              month: 'short',
              timeZone: 'UTC'
            })
          : ''
      )
    );

  let gambi = '';
  if (item.id === 'P275-E52282') {
    gambi = 'Vol. 1, No. 1, Janeiro/Junho 2023';
  }
  if (item.id === 'P275-E52373') {
    gambi = 'Vol. 1, No. 2, Julho/Dezembro 2023';
  }
  return (
    <PeriodicInnerFields>
      <PeriodicInfoImg src={CalendarIcon} />
      <span>
        {/* TODO remover mock */}
        {gambi
          ? gambi
          : `${`Número ${String(item.text_3)
              .replaceAll('Edição ', '')
              .replaceAll('Número ', '')}`}, Ano ${year(item)}, ${startMonth(
              item
            )}${endMonth(item) ? `/${endMonth(item)}` : ''} - ${year(item)}`}
      </span>
    </PeriodicInnerFields>
  );
};
