import styled from 'styled-components';
import { device } from '../../styledComponent';

export const Container = styled.div`
  position: relative;
  z-index: 12;
  backdrop-filter: saturate(180%) blur(10px);
  background: linear-gradient(
    90deg,
    rgba(0, 76, 146, 1) 0%,
    rgba(162, 49, 71, 1) 100%
  );
`;

export const Navbar = styled.nav`
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  transition: all ease-in-out 1s;
  margin-left: 16px;
  margin-right: 16px;

  @media ${device.sm} {
  }
`;

export const Menus = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  margin-left: 16px;
  margin-right: 16px;
`;

export const Link = styled.a`
  color: black !important;
`;

export const ContainerMobile = styled.div`
  width: 100%;
  height: 150px !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const ContainerMobileWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;
