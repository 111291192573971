import { useState } from 'react';
import { http_client } from '../../../../../util/http_client';
import { Informative } from '../../interfaces/Informative';

export const useInformativeRequest = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const defaultData = {
    num_id: '',
    is_published: true
  };
  const [data, setData] = useState<Informative>(defaultData);
  const request = async (type: string, date: string) => {
    setLoading(true);
    try {
      const res = await http_client.get<any>(
        `${process.env.REACT_APP_ENTITY_SEARCH}/informatives/${type}/show/${date}`
      );
      setData(res.data);
      setLoading(false);
      window.scrollTo(0, 0);
    } catch (e) {
      setData(defaultData);
      setLoading(false);
    }

    return data;
  };

  return {
    data,
    loading,
    request,
    setLoading
  };
};

export default useInformativeRequest;
