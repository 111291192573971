import { Icon } from 'antd';
import React, { useContext } from 'react';
import { InformativesNavBarClear } from './styles';
import { InformativesFiltersContext } from './InformativesFiltersContext';
import { NavbarContext } from '../../../components/_shell/NavbarFilters/NavbarContext';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { GlobalFiltersContext } from '../home/GlobalFiltersContext';
import { DateFilter } from './components/filters/DateFilter';

const Container = styled.div`
  display: flex;
`;

export const InformativesNavBarFilters: React.FC = () => {
  const history = useHistory();

  const {
    informativesData,
    dateData,
    setInformativesData,
    setDateData,
    updateData,
    setOrder,
    setPage
  } = useContext(InformativesFiltersContext);

  const { advancedData } = useContext(GlobalFiltersContext);

  const { setSearchString } = useContext(NavbarContext);

  const activeFilters = [...(informativesData || [])].filter(
    (e) => e.checked
  ).length;

  const dateFilter = (dateData || []).filter((e) => e.start || e.end).length
    ? 1
    : 0;

  const advancedFilters = (advancedData || []).filter(
    (e) => e.text.length > 0
  ).length;
  const activeCount = advancedFilters + activeFilters + dateFilter;

  const checkFalse = (e) => ({ ...e, checked: false });
  const checkRangeFalse = (e) => ({ ...e, start: false, end: false });
  const clearFilters = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('search');
    history.replace({ search: queryParams.toString() });

    // Global
    setSearchString('');
    // Informatives
    setDateData(dateData.map(checkRangeFalse));
    setInformativesData(informativesData.map(checkFalse));
    setOrder('relevance');
    setPage(1);
    updateData();
  };

  return (
    <Container>
      <DateFilter />
      <InformativesNavBarClear>
        <div
          onClick={clearFilters}
          style={{
            cursor: 'pointer'
          }}>
          <Icon
            type="delete"
            style={{ fontSize: 16, marginTop: 8 }}
            className="pointer"
          />{' '}
          <span
            style={{
              fontSize: 12,
              color: '#004c92',
              fontWeight: 'bold',
              cursor: 'pointer'
            }}>
            Limpar filtros à esquerda
          </span>
        </div>
      </InformativesNavBarClear>
    </Container>
  );
};
