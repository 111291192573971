import React, { useContext, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { AxiosResponse } from 'axios';
import { Link } from 'react-router-dom';
import { EffectCoverflow, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSearchFilter } from '../../../pesquisa/hooks/useSearchFilter';
import { Card } from './card';
import { getPeriodics } from './services/getPeriodics';
import { Container } from './styles';
import { Occurrences } from './types/occurrences';
import { linkWithGlobalSearch } from '../../_shared/navigation';
import { NavbarContext } from '../../../../components/_shell/NavbarFilters/NavbarContext';
import { List } from '../../periodic/components/common/List';
import {
  SearchLinkMoreResults,
  SearchResults,
  Separator
} from '../../_shared/styles';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { GlobalFiltersContext } from '../../home/GlobalFiltersContext';
import iconFilter from '../../../../assets/images/filters.svg';

interface IParams {
  setTotal?: (total: number) => any;
  setIsInSearch?: (value: boolean) => any;
  setLoading?: (value: boolean) => any;
}

export const ContentListPeriodic = ({
  setTotal,
  setIsInSearch,
  setLoading
}: IParams) => {
  const [results, setResults] = useState<any>([]);
  const [loading, isLoading] = useState(false);
  const [status, setStatus] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  const { filter } = useSearchFilter();
  const { searchScope } = useContext(NavbarContext);
  const { areaData, authorData, dateData, submit, advancedData } =
    useContext(GlobalFiltersContext);

  useEffect(() => {
    verifyIsInSearch();
  }, []);

  const getPeriodicAuthor = (periodic: any) => {
    let result = '';

    if ('authors' in periodic.entity) {
      result = periodic.entity.authors.map((el: any) => el.title).join(', ');
    }

    if (result.trim()) return result;

    return periodic.tags
      .filter((el: any) => String(el.id).startsWith('author_'))
      .map((el: any) => el.title.trim())
      .join(', ');
  };

  const mapListPeriodics = (el) => ({
    id: el.id,
    title: el.text_1,
    date: el.publish_date.split('T')[0],
    desc: el.text_2,
    link: `/v2/revista/${el.id.replace('-', '/')}`,
    author: getPeriodicAuthor(el),
    contents: el.contents,
    highlightContents: el.highlight_contents,
    number: el.text_3,
    startMonth: el.startMonth,
    endMonth: el.endMonth
  });

  const fetchData = async () => {
    const data = { page: 1, pageSize: 5, filter: filter.words, searchScope };
    const dateStart = dateData.filter((e) => e.start);
    const dateEnd = dateData.filter((e) => e.end);
    const advanced = advancedData.filter((e) => e.text);
    const filters = {
      advanced,
      area: areaData.filter((e) => e.checked).map((e) => e.key),
      author: authorData.filter((e) => e.checked).map((e) => e.key),
      date: [
        dateStart[0] ? dateStart[0].value : null,
        dateEnd[0] ? dateEnd[0].value : null
      ]
    };

    const response: AxiosResponse<Occurrences> = await getPeriodics(
      data,
      filters
    );

    verifyIsInSearch();
    setResults(response.data.hits);
    if (setTotal) setTotal(response.data.total || 0);
    setStatus(response.status);
    isLoading(false);
  };

  const verifyIsInSearch = () => {
    const advanced = advancedData.filter((e) => e.text);
    const dateStart = dateData.filter((e) => e.start);
    const dateEnd = dateData.filter((e) => e.end);
    const area = areaData.filter((e) => e.checked).map((e) => e.key);
    const author = authorData.filter((e) => e.checked).map((e) => e.key);
    const dateStartParam = dateStart[0] ? dateStart[0].value : null;
    const dateEndParam = dateEnd[0] ? dateEnd[0].value : null;
    if (setIsInSearch) {
      if (
        `${filter.words}`.trim() !== '' ||
        area.length ||
        author.length ||
        advanced.length
      ) {
        setIsInSearch(true);
        setIsSearch(true);
      } else if (dateStartParam && dateEndParam) {
        setIsInSearch(true);
        setIsSearch(true);
      } else {
        setIsInSearch(false);
        setIsSearch(false);
      }
    }
  };

  useEffect(() => {
    verifyIsInSearch();
    isLoading(true);
    fetchData();
  }, [searchScope, filter.words, submit]);

  useEffect(() => {
    if (setLoading) setLoading(loading);
  }, [loading]);

  return (
    <Container id="element-7">
      {isSearch ? (
        <>
          <div className="title">
            <h2></h2>
            <Link
              to={linkWithGlobalSearch(
                'periodicv2',
                filter.words,
                searchScope
              )}>
              <span
                className="link link-filter"
                id="element-8"
                style={{ marginTop: 0 }}>
                <img
                  className="icon-filter"
                  width={20}
                  height={20}
                  src={iconFilter}
                  alt={iconFilter}
                />
                Pesquisar somente em revistas
              </span>
            </Link>
          </div>
          <Separator>
            <div className="sep">
              <span className="sep-inner" />
            </div>
          </Separator>
        </>
      ) : null}
      {loading && <Spin size="large" style={{ marginTop: '200px' }} />}
      {!loading && results.length === 0 && status === 200 && (
        <div className="message-wrapper">
          <p>Nenhum resultado encontrado para Revistas.</p>
        </div>
      )}
      {!loading && status !== 200 && (
        <p className="message-wrapper">Houve um erro ao carregar Revistas.</p>
      )}
      {!loading &&
        results.length > 0 &&
        (isSearch ? (
          <div>
            <SearchResults>
              <List items={results} />
            </SearchResults>
            <SearchLinkMoreResults>
              <Link
                className="call-to-list"
                to={linkWithGlobalSearch(
                  'periodicv2',
                  filter.words,
                  searchScope
                )}>
                Ver mais resultados de Revistas →
              </Link>
            </SearchLinkMoreResults>
            <br />
          </div>
        ) : (
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={30}
            pagination={{
              clickable: true,
              type: 'progressbar'
            }}
            navigation={true}
            modules={[Pagination, Navigation, EffectCoverflow]}
            className="swiper">
            {results.map(mapListPeriodics).map((el: any, i: number) => (
              <SwiperSlide key={i}>
                <Card
                  id={el.id}
                  title={el.title}
                  desc={el.desc}
                  link={el.link}
                  contents={el.contents}
                  highlights={el.highlightContents}
                  number={el.number}
                  startMonth={el.startMonth}
                  endMonth={el.endMonth}
                />
              </SwiperSlide>
            ))}
            <SwiperSlide className="call-to-action">
              <Link
                className="call-to-action-text"
                to={linkWithGlobalSearch(
                  'periodicv2',
                  filter.words,
                  searchScope
                )}>
                Ver todas as revistas
              </Link>
            </SwiperSlide>
          </Swiper>
        ))}
    </Container>
  );
};
