import { Icon } from 'antd';
import React, { useContext } from 'react';
import { AreaFilter } from './components/filters/AreaFilter';
import { AuthorFilter } from './components/filters/AuthorFilter';
import { DateFilter } from './components/filters/DateFilter';
import { GlobalNavBarClear } from './styles';
import { GlobalFiltersContext } from './GlobalFiltersContext';
import { NavbarContext } from '../../../components/_shell/NavbarFilters/NavbarContext';
import { useHistory } from 'react-router';
import styled, { keyframes } from 'styled-components';

const Container = styled.div`
  position: fixed;
  display: flex;
`;

const clickAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
`;

export const GlobalNavBarFilters: React.FC = () => {
  const history = useHistory();

  const {
    areaData,
    authorData,
    dateData,
    advancedData,
    setAreaData,
    setAuthorData,
    setAdvancedData,
    updateData,
    setAreaSearchValue,
    setAuthorSearchValue,
    setDateData,
    setOrder,
    setPage
  } = useContext(GlobalFiltersContext);

  const { setSearchString } = useContext(NavbarContext);

  const activeFilters = [...(areaData || []), ...(authorData || [])].filter(
    (e) => e.checked
  ).length;
  const dateFilter = (dateData || []).filter((e) => e.start || e.end).length
    ? 1
    : 0;
  const advancedFilters = advancedData.filter((e) => e.text.length > 0).length;
  const activeCount = advancedFilters + activeFilters + dateFilter;

  const checkFalse = (e) => ({ ...e, checked: false });
  const checkRangeFalse = (e) => ({ ...e, start: false, end: false });
  const clearFilters = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('search');
    history.replace({ search: queryParams.toString() });

    // Global
    setSearchString('');
    setAreaSearchValue('');
    setAuthorSearchValue('');
    // setAdvancedData([{ type: 'AND', text: '', exact: false }]);
    setAreaData(areaData.map(checkFalse));
    setAuthorData(authorData.map(checkFalse));
    setDateData(dateData.map(checkRangeFalse));
    setPage(1);
    updateData();
  };

  return (
    <Container className="container-nav-bar-filters">
      <AreaFilter />
      <AuthorFilter />
      <DateFilter />
      <GlobalNavBarClear>
        <div
          onClick={clearFilters}
          style={{
            cursor: 'pointer'
          }}>
          <Icon
            type="delete"
            style={{ fontSize: 16, marginTop: 8 }}
            className="pointer"
          />{' '}
          <span
            className="clear-right-filters"
            style={{
              fontSize: 12,
              color: '#004c92',
              fontWeight: 'bold',
              cursor: 'pointer'
            }}>
            Limpar filtros à esquerda
          </span>
        </div>
      </GlobalNavBarClear>
    </Container>
  );
};
