import React, { useContext } from 'react';
import { Select } from 'antd';
import { InformativesOrder } from '../../styles';
import { InformativesFiltersContext } from '../../InformativesFiltersContext';
import {
  OrderType,
  sortByOptionsInformative as sortByOptions
} from '../../../../../components/v2/searchHelpers';

export const OrderFilter = () => {
  const { Option } = Select;

  const { order, setOrder, updateData, setPage } = useContext(
    InformativesFiltersContext
  );

  const onSelect = (val: OrderType) => {
    if (setOrder) setOrder(val);
    setPage(1);
    if (updateData) updateData();
  };

  return (
    <InformativesOrder>
      <Select
        optionLabelProp="title"
        filterOption={false}
        onSelect={onSelect}
        value={order}>
        {sortByOptions
          .filter((e) => e.show)
          .map((d, i) => (
            <Option key={i} value={d.value} title={d.title}>
              {d.title}
            </Option>
          ))}
      </Select>
    </InformativesOrder>
  );
};
