export type LinkType = 'book' | 'periodic' | 'periodicv2' | 'video';

const linkTypeMap = {
  book: '/conteudo/livros',
  video: '/conteudo/videos',
  periodicv2: '/conteudo/revistas',
  periodic: '/pesquisa/revistas'
};

const legacyParams = (scope: string | number, search: string) => {
  const params = {
    MonthYearEnd: '',
    areasInterestIdsList: '',
    articleType: 1,
    authorsIdList: '',
    bookIdList: '',
    clippingDate: new Date().toISOString().split('T')[0],
    clippingId: 'jacoby',
    clippingMonthly: 'false',
    codeIdList: '',
    dateInterval: 0,
    eventsIdList: '',
    isGrouped: 'false',
    monthYearInitial: '',
    page: 1,
    periodicIdList: '',
    rows: 20,
    searchScope: scope,
    seriesIdList: '',
    videoSeriesIdList: '',
    words: encodeURIComponent(search)
  };

  return Object.keys(params).reduce(
    (acc, key) => `${acc}${key}=${params[key]}&`
  );
};

export const linkWithGlobalSearch = (
  type: LinkType,
  search: string,
  scope: string | number
) => {
  const base = linkTypeMap[type];
  const key = type === 'periodic' ? 'words' : 'search';

  if (search) {
    if (type === 'periodic') return `${base}?${legacyParams(scope, search)})}`;

    return `${base}?searchScope=${scope}&${key}=${encodeURIComponent(search)}`;
  }

  return base;
};
