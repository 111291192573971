import _ from 'lodash';
import React, { useCallback, useContext, useEffect } from 'react';
import 'react-image-lightbox/style.css';
import { RouteComponentProps } from 'react-router';
import { ContentNotAvailable } from '../../../components/ContentNotAvailable';
import { ErrorMessage } from '../../../components/ErrorMessage';
import { StartReadingButton } from '../../../components/StartReadingButton';
import { MultipleFilteredSelectParser } from '../../../components/_inputs/MultipleFilteredSelect';
import { BackToTopButton } from '../../../components/_shell/BackToTopButton';
import { ContentBelowHeader } from '../../../components/_templates/ContentBelowHeader';
import { ContentPageTemplate } from '../../../components/_templates/ContentPageTemplate/ContentPageTemplate';
import { ContentType } from '../../../enums/ContentType';
import { useReturnUrlFromPdf } from '../../../Hooks/useReturnUrlFromPdf';
import { PeriodicContextModel } from '../../../models/periodic/PeriodicContextModel';
import { ContentSearchResult } from '../../../models/ContentSearchResult';
import { SearchFilter } from '../../../models/search/SearchFilter';
import { PeriodicContext } from './PeriodicContextProvider';
import { PeriodicEditionItemList } from './PeriodicEditionItemList';
import { ArticleType } from '../../pesquisa/enums/ArticleType';
import slug from '../_shared/slug';
import { Button } from '../../../components/Button';
import { Icon } from 'antd';

export const PeriodicView = ({ match, history }: RouteComponentProps) => {
  const { returnToHereFromPdf } = useReturnUrlFromPdf();
  const ctx = useContext<{ state: PeriodicContextModel }>(
    PeriodicContext as any
  );
  const { editionsRequest, articlesRequest } = ctx.state;

  const editions: any =
    editionsRequest.result !== 204 ? editionsRequest.result || [] : [];
  const editionIdParam = _.get(match, 'params.editionId', null) || '';
  const editionId = editionIdParam.replace('P', 'E');
  const itemId = _.get(match, 'params.itemId') || '';

  const periodic = editions.find(
    (edition: { editionNumId: string }) =>
      String(edition.editionNumId) === String(editionId)
  );

  const periodicId: string = _.get(match, 'params.periodicId') || '';
  const items: any =
    articlesRequest && articlesRequest.result ? articlesRequest.result : [];
  const doctrines =
    items && items.filter
      ? items.filter((d) => d.articleType === ArticleType.DOUTRINA)
      : [];
  const jurisprudences =
    items && items.filter
      ? items.filter((d) => d.articleType === ArticleType.JURISPRUDENCIA)
      : [];
  const firstItemOfEdition: any = _.get(articlesRequest, 'result[0]');

  useEffect(() => {
    if (itemId && Number.isInteger(Number(itemId))) {
      return;
    }
    if (editionId) {
      if (!editions || !editions.length) return;
      history.replace(
        `/v2/revista/${periodicId}/${editionId}${
          editions[0].periodicTitle ? `/${slug(editions[0].periodicTitle)}` : ''
        }`
      );
      return;
    }
    if (editions.length > 0) {
      history.replace(
        `/v2/revista/${periodicId}/${editions[0].editionNumId}${
          editions[0].periodicTitle ? `/${slug(editions[0].periodicTitle)}` : ''
        }`
      );
    }
  }, [editions]);

  useEffect(() => {
    if (!itemId || !Number.isInteger(Number(itemId))) {
      setTimeout(() => returnToHereFromPdf(), 250);
    }
  }, [editionsRequest.loading, editionId]);

  function getEditionTitle(editionId: number) {
    let currentEdition;

    if (editions) {
      currentEdition = editions.filter((e) => e.editionNumId === editionId)[0];

      if (currentEdition) {
        const startMonth = new Date(currentEdition.editionStartMonth);
        let monthStart = startMonth.toLocaleString('pt-BR', {
          month: 'short',
          timeZone: 'UTC'
        });
        monthStart = monthStart[0].toUpperCase() + monthStart.slice(1);
        const endMonth = new Date(currentEdition.editionEndMonth);
        let monthEnd = endMonth.toLocaleString('pt-BR', {
          month: 'short',
          timeZone: 'UTC'
        });
        monthEnd = monthEnd[0].toUpperCase() + monthEnd.slice(1);
        const year = startMonth.getFullYear();
        const isMontly = periodic.editionFrequency === 'Mensal';
        const editionDate =
          monthStart === monthEnd || isMontly
            ? `${monthStart} - ${year}`
            : `${monthStart}/${monthEnd} - ${year}`;
        const titleReplaced = periodic.editionTitle
          ? periodic.editionTitle.replace('Número ', '')
          : '';

        // TODO remover mock
        let gambi = '';

        if (currentEdition.editionNumId === 'E52282') {
          gambi = 'Vol. 1, No. 1, Janeiro/Junho 2023';
        }
        if (currentEdition.editionNumId === 'E52373') {
          gambi = 'Vol. 1, No. 2, Julho/Dezembro 2023';
        }

        return gambi
          ? gambi
          : `${`Número ${String(periodic.editionTitle)
              .replaceAll('Edição ', '')
              .replaceAll(
                'Número ',
                ''
              )}`}, Vol. ${year}, Ano ${year}, ${editionDate}`;
      }
    }
  }

  const onSearch = useCallback(
    (words: string) => {
      const filter = new SearchFilter(words);
      filter.periodicIdList = MultipleFilteredSelectParser.encodeValue({
        value: periodicId,
        text: periodic.title
      });
      history.push(`/conteudo/revistas?v=2&${filter.convertToQueryString()}`);
    },
    [periodic, periodicId]
  );
  const stage = process.env.REACT_APP_STAGE || 'staging';
  const periodicImageSrc = `https://bid1006-${stage}-public.s3.sa-east-1.amazonaws.com/periodics/cover/${periodicId}/${periodicId}.jpg`;

  const convertPeriodicToContentSearchResult = useCallback(() => {
    let editionInfo = '';
    if (periodic) {
      const startMonth = new Date(periodic.editionStartMonth);
      let monthStart = startMonth.toLocaleString('pt-BR', {
        month: 'short',
        timeZone: 'UTC'
      });
      monthStart = monthStart[0].toUpperCase() + monthStart.slice(1);
      const endMonth = new Date(periodic.editionEndMonth);
      let monthEnd = endMonth.toLocaleString('pt-BR', {
        month: 'short',
        timeZone: 'UTC'
      });
      monthEnd = monthEnd[0].toUpperCase() + monthEnd.slice(1);
      const year = startMonth.getFullYear();
      const isMontly = periodic.editionFrequency === 'Mensal';
      const editionDate =
        monthStart === monthEnd || isMontly
          ? `${monthStart} - ${year}`
          : `${monthStart}/${monthEnd} - ${year}`;
      const titleReplaced = periodic.editionTitle
        ? periodic.editionTitle.replace('Número ', '')
        : '';
      editionInfo = `${
        String(titleReplaced).includes('Edição')
          ? titleReplaced
          : `Edição ${titleReplaced}`
      }, Ano ${year}, ${editionDate}`;
    }
    return !periodic
      ? ContentSearchResult.Empty
      : new ContentSearchResult(
          ContentType.PERIODIC,
          periodicId,
          `${periodic.periodicAcronym} - ${periodic.periodicTitle}`,
          null,
          periodic.periodicDescription,
          null,
          editionId,
          periodicId,
          periodicImageSrc,
          null,
          null,
          editionInfo
        );
  }, [periodicId, periodic, editionId]);

  if (editionsRequest.error) {
    return (
      <ContentBelowHeader fullscreen={false}>
        <ErrorMessage error={editionsRequest.error} />
      </ContentBelowHeader>
    );
  }

  if (editionsRequest.result === 204 || articlesRequest.result === 204) {
    return <ContentNotAvailable />;
  }

  const S3_BASE_URL =
    'https://plataforma-docs-publicos.s3.sa-east-1.amazonaws.com/';

  return (
    <>
      <ContentPageTemplate
        result={convertPeriodicToContentSearchResult()}
        loading={editionsRequest.loading}
        title={_.get(periodic, 'periodicTitle')}
        imageSrc={periodicImageSrc}
        editions={editions.map((e) => {
          const titleReplaced = e.editionTitle
            ? e.editionTitle.replace('Número ', '').replace('Edição ', '')
            : '';
          // TODO remover mock
          let gambi = '';
          if (e.editionNumId === 'E52282') {
            gambi = 'Vol. 1, No. 1, Janeiro/Junho 2023';
          }
          if (e.editionNumId === 'E52373') {
            gambi = 'Vol. 1, No. 2, Julho/Dezembro 2023';
          }
          const editionNumber = gambi
            ? gambi
            : titleReplaced
            ? titleReplaced
            : e.editionNumber;
          return { ...e, id: e.editionNumId, editionNumber } || [];
        })}
        selectedEditionId={editionId}
        contentType="Revista"
        subtitle={_.get(periodic, 'subtitle')}
        onEditionClick={(editionId: number) => {
          const e =
            editions && editions.length
              ? editions.find((f) => f.editionNumId === editionId)
              : undefined;
          history.push(
            `/v2/revista/${periodicId}/${editionId}${
              e ? `/${slug(e.periodicTitle)}` : ''
            }`
          );
          window.scroll(0, 0);
        }}
        qualis={_.get(periodic, 'periodicQualisSeal')}
        DescriptionContent={<p>{_.get(periodic, 'periodicDescription')}</p>}
        onSearch={onSearch}
        buttonSearchText="Pesquisar nesta revista"
        DetailsContent={
          <>
            <ul>
              <li>
                <b>CDD:</b> {_.get(periodic, 'periodicCdd')}
              </li>
              <li>
                <b>CDU: </b> {_.get(periodic, 'periodicCdu')}
              </li>
              <li>
                <b>ISSN Impresso: </b>{' '}
                {_.get(periodic, 'periodicIssnImpresso') || '-'}
              </li>
              <li>
                <b>ISSN Digital: </b> {_.get(periodic, 'periodicIssn') || '-'}
              </li>
              <li>
                <b>Periodicidade: </b> {_.get(periodic, 'editionFrequency')}
              </li>
              <li>
                <b>Doutrinas: </b> {doctrines.length || 0}
              </li>
              <li>
                <b>Jurisprudências: </b> {jurisprudences.length || 0}
              </li>
            </ul>

            <div className="buttons-container">
              {_.get(periodic, 'periodicExpedient') && (
                <a
                  href={`${S3_BASE_URL}${_.get(periodic, 'periodicExpedient')}`}
                  target="_blank">
                  <Button type={'default'} style={{ minWidth: 194 }}>
                    <Icon type="eye" /> Expediente
                  </Button>
                </a>
              )}

              {_.get(periodic, 'periodicPublicationStandard') && (
                <a
                  href={`${S3_BASE_URL}${_.get(
                    periodic,
                    'periodicPublicationStandard'
                  )}`}
                  target="_blank">
                  <Button type={'default'} style={{ minWidth: 194 }}>
                    <Icon type="book" /> Normas de publicação
                  </Button>
                </a>
              )}
              <StartReadingButton
                style={{ minWidth: 194 }}
                disabled={!firstItemOfEdition}
                onClick={() => {
                  if ('localStorage' in window) {
                    localStorage.setItem(
                      'pdfReturnTo',
                      `/v2/revista/${periodicId}/${editionId}`
                    );
                  }
                  history.push(
                    `/v2/revista/${periodicId}/${editionId}/${
                      firstItemOfEdition.id || ''
                    }`
                  );
                }}>
                Iniciar leitura
              </StartReadingButton>
            </div>
          </>
        }
        BottomContent={
          <>
            <h2>{getEditionTitle(editionId)}</h2>

            {editionId && (
              <PeriodicEditionItemList {...{ periodic, editionId }} />
            )}
          </>
        }
      />
      <BackToTopButton />
    </>
  );
};
