import React from 'react';
import moment, { Moment } from 'moment';
import styled from 'styled-components';

const NotFoundContainer = styled.div`
  text-align: center;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  header {
    background-color: #002f48;
  }

  header .date {
    font-size: 1em;
    font-weight: bold;
    color: white;
    text-align: left;
    margin-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  main h1 {
    font-size: 1.5em;
    color: #020102;
    margin: 20px 0;
  }

  section p {
    font-size: 1em;
    color: #7f8c8d;
  }
`;

function dateFormat(date: Moment | Date) {
  date = moment(date).toDate();
  const day = [
    'Domingo',
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sábado'
  ][date.getDay()];
  const dateNumber = date.getDate();
  const month = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ][date.getMonth()];
  const year = date.getFullYear();
  return `${day}, ${dateNumber} de ${month} de ${year}`;
}

export const InformativesNotFound = ({ date }: { date: Moment | Date }) => {
  const dateFormated = dateFormat(date);
  return (
    <NotFoundContainer>
      <header>
        <p className="date">{dateFormated}</p>
      </header>
      <main>
        <h1>Não há publicações disponíveis para a presente data.</h1>
        <section>
          <p>RESUMO DOU - DIÁRIO OFICIAL DA UNIÃO</p>
          <p>
            Informamos que, na presente data, não há publicações registradas.
          </p>
        </section>
      </main>
    </NotFoundContainer>
  );
};
