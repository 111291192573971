import { AxiosResponse } from 'axios';
import { http_client } from '../../../../../util/http_client';
import { Occurrences } from '../types/occurrences';

const BASE_URL = `${process.env.REACT_APP_ENTITY_SEARCH}/periodic`;

interface IParams {
  page: number;
  pageSize: number;
  filter: string;
  searchScope: number;
}

export const getPeriodics = async (
  data: IParams,
  filters: any
): Promise<AxiosResponse<Occurrences>> => {
  const { page = 1, filter, searchScope } = data;
  const searchInTitle = searchScope === 0 ? false : true;
  const body = {
    page,
    size: 10,
    order: filters.advanced.length ? 'relevance' : 'date:desc',
    filters: {
      area: filters.area || [],
      author: filters.author || [],
      serie: [],
      date: filters.date || [],
      advanced: filters.advanced
        ? filters.advanced.map((a) => ({ ...a, searchInTitle }))
        : []
    }
  };

  if (filter) {
    body.filters.advanced.push({
      type: 'AND',
      text: filter,
      exact: false,
      searchInTitle
    });
  }

  try {
    const response = await http_client.post<Occurrences>(BASE_URL, body);

    if (response.status !== 200) {
      throw new Error(
        `A requisição falhou com o código de estado ${response.status}`
      );
    }

    return response;
  } catch (error) {
    return {
      status: 0,
      data: {
        currentPage: 0,
        pages: 0,
        perPage: 0,
        total: 0,
        hits: []
      }
    } as AxiosResponse<Occurrences>;
  }
};
