import { ContentSearchResult } from '../../../models/ContentSearchResult';
import { AnnotationModel } from '../../../models/annotation/AnnotationModel';

export class SaveContentAnnotationDto {
  constructor(
    public content: ContentSearchResult,
    public pdfUrl: string,
    public annotation: AnnotationModel
  ) {
    this.sanitizeContent();
  }

  private sanitizeContent() {
    this.content.total = this.content.total || undefined;
    this.content.articleType = this.content.articleType || undefined;
    this.content.editionId =
      this.content && this.content.editionId
        ? String(this.content.editionId).replace('E', '')
        : undefined;
    this.content.parentId =
      this.content && this.content.parentId
        ? String(this.content.parentId).replace('L', '').replace('P', '')
        : undefined;
  }
}
