import React, { useEffect, useRef } from 'react';
import { Badge, Button, Checkbox, Icon, Input, Popover, Tag } from 'antd';
import { GlobalNavBarContainer, GlobalNavBarResults } from '../../../styles';
import { http_client } from '../../../../../../util/http_client';
import { globalFiltersRequest } from '../../../../../../util/globalFiltersRequest';

let searchTimer: any;

export const TimedFetch = ({
  segment,
  placeholder,
  visible,
  searchValue,
  loading,
  loaded,
  error,
  data,
  ri,
  setVisible,
  setSearchValue,
  setLoading,
  setLoaded,
  setError,
  setData,
  setRI,
  updateData,
  fetchOnMount,
  setPage
}) => {
  const { Search } = Input;

  const currentData = data || [];
  const selecteds = currentData.filter((e) => e.checked);

  const mergeSelectedWithData = (newData, oldSelected) => {
    if (!oldSelected.length) return newData;

    const selectedKeys = [...oldSelected].map((e) => e.key);
    const newDataFiltered = newData.filter(
      (e) => !selectedKeys.includes(e.key)
    );
    return [...oldSelected, ...newDataFiltered];
  };

  const fetchData = async (query: string) => {
    const fetchId = ri;
    const periodicUrl = `${process.env.REACT_APP_ENTITY_SEARCH}/periodic/filter/${segment}`;
    const bookUrl = `${process.env.REACT_APP_ENTITY_SEARCH}/book/filter/${segment}`;
    const videoUrl = `${process.env.REACT_APP_ENTITY_SEARCH}/video/filter/${segment}`;

    setRI(ri + 1);

    if (fetchId !== ri) return setLoading(false);

    try {
      const resPeriodic = await globalFiltersRequest(periodicUrl, { query });
      const resBook = await globalFiltersRequest(bookUrl, { query });
      const resVideo = await globalFiltersRequest(videoUrl, { query });
      const allData = [...resPeriodic.data, ...resBook.data, ...resVideo.data];
      const data = [];
      for (const e of allData) {
        const isExists = data.find((d) => d.key === e.key);
        if (!isExists) {
          data.push({ ...e, start: false, end: false });
        }
      }
      setData(mergeSelectedWithData(data, selecteds));
      setError(false);
      setLoading(false);
      setLoaded(true);
    } catch (e) {
      setError(true);
      setLoading(false);
    }
  };

  const onChangeSearch = (ev) => {
    const val = ev.target.value || '';
    setData(selecteds);
    setSearchValue(val);
    setLoading(true);
    setError(false);
    setPage(1);
    if (searchTimer) clearTimeout(searchTimer);
    searchTimer = setTimeout(() => fetchData(val.trim()), 750);
  };

  const onChangeCheckbox = (checked, item) => {
    const index = data.findIndex((e) => e.key === item.key);
    const upData = [...data];
    upData[index] = { ...item, checked };
    setData(upData);
    setPage(1);
    updateData();
  };

  const spacePressedRef = useRef(false);
  const handleKeyUp = (event) => {
    if (event.which === 32) {
      spacePressedRef.current = true;
    }
  };

  const handleVisibleChange = () => {
    if (spacePressedRef.current) {
      setVisible(true);
      spacePressedRef.current = false;
      return;
    }
    return setVisible ? setVisible(!visible) : null;
  };

  const results = (data || []).map((d, i) => (
    <Checkbox
      key={i}
      checked={d.checked}
      onChange={(ev) => onChangeCheckbox(ev.target.checked, d)}>
      {d.value}
    </Checkbox>
  ));

  const tags = (data || []).map((d, i) => (
    <Tag
      key={i}
      closable
      visible={d.checked}
      onClose={() => onChangeCheckbox(false, d)}>
      {d.value}
    </Tag>
  ));

  const content = (
    <GlobalNavBarResults>
      {selecteds.length ? <div className="selecteds">{tags}</div> : null}
      <Search
        autoFocus
        className="filter-search"
        placeholder={`Buscar ${placeholder.toLowerCase()}`}
        value={searchValue}
        onChange={onChangeSearch}
        onKeyUp={handleKeyUp}
      />
      {loading ? <Icon type="loading" /> : null}
      {error ? (
        <div className="error">
          <Icon type="stop" /> Falha ao carregar os resultados
        </div>
      ) : null}
      {!loading && !error ? (
        <>
          {(searchValue || []).length && !(data || []).length ? (
            <div className="empty">
              <Icon type="info-circle" /> Sem resultados para "{searchValue}"
            </div>
          ) : null}

          {(data || []).length ? (
            <div className="results">{results}</div>
          ) : null}
        </>
      ) : null}
    </GlobalNavBarResults>
  );

  useEffect(() => {
    if ((fetchOnMount && !loaded) || (!loaded && error)) fetchData('');
  }, [visible]);

  return (
    <GlobalNavBarContainer>
      <Popover
        trigger="click"
        placement="bottom"
        visible={visible}
        content={content}
        onVisibleChange={handleVisibleChange}
        getPopupContainer={(trigger) => trigger}>
        <Button className="filter-btn">
          {placeholder}
          <Badge count={selecteds.length} />
        </Button>
      </Popover>
    </GlobalNavBarContainer>
  );
};
