import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Button } from 'antd';
import styled from 'styled-components';
import { Container } from './components/LayoutFilter';
import moment, { Moment } from 'moment';
import { CalendarDateInput } from '../../informativos/components/CalendarDateInput';
import useInformativeRequest from './hooks/search/useInformativeRequest';
import { InformativesNotFound } from './InformativesNotFound';
import { InformativesLoading } from './InformativesLoading';
import { GlobalFiltersContext } from '../home/GlobalFiltersContext';
import { InformativesListSearch } from './InformativesListSearch';

const iframeStyle = {
  width: '100%',
  height: '100%',
  pointerEvents: 'none' as React.CSSProperties['pointerEvents'],
  overflow: 'hidden',
  border: 'none'
};

const StyledButton = styled<any>(Button)`
  background: #ca0c51;
  color: #fff !important;
  &:focus,
  &:active,
  &:hover {
    background-color: #cf1322 !important;
    border-color: #cf1322 !important;
  }
`;

const OpacityContainer = styled.div`
  width: 100%;
  height: 750px;
  background: #fff;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 15px;
`;

const CenterContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  color: #fff;
  height: 710px;
  opacity: 0.999;
  margin-top: -710px;
  text-align: center;
  padding-top: 20px;
`;

const InfoBackground = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  height: 125px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 5px;
  padding-top: 10px;
  margin-top: 198px;
`;

const Info = styled.div`
  background: #fff;
  height: 105px;
  width: 55%;
  padding-top: 18px;
  margin: 0 auto;
  border-radius: 8px;
`;

export const InformativesList: React.FC = () => {
  const { data, loading, request } = useInformativeRequest();
  const [type] = useState('jacoby');
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));

  const { advancedData, submit } = useContext(GlobalFiltersContext);

  const firstAdvancedSearch = () => {
    const filters =
      advancedData && advancedData.filter
        ? advancedData.filter((e) => e.text.length > 0)
        : [];
    return filters.length > 0 ? filters[0].text : '';
  };

  useEffect(() => {
    request(type, date);
  }, [date]);

  useEffect(() => {
    const search = firstAdvancedSearch() || '';
    setIsSearch(!!search);
  }, [submit]);

  return isSearch ? (
    <InformativesListSearch />
  ) : (
    <Container>
      <Row
        gutter={16}
        style={{ width: '100%', minHeight: 800, background: '#EAEBEF' }}>
        <Col xl={6}></Col>
        <Col
          span={8}
          xs={24}
          sm={24}
          md={10}
          lg={8}
          xl={8}
          className="calendar-date-input"
          style={{
            textAlign: 'center',
            paddingTop: 21
          }}>
          <CalendarDateInput
            selectingMonth={false}
            currentDate={moment(date)}
            onChange={(newDate: Moment) => {
              setDate(newDate.format('YYYY-MM-DD'));
            }}
          />
        </Col>
        <Col
          span={12}
          xs={24}
          sm={24}
          md={12}
          lg={10}
          xl={10}
          style={{ textAlign: 'left' }}>
          <OpacityContainer>
            <div style={{ width: '100%', height: 700 }}>
              {loading ? (
                <InformativesLoading />
              ) : data.pdf ? (
                <>
                  <iframe
                    data-embed="true"
                    scrolling="no"
                    seamless={false}
                    frameBorder="0"
                    marginHeight={0}
                    marginWidth={0}
                    allowFullScreen
                    style={iframeStyle}
                    src={
                      data.pdf
                        ? `${data.pdf}#toolbar=0&scrollbar=0&scrolling=0&embedded=true`
                        : ''
                    }></iframe>
                  <CenterContainer>
                    <InfoBackground>
                      <Info>
                        <h4 style={{ color: '#233042' }}>
                          <small>Leia o </small>Informativo
                          <small> na íntegra</small>
                          <br />
                        </h4>
                        <a
                          target="_blank"
                          href={
                            data.pdf ? `/v2/informativo/${type}/${date}` : '#'
                          }
                          style={{
                            color: '#233042',
                            textDecoration: 'underline'
                          }}>
                          <StyledButton type="danger">Clique aqui</StyledButton>
                        </a>
                      </Info>
                    </InfoBackground>
                  </CenterContainer>
                </>
              ) : (
                <InformativesNotFound date={moment(date)} />
              )}
            </div>
          </OpacityContainer>
        </Col>
      </Row>
    </Container>
  );
};
