import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useSearchContext } from '../../../pages/pesquisa/hooks/useSearchContext';
import { useSearchFilter } from '../../../pages/pesquisa/hooks/useSearchFilter';
import { VideoFiltersContext } from '../../../pages/v2/video/VideoFiltersContext';
import { BookFiltersContext } from '../../../pages/v2/book/BookFiltersContext';
import { PeriodicFiltersContext } from '../../../pages/v2/periodic/PeriodicFiltersContext';
import { CodesFiltersContext } from '../../../pages/v2/codes/CodesFiltersContext';
import { Container, GlobalNavBarAdvanced, RadioGroup, Wrapper } from './styles';
import { NavbarContext } from '../NavbarFilters/NavbarContext';
import { useQuery } from '../../../pages/v2/_shared/useQuery';
import { SearchFilter } from '../../../models/search/SearchFilter';
import {
  bookRoutes,
  getActivePath,
  getUrlParamsForSearch,
  getValidSortBy,
  goTo,
  ignoreSearchOnFirstAccess,
  periodicRoutes,
  videoRoutes,
  periodicRoutesV2,
  codesRoutesV2,
  informativesRoutesV2
} from '../../v2/searchHelpers';
import { GlobalAdvancedFilter } from './GlobalAdvancedFilter';
import { GlobalFiltersContext } from '../../../pages/v2/home/GlobalFiltersContext';
import { Icon, Popover } from 'antd';
import { InformativesFiltersContext } from '../../../pages/v2/informatives/InformativesFiltersContext';

export const processAdvancedSearch = (
  search: string = '',
  searchInTitle: string | number = '0'
) => {
  const results = [];
  const andOr = ['AND', 'OR'];
  const and = 'AND';
  const not = 'NOT';
  const isDelimiter = (val: string) => [' and ', ' or ', ' not '].includes(val);
  const advDataSplit = search
    .trim()
    .toLowerCase()
    .replace(/^(and\s|or\s|not\s)/g, '')
    .replace(/(\sand|\sor|\snot)$/g, '')
    .split(/(\sand\s|\sor\s|\snot\s)/g);

  advDataSplit.forEach((val: string, ind: number, arr: any[]) => {
    if (isDelimiter(val)) return;

    const exact = false;
    const prev = arr[ind - 1];
    const lastResult = results[results.length - 1];
    const prevIsDelimiter = ind > 0 && isDelimiter(prev);
    const type = prevIsDelimiter ? prev.trim().toUpperCase() : and;
    const text = val.trim();

    if (ind === 0 || !lastResult) {
      return results.push({
        type,
        text,
        exact,
        searchInTitle
      });
    }

    if (andOr.includes(type) && andOr.includes(lastResult.type)) {
      return (lastResult.text = `${lastResult.text} ${text}`);
    } else if (type === not && lastResult.type === not) {
      return (lastResult.text = `${lastResult.text} ${text}`);
    }

    results.push({ type, text, exact, searchInTitle });
  });

  return results;
};

export const NavbarSearch = () => {
  const {
    REACT_APP_NEW_BOOK_VERSION,
    REACT_APP_NEW_PERIODIC_VERSION,
    REACT_APP_NEW_CODES_VERSION,
    REACT_APP_NEW_INFORMATIVES_VERSION
  } = process.env;
  const { filter } = useSearchFilter();
  const { changeFilter } = useSearchContext();

  const location = useLocation();
  const history = useHistory();
  const searchParam = useQuery().get('search');
  const wordsParam = useQuery().get('words');
  const scopeParam = useQuery().get('searchScope');
  const sortParam = useQuery().get('sortBy');
  const isNewBookVers = String(REACT_APP_NEW_BOOK_VERSION) === 'true';
  const isNewPeriodicVers = String(REACT_APP_NEW_PERIODIC_VERSION) === 'true';
  const isNewCodesVers = String(REACT_APP_NEW_CODES_VERSION) === 'true';
  const isNewInformativesVers =
    String(REACT_APP_NEW_INFORMATIVES_VERSION) === 'true';

  const {
    updateData: updateGlobalData,
    setAreaSearchValue: setGlobalAreaSearchValue,
    setAuthorSearchValue: setGlobalAuthorSearchValue,
    setAreaData: setGlobalAreaData,
    areaData: areaGlobalData,
    setAuthorData: setGlobalAuthorData,
    authorData: authorGlobalData,
    setDateData: setGlobalDateData,
    dateData: dateGlobalData,
    advancedData: advancedGlobalData,
    setAdvancedData: setAdvancedGlobalData,
    setOrder: setGlobalOrder,
    setPage: setGlobalPage
  } = useContext(GlobalFiltersContext);

  const {
    searchCount,
    searchString,
    searchScope,
    searchOpen,
    setSearchCount,
    setSearchString,
    setSearchScope
  } = useContext(NavbarContext);

  const {
    updateData: updateVideoData,
    setOrder: setVideoOrder,
    setPage: setVideoPage,
    setAreaSearchValue: setVideoAreaSearchValue,
    setAuthorSearchValue: setVideoAuthorSearchValue,
    setEventSearchValue: setVideoEventSearchValue,
    setSerieSearchValue: setVideoSerieSearchValue,
    setAreaData: setVideoAreaData,
    setAuthorData: setVideoAuthorData,
    setEventData: setVideoEventData,
    setSerieData: setVideoSerieData,
    setDateData: setVideoDateData,
    areaData: areaVideoData,
    authorData: authorVideoData,
    eventData: eventVideoData,
    serieData: serieVideoData,
    dateData: dateVideoData
  } = useContext(VideoFiltersContext);

  const {
    updateData: updateBookData,
    setOrder: setBookOrder,
    setPage: setBookPage,
    setAreaSearchValue: setBookAreaSearchValue,
    setAuthorSearchValue: setBookAuthorSearchValue,
    setSerieSearchValue: setBookSerieSearchValue,
    setAreaData: setBookAreaData,
    setAuthorData: setBookAuthorData,
    setSerieData: setBookSerieData,
    setDateData: setBookDateData,
    areaData: areaBookData,
    authorData: authorBookData,
    serieData: serieBookData,
    dateData: dateBookData
  } = useContext(BookFiltersContext);

  const {
    updateData: updatePeriodicData,
    setAreaSearchValue: setPeriodicAreaSearchValue,
    setAuthorSearchValue: setPeriodicAuthorSearchValue,
    setAreaData: setPeriodicAreaData,
    setAuthorData: setPeriodicAuthorData,
    setDateData: setPeriodicDateData,
    setOrder: setPeriodicOrder,
    setPage: setPeriodicPage,
    areaData: areaPeriodicData,
    authorData: authorPeriodicData,
    dateData: datePeriodicData
  } = useContext(PeriodicFiltersContext);

  const {
    updateData: updateCodesData,
    setOrder: setCodesOrder,
    setPage: setCodesPage
  } = useContext(CodesFiltersContext);

  const {
    updateData: updateInformativesData,
    setDateData: setInformativeDateData,
    setOrder: setInformativesOrder,
    setPage: setInformativesPage,
    dateData: dateInformativesData
  } = useContext(InformativesFiltersContext);

  const getSearchString = () =>
    String(searchString || searchParam || wordsParam || '').trim();

  const doSearchGlobal = (search: string) => {
    const url = getUrlParamsForSearch('/pesquisa/todos', {
      search,
      searchScope: searchScope || '0',
      sortBy: getValidSortBy(sortParam)
    });
    setGlobalOrder('relevance');
    if (!advancedGlobalData) {
      setAdvancedGlobalData(processAdvancedSearch(search, searchScope));
    }

    updateGlobalData();
    setGlobalPage(1);
    setSearchCount(searchCount + 1);
    setTimeout(() => goTo(history, url), 1);
  };

  const doSearchVideo = (search: string) => {
    const url = getUrlParamsForSearch('/conteudo/videos', {
      search,
      searchScope: searchScope || '0',
      sortBy: getValidSortBy(sortParam)
    });
    setVideoOrder('relevance');

    updateVideoData();
    setVideoPage(1);
    setSearchCount(searchCount + 1);
    setTimeout(() => goTo(history, url), 1);
  };

  const doSearchBook = (search: string) => {
    const url = getUrlParamsForSearch('/conteudo/livros', {
      search,
      searchScope: scopeParam || '0',
      sortBy: getValidSortBy(sortParam)
    });
    setBookOrder('relevance');
    updateBookData();
    setBookPage(1);
    setSearchCount(searchCount + 1);
    setTimeout(() => goTo(history, url), 1);
  };

  const doSearchPeriodicV2 = (search: string) => {
    const url = getUrlParamsForSearch('/conteudo/revistas', {
      search,
      searchScope: scopeParam || '0',
      sortBy: getValidSortBy(sortParam)
    });
    setPeriodicOrder('relevance');
    updatePeriodicData();
    setPeriodicPage(1);
    setSearchCount(searchCount + 1);
    setTimeout(() => goTo(history, url), 1);
  };

  const doSearchCodesV2 = (search: string) => {
    const url = getUrlParamsForSearch('/conteudo/codigos', {
      search,
      searchScope: scopeParam || '0',
      sortBy: getValidSortBy(sortParam)
    });
    setCodesOrder('relevance');
    updateCodesData();
    setCodesPage(1);
    setSearchCount(searchCount + 1);
    setTimeout(() => goTo(history, url), 1);
  };

  const doSearchInformativesV2 = (search: string) => {
    const url = getUrlParamsForSearch('/conteudo/informativos', {
      search,
      searchScope: scopeParam || '0',
      sortBy: getValidSortBy(sortParam)
    });
    setInformativesOrder('relevance');
    updateInformativesData();
    setInformativesPage(1);
    setSearchCount(searchCount + 1);
    setTimeout(() => goTo(history, url), 1);
  };

  const doSearchPeriodic = (search: string) => {
    const filter = new SearchFilter(search);
    filter.searchScope = searchScope;
    filter.words = search;
    filter.page = 0;
    setPeriodicPage(1);
    setSearchCount(searchCount + 1);
    changeFilter(filter);
  };

  const doSearch = (page: string, search: string, searchScope: number) => {
    window.scrollTo({ top: 0 });

    if (!searchCount && ignoreSearchOnFirstAccess) {
      return setSearchCount(searchCount + 1);
    }

    if (!periodicRoutes.includes(page)) {
      filter.searchScope = searchScope;
      filter.words = search;
      filter.page = 0;
      changeFilter(filter);
    }

    if (page === 'search') return doSearchGlobal(search);
    if (videoRoutes.includes(page)) return doSearchVideo(search);
    if (!isNewPeriodicVers && periodicRoutes.includes(page))
      return doSearchPeriodic(search);
    if (isNewBookVers && bookRoutes.includes(page)) return doSearchBook(search);
    if (isNewPeriodicVers && periodicRoutesV2.includes(page))
      return doSearchPeriodicV2(search);
    if (isNewCodesVers && codesRoutesV2.includes(page))
      return doSearchCodesV2(search);
    if (isNewInformativesVers && informativesRoutesV2.includes(page))
      return doSearchInformativesV2(search);
  };

  const handleSearch = () => {
    let search = getSearchString();
    const page = getActivePath(location.pathname);

    if (!searchCount && String(scopeParam) === '1') setSearchScope(1);

    if (searchCount && !searchString) {
      setSearchScope(0);
      search = '';
    }

    doSearch(page, search, searchScope);
  };

  const checkFalse = (e) => ({ ...e, checked: false });
  const checkRangeFalse = (e) => ({ ...e, start: false, end: false });

  const clearFiltersGlobal = () => {
    setGlobalAreaSearchValue('');
    setGlobalAuthorSearchValue('');
    setAdvancedGlobalData([{ type: 'AND', text: '', exact: false }]);
    setGlobalAreaData(areaGlobalData.map(checkFalse));
    setGlobalAuthorData(authorGlobalData.map(checkFalse));
    setGlobalDateData(dateGlobalData.map(checkRangeFalse));
    setGlobalPage(1);
    setGlobalOrder('date:desc');
  };

  const clearFiltersPeriodic = () => {
    setPeriodicAreaSearchValue('');
    setPeriodicAuthorSearchValue('');
    setPeriodicAreaData(areaPeriodicData.map(checkFalse));
    setPeriodicAuthorData(authorPeriodicData.map(checkFalse));
    setPeriodicDateData(datePeriodicData.map(checkRangeFalse));
    setPeriodicPage(1);
    setPeriodicOrder('date:desc');
  };

  const clearFiltersBook = () => {
    setBookAreaSearchValue('');
    setBookAuthorSearchValue('');
    setBookSerieSearchValue('');
    setBookAreaData(areaBookData.map(checkFalse));
    setBookAuthorData(authorBookData.map(checkFalse));
    setBookSerieData(serieBookData.map(checkFalse));
    setBookDateData(dateBookData.map(checkRangeFalse));
    setBookPage(1);
    setBookOrder('date:desc');
  };

  const clearFiltersVideo = () => {
    setVideoAreaSearchValue('');
    setVideoAuthorSearchValue('');
    setVideoEventSearchValue('');
    setVideoSerieSearchValue('');
    setVideoAreaData(areaVideoData.map(checkFalse));
    setVideoAuthorData(authorVideoData.map(checkFalse));
    setVideoEventData(eventVideoData.map(checkFalse));
    setVideoSerieData(serieVideoData.map(checkFalse));
    setVideoDateData(dateVideoData.map(checkRangeFalse));
    setVideoPage(1);
    setVideoOrder('date:desc');
  };

  const clearFiltersCodes = () => {
    setCodesOrder('relevance');
    setCodesPage(1);
  };

  const clearFiltersInformatives = () => {
    setInformativeDateData(dateInformativesData.map(checkRangeFalse));
    setInformativesOrder('relevance');
    setInformativesPage(1);
  };

  const clearFilters = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('search');
    history.replace({ search: queryParams.toString() });

    clearFiltersGlobal();
    clearFiltersPeriodic();
    clearFiltersBook();
    clearFiltersVideo();
    clearFiltersCodes();
    clearFiltersInformatives();

    updateGlobalData();
  };

  useEffect(() => {
    handleSearch();
  }, [searchScope]);

  useEffect(() => {
    setSearchString(getSearchString());
  }, []);

  const scopeOptions = [
    { value: 0, label: 'Em todo conteúdo' },
    { value: 1, label: 'Somente no título' }
  ];

  const [questionVisible, setQuestionVisible] = useState(false);
  const contentQuestion = (
    <div>
      <div style={{ textAlign: 'center' }}>
        <h5>
          <span>
            A amplitude "<b>Somente no título</b>" contempla títulos de{' '}
            <b>livros</b>, <b>capítulos</b>, <b>revistas</b>, <b>doutrinas</b>,{' '}
            <b>jurisprudências</b> e <b>vídeos</b>.
          </span>
        </h5>
      </div>
    </div>
  );

  const [infoVisible, setInfoVisible] = useState(false);
  const contentInfo = (
    <div>
      <div style={{ textAlign: 'center' }}>
        <h5>
          <span>
            Não é mais necessário utilizar <b>comandos booleanos</b>!
            <br />
            Você pode personalizar sua pesquisa com os <b>
              filtros avançados
            </b>{' '}
            da plataforma.
          </span>
        </h5>
      </div>
    </div>
  );

  return (
    <Container id="element-3" className={searchOpen ? 'opened' : 'closed'}>
      <Wrapper id="advanced-bar">
        <div
          className="search-description"
          style={{
            color: 'white',
            marginTop: 12,
            marginRight: 10,
            fontSize: 12
          }}>
          Personalize sua pesquisa:{' '}
          <Icon
            type="question-circle"
            style={{ fontSize: 14, cursor: 'pointer' }}
            onClick={() => {
              setInfoVisible(!infoVisible);
            }}
          />
        </div>
        <Popover
          style={{ zIndex: 1031 }}
          trigger="contextMenu"
          placement="bottom"
          visible={infoVisible}
          content={contentInfo}
          onVisibleChange={() => {
            setInfoVisible(!infoVisible);
          }}
          getPopupContainer={(trigger) => trigger}>
          <GlobalNavBarAdvanced
            className="global-navbar-advanced"
            style={{
              width: (advancedGlobalData || []).length > 1 ? 642 : 632,
              padding: 5
            }}>
            <GlobalAdvancedFilter
              handleSearch={handleSearch}
              showBooleanInfo={() => {
                setInfoVisible(true);
              }}
            />
          </GlobalNavBarAdvanced>
        </Popover>
        <span
          style={{
            marginLeft: 10,
            marginTop: 10,
            fontSize: 14,
            color: 'white',
            fontWeight: 'bold',
            cursor: 'pointer'
          }}
          onClick={clearFilters}>
          <Icon type="delete" style={{ fontSize: 20 }} className="pointer" />{' '}
          Limpar tudo
        </span>
      </Wrapper>
      <Wrapper>
        <div
          style={{
            color: 'white',
            textAlign: 'center',
            marginTop: 20,
            fontSize: 12,
            width: '100%'
          }}>
          <span style={{ fontWeight: 'bold' }}>Amplitude: </span>
          <RadioGroup
            onChange={(e: any) => setSearchScope(e.target.value)}
            options={scopeOptions}
            value={searchScope}
          />
          <Popover
            style={{ zIndex: 1031 }}
            trigger="click"
            placement="bottom"
            visible={questionVisible}
            content={contentQuestion}
            onVisibleChange={() => {
              setQuestionVisible(!questionVisible);
            }}
            getPopupContainer={(trigger) => trigger}>
            <Icon
              type="question-circle"
              style={{ marginLeft: -10, fontSize: 14, cursor: 'pointer' }}
            />
          </Popover>

          <div className="sep" />
        </div>
      </Wrapper>
    </Container>
  );
};
