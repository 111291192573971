import React, { useState } from 'react';
import { Button } from 'antd';
import { Image } from './Image';
import { Tags } from './Tags';
import { Description } from './Description';
import { Periodic } from '../../interfaces/Periodic';
import { Info } from './Info';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ItemModal } from './ItemModal';
import { ContentTypeTag } from '../../../../../components/DefaultContentListItem/TagsRenderer/ContentTypeTag';
import { getItemType } from '../../helpers/getItemType';
import { ReferenceBtns } from '../../../video/styles';
import { Bibliographic } from './Bibliographic';
import { AddToFolder } from './AddToFolder';
import { http_client } from '../../../../../util/http_client';

const Container = styled.div`
  max-width: 1024px;
  display: flex;
  margin-bottom: 32px;
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const Left = styled.div`
  margin-right: 32px;
`;

const Right = styled.div`
  @media (max-width: 1024px) {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const Infos = styled.div`
  margin-bottom: 16px;
`;

const Title = styled.h2`
  font-size: 16px !important;

  * {
    font-weight: 500 !important;
  }

  @media (max-width: 1024px) {
    font-size: 14px !important;
    text-align: center;
  }
`;

const Highlight = styled.div`
  max-width: 1000px;
  padding-left: 212px;

  em {
    background: rgba(255, 255, 0, 0.3) !important;
  }
`;

const HighlightContainer = styled.div`
  padding: 12px;
  border-left: 3px solid #d7282f;
  margin-bottom: 10px;
  b {
    font-size: 16px;
  }
`;

const HighlightRight = styled.div`
  margin-top: -32px;
  .filter-btn {
    background: none transparent;
    border: 2px solid #003a70;
    color: #003a70;
    min-width: min-content;
    width: auto;
    outline: none;
    border-radius: 12px;
    font-weight: 500;
    padding: 11px 20px;
    margin: 0 10px 0 0;
    height: auto;
    line-height: 1;
    font-size: 14px;
    position: relative;

    &:hover,
    &:focus,
    &:active {
      border: 2px solid #003a70;
      color: #003a70;
    }
  }
  @media (max-width: 1024px) {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const HighlightTitle = styled.h2`
  text-transform: uppercase;
  font-size: 14px !important;
  color: #2f2f2f !important;
  * {
    font-weight: 500 !important;
  }
`;

const ButtonContainer = styled.div`
  padding: 12px;
  padding-left: 0;
  margin-top: 25px;
  margin-bottom: 10px;
`;

const ItemTitle = styled.span`
  display: inline-block;
`;

const ItemTitleMargin = styled.span`
  display: inline-block;
  margin-top: 8px;
`;

export const List = ({ items }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState<any[]>([]);
  const [currentItem, setCurrentItem] = useState<any>({});

  const [showBibliografy, setShowBibliografy] = useState<boolean>(false);
  const [bibliografyItem, setBibliografyItem] = useState<string>('');
  const [showAddToFolder, setShowAddToFolder] = useState<boolean>(false);
  const [folderItem, setFolderItem] = useState<any | unknown>({});

  if (!items) return;

  const link = (item: Periodic, itemId?: string) =>
    `/v2/revista/${String(item.id).split('-').join('/')}${
      itemId ? `/${itemId}?searchpage=1&origin=search` : ''
    }`;

  const toggleModal = (data: any[], item: Partial<Periodic>) => {
    setShowModal(!showModal);
    setModalData(data);
    setCurrentItem(item);
  };

  const getHighlights = (str: string): string[] => {
    const regex = /<mark>(.*?)<\/mark>/g;
    let m: any;
    const occurrences = [];
    while ((m = regex.exec(str)) !== null) {
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }
      if (m && m[1]) {
        occurrences.push(m[1]);
      }
    }
    return occurrences.sort((a, b) => {
      if (a.length > b.length) return -1;
      if (a.length === b.length) return 0;
      return 1;
    });
  };

  const isHighlight = (compare: string, str: string) => {
    const regexStr = `<mark>(${compare}*?)<\/mark>`;
    const regex = new RegExp(regexStr, 'g');
    const result = regex.exec(str);
    return !!result && result[1];
  };

  const getTitleHighlight = (item: any, content: any) => {
    let itemTitle = content.item_title;
    if (item && item.highlight_text_4 && item.highlight_text_4.length) {
      for (const t of item.highlight_text_4) {
        const highlights = getHighlights(t);
        const replaced = [];
        for (const h of highlights) {
          if (replaced.includes(h)) continue;
          if (isHighlight(h, itemTitle)) continue;
          const lowerCase = String(h).toLocaleLowerCase();
          itemTitle = itemTitle.replaceAll(
            new RegExp('\\b' + lowerCase + '\\b', 'g'),
            `<mark>${lowerCase}</mark>`
          );
          replaced.push(lowerCase);
          const firstUppercase =
            lowerCase[0].toUpperCase() + lowerCase.slice(1);
          itemTitle = itemTitle.replaceAll(
            new RegExp('\\b' + firstUppercase + '\\b', 'g'),
            `<mark>${firstUppercase}</mark>`
          );
          replaced.push(firstUppercase);
          const uppercase = lowerCase.toUpperCase();
          itemTitle = itemTitle.replaceAll(
            new RegExp('\\b' + uppercase + '\\b', 'g'),
            `<mark>${uppercase}</mark>`
          );
          replaced.push(uppercase);
        }
      }
    }
    return itemTitle;
  };

  const periodicContents: any[] = [];
  if (items && items.length) {
    for (const item of items) {
      if (item && item.highlight_contents && item.highlight_contents.length) {
        for (const c of item.highlight_contents) {
          const highlight = c.highlight && c.highlight[0] ? c.highlight[0] : '';
          if (!highlight) continue;
          const content = item.contents.find(
            (cp: any) => cp.item_id === c.item_id
          );
          if (!content) {
            continue;
          }
          const contentExists = periodicContents.find(
            (bc: any) => bc.periodic_id === item.id && bc.item_id === c.item_id
          );
          if (contentExists) {
            contentExists.txts.push(highlight);
            continue;
          }

          const titleHighlight = getTitleHighlight(item, content);
          periodicContents.push({
            periodic_id: item.id,
            item_id: c.item_id,
            item_title: titleHighlight || content.item_title,
            item_type: content.item_type || '',
            page: c.page,
            txts: [highlight]
          });
        }
      } else {
        if (item && item.contents) {
          for (const c of item.contents) {
            const titleHighlight = getTitleHighlight(item, c);
            if (titleHighlight.includes('<mark>')) {
              periodicContents.push({
                periodic_id: item.id,
                item_id: c.item_id,
                item_title: titleHighlight,
                item_type: c.item_type || '',
                page: c.page,
                txts: []
              });
            }
          }
        }
      }
    }
  }

  const openBibliografy = (item: any) => {
    setBibliografyItem('');
    const [periodicId, editionId] = String(item.id).split('-');
    const url = `${process.env.REACT_APP_BIBLIOGRAPHIC_REFERENCE}/periodics/${periodicId}/editions/${editionId}/reference`;
    http_client.get<any>(url).then((res) => {
      setBibliografyItem(res.data.citation);
    });
    setShowBibliografy(true);
  };

  const openAddToFolder = (item: any) => {
    setShowAddToFolder(true);
    setFolderItem(item);
  };

  return (
    <>
      <ItemModal
        key={`item-modal`}
        showModal={showModal}
        toggleModal={toggleModal}
        modalData={modalData}
        link={link}
        currentItem={currentItem}
      />
      {items.map((item: Periodic, index: number) => {
        const periodicContent =
          periodicContents && periodicContents.length
            ? periodicContents.filter((b) => b.periodic_id === item.id)
            : [];
        let isTxtsHide = false;
        periodicContent.forEach((b) => {
          if (b.txts && b.txts.length > 3) isTxtsHide = true;
        });
        const subtitle =
          item && item.entity && item.entity.subtitle
            ? item.entity.subtitle
            : '';

        return (
          <div key={index} style={index !== 0 ? { marginTop: '45px' } : {}}>
            <Container>
              <Left>
                <Link to={link(item)} target="_blank">
                  <Image item={item} />
                </Link>
              </Left>
              <Right>
                <Title>
                  <Link
                    to={link(item)}
                    target="_blank"
                    dangerouslySetInnerHTML={{
                      __html: `${
                        item &&
                        item.highlight_text_1 &&
                        item.highlight_text_1[0]
                          ? item.highlight_text_1[0]
                          : item.text_1
                      } ${subtitle ? ` : ${subtitle}` : ''}`
                    }}
                    style={{ fontWeight: 'bold', color: '#000000' }}
                  />
                </Title>
                <Infos>
                  <Info item={item} />
                  <Tags item={item} />
                </Infos>
                <Description item={item} />
                <ReferenceBtns>
                  <Button onClick={() => openAddToFolder(item)} icon="folder">
                    Adicionar à pasta
                  </Button>
                  <Button onClick={() => openBibliografy(item)} icon="edit">
                    Referência bibliográfica
                  </Button>
                </ReferenceBtns>
              </Right>
            </Container>
            <Bibliographic
              options={{
                showBibliografy,
                setShowBibliografy,
                bibliografyItem
              }}
            />
            <AddToFolder
              options={{
                showAddToFolder,
                setShowAddToFolder,
                folderItem
              }}
            />
            {periodicContent && periodicContent.length ? (
              <HighlightRight>
                <Highlight>
                  {periodicContent
                    .filter((_, idx) => idx <= 2)
                    .map((bc, i) => (
                      <HighlightContainer key={`highlight-container-${i}`}>
                        <HighlightTitle>
                          <Link to={link(item, bc.item_id)} target="_blank">
                            {getItemType(bc.item_type || '') ? (
                              <>
                                <ContentTypeTag>
                                  {getItemType(bc.item_type || '')}
                                </ContentTypeTag>
                                <br />
                                <ItemTitleMargin>
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: bc.item_title
                                    }}
                                  />
                                </ItemTitleMargin>
                              </>
                            ) : (
                              <ItemTitle>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: bc.item_title
                                  }}
                                />
                              </ItemTitle>
                            )}
                          </Link>
                        </HighlightTitle>
                        {bc.txts
                          .filter((_, idx) => idx <= 2)
                          .map((t, index) => (
                            <div
                              key={`content-${index}`}
                              className="description highlight_description"
                              dangerouslySetInnerHTML={{
                                __html: `<b>“</b>${t}...<b>”</b>`
                              }}
                            />
                          ))}
                        {bc.txts && bc.txts.length > 3 ? (
                          <div className="description highlight_description">
                            ...
                          </div>
                        ) : (
                          <></>
                        )}
                      </HighlightContainer>
                    ))}
                </Highlight>
              </HighlightRight>
            ) : (
              <></>
            )}
            {(periodicContent &&
              periodicContent.length &&
              periodicContent.length > 3) ||
            isTxtsHide ? (
              <HighlightRight>
                <Highlight>
                  <ButtonContainer>
                    <Button
                      className="filter-btn"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleModal(periodicContent, item);
                      }}>
                      Ver mais
                    </Button>
                  </ButtonContainer>
                </Highlight>
              </HighlightRight>
            ) : (
              <></>
            )}
          </div>
        );
      })}
    </>
  );
};
