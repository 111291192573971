import React from 'react';
import _ from 'lodash';
import { useHistory, Route } from 'react-router';
import { BookContextProvider } from '../pages/livro/BookContextProvider';
import { ReadChapterPage } from '../pages/livro/ReadChapterPage';
import { BookPage } from '../pages/livro/BookPage';

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
export const BookRoutes = (props: any) => {
  const history = useHistory();
  const { REACT_APP_NEW_BOOK_VERSION } = process.env;
  const newBookVersion = String(REACT_APP_NEW_BOOK_VERSION) === 'true';
  return (
    <Route
      path={`/livro/:bookId/:editionId?/:chapterId?`}
      render={(props) => {
        const bookId = _.get(props, 'match.params.bookId');
        const editionId = _.get(props, 'match.params.editionId');
        const chapterId = _.get(props, 'match.params.chapterId');
        const newBookId =
          bookId && !String(bookId).includes('L') ? `L${bookId}` : bookId;

        const newEditionId =
          editionId && !String(editionId).includes('E')
            ? `E${editionId}`
            : editionId;

        const url = `/v2/livro/${newBookId}${
          editionId ? `/${newEditionId}` : ''
        }${chapterId ? `/${chapterId}` : ''}`;
        if (newBookVersion) history.push(url);
        return (
          <BookContextProvider {...props}>
            <>
              {chapterId && <ReadChapterPage />}
              <BookPage {...props} />
            </>
          </BookContextProvider>
        );
      }}
    />
  );
};
