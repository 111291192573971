export enum AreaOfInterest {
  Administrativo = 1,
  Ambiental = 2,
  Constitucional = 3,
  Eleitoral = 4,
  Tributario = 5,
  Urbanistico = 6,
  Civil = 7,
  Criminal = 8,
  Empresarial = 9,
  Processual = 10,
  Trabalhista = 11,
  TemasVariados = 12,
  GestaoPublica = 13,
  Financeiro = 14,
  Economico = 15,
  Penal = 16,
  Internacional = 17,
  Previdenciario = 20,
  DireitoMunicipal = 21
}
