import React, { useContext, useEffect, useState } from 'react';
import { Collapse } from 'antd';
// import { BannerSearch } from './BannerSearch';
import { ContentListBook } from '../contentList/contentListBook';
import { ContentListPeriodic } from '../contentList/contentListPeriodic';
import { ContentListVideo } from '../contentList/contentListVideo';
import { useAuthCtx } from '../../../features/auth_v2/hooks/useAuthCtx';
import { NoAccessToAnyContent } from './NoAccessToAnyContent';
import { CollapseContainer } from './styles';
import { LinkType } from '../_shared/navigation';
import LoadingImg from '../../../assets/images/loading.gif';

const { Panel } = Collapse;

export const Home = () => {
  const { session } = useAuthCtx();
  const [currentActiveKeys, setCurrentActiveKeys] = useState([]);

  const [hasAccessToPeriodics, setHasAccessToPeriodics] = useState(false);
  const [totalPeriodics, setTotalPeriodics] = useState(0);
  const [isInSearchPeriorics, setIsInSearchPeriorics] = useState(false);
  const [loadingPeriorics, setLoadingPeriorics] = useState(false);

  const [hasAccessToBooks, setHasAccessToBooks] = useState(false);
  const [totalBooks, setTotalBooks] = useState(0);
  const [isInSearchBooks, setIsInSearchBooks] = useState(false);
  const [loadingBooks, setLoadingBooks] = useState(false);

  const [hasAccessToVideos, setHasAccessToVideos] = useState(false);
  const [totalVideos, setTotalVideos] = useState(0);
  const [isInSearchVideos, setIsInSearchVideos] = useState(false);
  const [loadingVideos, setLoadingVideos] = useState(false);

  const contractedTags = session.client.contracted_tags || [];

  useEffect(() => {
    isInSearchBooks ||
    loadingBooks ||
    isInSearchPeriorics ||
    loadingPeriorics ||
    isInSearchVideos ||
    loadingVideos
      ? setCurrentActiveKeys([])
      : setCurrentActiveKeys(['1', '2', '3']);
  }, [
    isInSearchBooks,
    loadingBooks,
    isInSearchPeriorics,
    loadingPeriorics,
    isInSearchVideos,
    loadingVideos
  ]);

  useEffect(() => {
    setHasAccessToPeriodics(
      contractedTags.some((result) => result.id.startsWith('periodic'))
    );

    setHasAccessToBooks(
      contractedTags.some((result) => result.id.startsWith('book'))
    );

    setHasAccessToVideos(
      contractedTags.some((result) => result.id.startsWith('video'))
    );
  }, []);

  const hasAccesToSomeContent =
    hasAccessToPeriodics || hasAccessToBooks || hasAccessToVideos;

  const CollapseHeader = ({
    title,
    total,
    searchType,
    isInSearch,
    loading
  }: {
    title: string;
    total: number;
    searchType: LinkType;
    isInSearch: boolean;
    loading: boolean;
  }) => (
    <>
      <div className="title">
        <h2>
          {title}
          {loading ? (
            <span>
              {` | `}
              <img src={LoadingImg} />
            </span>
          ) : (
            <span>{` | ${total} registros ${
              isInSearch ? 'encontrados' : ''
            }`}</span>
          )}
        </h2>
        <span style={{ marginRight: 25, paddingTop: 2, fontSize: 12 }}>
          Visualizar
        </span>
      </div>
    </>
  );

  return (
    <>
      {hasAccesToSomeContent ? <></> : <NoAccessToAnyContent />}
      <CollapseContainer>
        <Collapse
          style={{ marginBottom: '15px' }}
          expandIconPosition="right"
          onChange={(e: any) => {
            setCurrentActiveKeys(e);
          }}
          activeKey={currentActiveKeys}>
          {hasAccessToPeriodics ? (
            <Panel
              header={
                <CollapseHeader
                  title="Revistas"
                  searchType="periodicv2"
                  total={totalPeriodics}
                  isInSearch={isInSearchPeriorics}
                  loading={loadingPeriorics}
                />
              }
              key="1"
              forceRender={true}>
              <ContentListPeriodic
                setTotal={setTotalPeriodics}
                setIsInSearch={setIsInSearchPeriorics}
                setLoading={setLoadingPeriorics}
              />
            </Panel>
          ) : null}
          {hasAccessToBooks ? (
            <Panel
              header={
                <CollapseHeader
                  title="Livros"
                  searchType="book"
                  total={totalBooks}
                  isInSearch={isInSearchBooks}
                  loading={loadingBooks}
                />
              }
              key="2"
              forceRender={true}>
              <ContentListBook
                setTotal={setTotalBooks}
                setIsInSearch={setIsInSearchBooks}
                setLoading={setLoadingBooks}
              />
            </Panel>
          ) : null}
          {hasAccessToVideos ? (
            <Panel
              header={
                <CollapseHeader
                  title="Vídeos"
                  searchType="video"
                  total={totalVideos}
                  isInSearch={isInSearchVideos}
                  loading={loadingVideos}
                />
              }
              key="3"
              forceRender={true}>
              <ContentListVideo
                setTotal={setTotalVideos}
                setIsInSearch={setIsInSearchVideos}
                setLoading={setLoadingVideos}
              />
            </Panel>
          ) : null}
        </Collapse>
      </CollapseContainer>
    </>
  );
};
