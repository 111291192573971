/* eslint-disable indent */
import { Icon, message } from 'antd';
import React, { useEffect } from 'react';
import { ContentType } from '../../../enums/ContentType';
import { useDoRequest } from '../../../Hooks/useDoRequest';
import { Button } from '../../Button';
import { Modal } from '../../Modal';
import { QuoteRequests } from '../../../requests/quote/QuoteRequests';

interface IProps {
  contentId: number | string;
  contentId2?: number | string;
  contentId3?: number | string;
  onCancel: () => any;
  visible: boolean;
  contentType: ContentType;
}

export const QuoteModal = ({
  contentId,
  onCancel,
  visible,
  contentType,
  contentId2,
  contentId3
}: IProps) => {
  let [quote, loading, error, doRequest] = useDoRequest<any>();

  useEffect(() => {
    if (visible) {
      switch (contentType) {
        case ContentType.ARTICLE:
          doRequest(() => {
            return QuoteRequests.getArticleQuote(
              contentId3,
              contentId2,
              contentId
            );
          });
          break;
        case ContentType.PERIODIC:
          doRequest(() => {
            return QuoteRequests.getPeriodicQuote(contentId, contentId2);
          });
          break;
        case ContentType.LEGISLATION:
          doRequest(() => QuoteRequests.getBookQuote(contentId, contentId2));
          break;
        case ContentType.VIDEO:
          doRequest(() => QuoteRequests.getVideoQuote(contentId));
          break;
        case ContentType.BOOK:
          doRequest(() => QuoteRequests.getBookQuote(contentId, contentId2));
          break;
        case ContentType.CHAPTER:
          doRequest(() => {
            return QuoteRequests.getBookChapterQuote(
              contentId3,
              contentId2,
              contentId
            );
          });
          break;
      }
    }
  }, [visible]);

  useEffect(() => {
    if (error) message.error('Erro ao buscar citação.');
  }, [error]);

  return (
    <Modal
      maskClosable={false}
      title="Referência Bibliográfica"
      visible={!!visible}
      onCancel={onCancel}
      footer={[
        <Button type={'primary'} key={'btn-1'} onClick={onCancel}>
          <Icon type="close" />
          Fechar
        </Button>
      ]}>
      {loading ? (
        <Icon
          style={{
            color: 'var(--primary-color)',
            display: 'flex',
            justifyContent: 'center',
            fontSize: '3rem',
            padding: '2rem'
          }}
          type="loading"
        />
      ) : (
        <>
          <div
            style={{ padding: '1rem' }}
            dangerouslySetInnerHTML={{
              __html: quote
            }}
          />

          {contentType === ContentType.ARTICLE ? (
            <p
              style={{
                padding: '0 1rem',
                color: '#00274f',
                fontWeight: 'bold'
              }}>
              * Altere os intervalos "página inicial-página final" para as
              páginas constantes no documento.
            </p>
          ) : null}
        </>
      )}
    </Modal>
  );
};
