import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { AnnotationRoutes } from './routes/AnnotationRoutes';
import { BookRoutes } from './routes/BookRoutes';
import { ClippingRoutes } from './routes/ClippingRoutes';
import { FolderRoutes } from './pages/pastas/FolderRoutes';
import { HomePage } from './pages/home/HomePage';
import { LegislationRoutes } from './routes/LegislationRoutes';
import { MenuContextProvider } from './components/_shell/SideMenu/context/MenuContextProvider';
import { NotFound } from './pages/NotFound';
import {
  PeriodicRoutes,
  PeriodicSpecialPageRoutes
} from './routes/PeriodicRoutes';
import { ProtectedRoute } from './components/ProtectedRoute';
import { PspdfkitLoaderProvider } from './components/_shell/PspdfkitLoaderProvider/PspdfkitLoaderProvider';
import { RedirectToContractManagerPage } from './pages/gestao-usuarios/RedirectToContractManagerPage';
import { SearchContextProvider } from './pages/pesquisa/Context/SearchContextProvider';
import { SearchPage } from './pages/pesquisa/SearchPage';
import { VideoList } from './pages/v2/video/VideoList';
import { VideoView } from './pages/v2/video/VideoView';
import { BookList } from './pages/v2/book/BookList';

import Navbar from './components/_shell/Navbar';
import { NewBookRoutes } from './routes/NewBookRoutes';
import { DesignSystem } from './pages/DesignSystem';
import { Footer } from './components/footer';
import MyJoyride from './util/joyride';
import useHotjar from './Hooks/useHotjar';
import { NewPeriodicRoutes } from './routes/NewPeriodicRoutes';
import { PeriodicList } from './pages/v2/periodic/PeriodicList';
import { CodesList } from './pages/v2/codes/CodesList';
import { InformativesList } from './pages/v2/informatives/InformativesList';
import { ReadInformativeView } from './pages/v2/informatives/ReadInformativeView';

export const Routes = () => {
  const { trackEvent } = useHotjar({ hjid: 1883039, hjsv: 6 });

  const location = useLocation();

  useEffect(() => {
    trackEvent('Navigation', 'Page View', location.pathname);
  }, [location, trackEvent]);

  const {
    REACT_APP_NEW_BOOK_VERSION,
    REACT_APP_NEW_PERIODIC_VERSION,
    REACT_APP_NEW_CODES_VERSION,
    REACT_APP_NEW_INFORMATIVES_VERSION
  } = process.env;
  const newBookVersion = String(REACT_APP_NEW_BOOK_VERSION) === 'true';
  const newPeriodicVersion = String(REACT_APP_NEW_PERIODIC_VERSION) === 'true';
  const newCodesVersion = String(REACT_APP_NEW_CODES_VERSION) === 'true';
  const newInformativesVersion =
    String(REACT_APP_NEW_INFORMATIVES_VERSION) === 'true';
  return (
    <PspdfkitLoaderProvider>
      <MyJoyride />
      <SearchContextProvider>
        <MenuContextProvider>
          <Navbar />
        </MenuContextProvider>
        <Switch>
          <Redirect exact from="/" to={'/pesquisa/todos'} />
          <Redirect exact from="/inicial" to={'/pesquisa/todos'} />
          <Redirect exact from="/pesquisa/videos" to={'/conteudo/videos'} />
          <Redirect exact from="/pesquisa/livros" to={'/conteudo/livros'} />
          {newPeriodicVersion && (
            <Redirect
              exact
              from="/pesquisa/revistas"
              to={'/conteudo/revistas'}
            />
          )}
          {newCodesVersion && (
            <Redirect exact from="/pesquisa/codigos" to={'/conteudo/codigos'} />
          )}
          <Redirect exact from="/video/:id" to={'/conteudo/videos/:id'} />

          <ProtectedRoute path={'/anotacoes'} component={AnnotationRoutes} />
          <ProtectedRoute path={'/pastas'} component={FolderRoutes} />
          <PeriodicRoutes path={'/periodico'} />
          <PeriodicSpecialPageRoutes path={'/:type/periodico'} />
          <BookRoutes path={'/livro'} />
          <NewBookRoutes path={'/v2/livro'} />
          <NewPeriodicRoutes path={'/v2/revista'} />
          <LegislationRoutes path={'/legislacao-comentada'} />
          <ProtectedRoute exact path={`/inicial`} component={HomePage} />

          <ProtectedRoute
            path={'/informativos/:clippingId?/:year?/:month?/:day?'}
            component={ClippingRoutes}
          />
          <ProtectedRoute
            path={'/gestao-usuarios'}
            component={RedirectToContractManagerPage}
          />
          <ProtectedRoute
            exact
            path={`/conteudo/videos`}
            component={VideoList}
          />
          <ProtectedRoute
            exact
            path={'/conteudo/videos/:videoId/:videoTitle?'}
            component={VideoView}
          />
          <ProtectedRoute
            exact
            path={`/conteudo/livros`}
            component={newBookVersion ? BookList : NotFound}
          />
          <ProtectedRoute
            exact
            path={`/conteudo/revistas`}
            component={newPeriodicVersion ? PeriodicList : NotFound}
          />
          <ProtectedRoute
            exact
            path={`/conteudo/informativos`}
            component={newInformativesVersion ? InformativesList : NotFound}
          />
          <ProtectedRoute
            exact
            path={`/v2/informativo/:informativeType/:informativeId`}
            component={newInformativesVersion ? ReadInformativeView : NotFound}
          />
          <ProtectedRoute
            exact
            path={`/conteudo/codigos`}
            component={newCodesVersion ? CodesList : NotFound}
          />
          <NewBookRoutes path={'/v2/livro'} />
          <NewPeriodicRoutes path={'/v2/revista'} />
          <ProtectedRoute
            exact
            path={`/pesquisa/:contentType/:queryString?`}
            component={SearchPage}
          />
          <ProtectedRoute
            exact
            path={`/design-system`}
            component={DesignSystem}
          />

          <Route path={'*'} component={NotFound} />
        </Switch>
        <Footer />
      </SearchContextProvider>
    </PspdfkitLoaderProvider>
  );
};
