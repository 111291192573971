import styled from 'styled-components';
import { Modal } from 'antd';

export const Container = styled.div`
  margin: 0 16px 0 16px;
  min-height: 470px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 16px;

    a {
      span {
        background: none;
        border: 0 none;
        margin-top: 12px;
        font-weight: 500;
        color: #004c92;
      }
    }
  }

  .swiper {
    width: 100%;
    min-height: 400px;
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }

  .swiper-slide {
    width: 240px;
    padding: 18px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .swiper-pagination-progressbar {
    background-color: #a23147; /* Cor de fundo da barra de progresso */
    height: 5px; /* Altura da barra de progresso */
    border-radius: 5px;
  }

  .swiper-pagination-progressbar-fill {
    border: 2px solid #004c92;
    background-color: #004c92 !important;
    border-radius: 0px !important;
  }

  .link-filter {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-filter {
    margin-right: 4px;
  }

  .link:visited {
    text-decoration: none;
    color: #e43339;
  }

  .call-to-action {
    height: 100%;
  }

  .call-to-action-text {
    text-decoration: none;
    color: #e43339;
  }

  .spin {
    margin-top: 176px;
    margin-bottom: 176px;
  }

  .ant-spin-dot-item {
    background-color: #d7282f;
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-color: rgba(255, 255, 255, 0);
    right: 10px;
    padding: 30px;
    color: #d7282f !important;
    fill: #d7282f !important;
    stroke: #d7282f !important;
    transition: all 0.3s ease;
  }

  .swiper-button-disabled {
    color: rgba(255, 255, 255, 0) !important;
    fill: rgba(255, 255, 255, 0) !important;
    stroke: rgba(255, 255, 255, 0) !important;
  }

  .error-message {
    color: red;
  }

  .message-wrapper {
    margin-top: 200px;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .link {
    text-decoration: none;
    display: block;
  }

  .cover {
    width: 160px;
    height: 220px;
    margin-bottom: 16px;
    border-radius: 8px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cover img {
    display: block;
    width: 160px;
    height: 220px;
    object-fit: cover;
    opacity: 1;
  }

  .title {
    font-size: 16px;
  }

  .info-wrapper {
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .info-date {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }

  .info-author {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal {
    width: 800px !important;
    height: 600px !important;
    background-color: blue;
  }

  .ant-modal-content {
    background-color: red;
  }
`;

export const ResultsButton = styled.button`
  font-size: 1rem;
  padding: 8px 16px;
  border-radius: 10px;
  background-color: #e43339;
  color: white;
  border: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  margin-top: 16px;

  &:hover {
    cursor: pointer;
    background-color: #c62d32;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  }
`;

export const ModalStyled = styled(Modal)`
  min-width: 60vw;
  max-width: 1560px;
  min-height: 30vh;
  max-height: 80vh;

  .ant-btn-primary {
    background-color: #003a70;
    border-color: #003a70;
  }
`;

export const ModalInner = styled.div`
  overflow-y: auto;

  img {
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }

  em {
    padding: 0.2em;
    box-shadow: 0 0 3px #737814;
    background: #f8f300;
    font-style: normal;
  }

  h4 {
    margin: 0 0 15px 0;
  }
`;

export const ModalResults = styled.p`
  border-left: 10px solid #ccc;
  padding: 5px 5px 5px 10px;
  margin: 0 12px 0 36px;

  &:nth-child(odd) {
    background: #f7f7f7;
  }

  &:nth-child(even) {
    background: #f2f2f2;
  }

  &:last-child {
    margin: 0 12px 30px 36px;
  }
`;

export const InfoPopover = styled.div`
  position: absolute;
  z-index: 1;
  padding: 8px;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 8px;
`;

export const Title = styled.h2`
  height: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;

  &:hover {
    ${InfoPopover} {
      display: block;
    }
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 4px;

  img {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.formatted {
      white-space: normal;
      text-overflow: initial;
    }
  }

  &:hover {
    ${InfoPopover} {
      display: block;
    }
  }
`;
