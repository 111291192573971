import React from 'react';
import { ContentSearchResult } from '../models/ContentSearchResult';
import { DefaultContentListItem } from './DefaultContentListItem/DefaultContentListItem';
import { RoutesResolver } from '../util/RoutesResolver';

interface IProps {
  result: ContentSearchResult;
}

export const BookListItem = ({ result, ...props }: IProps) => {
  function getCoverImage() {
    if (String(result.img).includes('production-public.s3')) return result.img;
    if (result && result.img && String(result.img).includes('Uploads')) {
      const imgSplited = String(result.img).split('/').pop();
      const imgName = imgSplited && imgSplited[0] ? imgSplited : '';
      return `https://bid1006-production-public.s3.sa-east-1.amazonaws.com/books_legacy/Capa/${imgName}`;
    }
    if (result && result.id && result.editionId) {
      const id = String(result.id).replace('L', '');
      const editionId = String(result.editionId).replace('E', '');
      return `https://bid1006-production-public.s3-sa-east-1.amazonaws.com/books/cover/L${id}/editions/E${editionId}.jpg`;
    }
    return `${result.img}`.match('http')
      ? result.img
      : `https://bid1006-production-public.s3-sa-east-1.amazonaws.com${result.img}`;
  }

  return (
    <DefaultContentListItem
      {...props}
      linkTo={RoutesResolver.getContentRoute(result)}
      result={{
        ...result,
        img: getCoverImage(),
        tags: [{ text: 'Livro', color: 'purple' }]
      }}
    />
  );
};
