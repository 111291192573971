import React, { useContext, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { AxiosResponse } from 'axios';
import { Link } from 'react-router-dom';
import { EffectCoverflow, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSearchFilter } from '../../../pesquisa/hooks/useSearchFilter';
import { Card } from './card';
import { getBooks } from './services/getBooks';
import { Container } from './styles';
import { Occurrences } from './types/occurrences';
import { linkWithGlobalSearch } from '../../_shared/navigation';
import { NavbarContext } from '../../../../components/_shell/NavbarFilters/NavbarContext';
import { List } from '../../book/components/common/List';
import {
  SearchLinkMoreResults,
  SearchResults,
  Separator
} from '../../_shared/styles';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { GlobalFiltersContext } from '../../home/GlobalFiltersContext';
import iconFilter from '../../../../assets/images/filters.svg';

interface IParams {
  setTotal?: (total: number) => any;
  setIsInSearch?: (value: boolean) => any;
  setLoading?: (value: boolean) => any;
}

export const ContentListBook = ({
  setTotal,
  setIsInSearch,
  setLoading
}: IParams) => {
  const [results, setResults] = useState<any>([]);
  const [loading, isLoading] = useState(false);
  const [status, setStatus] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  const { filter } = useSearchFilter();
  const { searchScope } = useContext(NavbarContext);
  const { areaData, authorData, dateData, submit, advancedData } =
    useContext(GlobalFiltersContext);

  useEffect(() => {
    verifyIsInSearch();
  }, []);

  const getBookAuthor = (book: any) => {
    let result = '';

    if ('authors' in book.entity) {
      result = book.entity.authors.map((el: any) => el.title).join(', ');
    }

    if (result.trim()) return result;

    return book.tags
      .filter((el: any) => String(el.id).startsWith('author_'))
      .map((el: any) => el.title.trim())
      .join(', ');
  };

  const mapListBooks = (el) => ({
    id: el.id,
    edition_number: el.edition_number,
    title: el.text_2,
    date: el.publish_date.split('T')[0],
    desc: el.text_1,
    link: `/v2/livro/${el.id.replace('-', '/')}`,
    author: getBookAuthor(el),
    contents: el.contents,
    highlightContents: el.highlight_contents
  });

  const fetchData = async () => {
    const data = {
      page: 1,
      pageSize: 5,
      size: 10,
      filter: filter.words,
      searchScope
    };
    const advanced = advancedData.filter((e) => e.text);
    const dateStart = dateData.filter((e) => e.start);
    const dateEnd = dateData.filter((e) => e.end);
    const area = areaData.filter((e) => e.checked).map((e) => e.key);
    const author = authorData.filter((e) => e.checked).map((e) => e.key);
    const dateStartParam = dateStart[0] ? dateStart[0].value : null;
    const dateEndParam = dateEnd[0] ? dateEnd[0].value : null;
    const filters = {
      advanced,
      area,
      author,
      date: [dateStartParam, dateEndParam]
    };
    const response: AxiosResponse<Occurrences> = await getBooks(data, filters);

    verifyIsInSearch();
    setResults(response.data.hits);
    if (setTotal) setTotal(response.data.total || 0);
    setStatus(response.status);
    isLoading(false);
  };

  const verifyIsInSearch = () => {
    const advanced = advancedData.filter((e) => e.text);
    const dateStart = dateData.filter((e) => e.start);
    const dateEnd = dateData.filter((e) => e.end);
    const area = areaData.filter((e) => e.checked).map((e) => e.key);
    const author = authorData.filter((e) => e.checked).map((e) => e.key);
    const dateStartParam = dateStart[0] ? dateStart[0].value : null;
    const dateEndParam = dateEnd[0] ? dateEnd[0].value : null;
    if (setIsInSearch) {
      if (
        `${filter.words}`.trim() !== '' ||
        area.length ||
        author.length ||
        advanced.length
      ) {
        setIsInSearch(true);
        setIsSearch(true);
      } else if (dateStartParam && dateEndParam) {
        setIsInSearch(true);
        setIsSearch(true);
      } else {
        setIsInSearch(false);
        setIsSearch(false);
      }
    }
  };

  useEffect(() => {
    verifyIsInSearch();
    isLoading(true);
    fetchData();
  }, [searchScope, filter.words, submit]);

  useEffect(() => {
    if (setLoading) setLoading(loading);
  }, [loading]);

  return (
    <Container id="element-7">
      {isSearch ? (
        <>
          <div className="title">
            <h2></h2>
            <Link to={linkWithGlobalSearch('book', filter.words, searchScope)}>
              <span
                className="link link-filter"
                id="element-8"
                style={{ marginTop: 0 }}>
                <img
                  className="icon-filter"
                  width={20}
                  height={20}
                  src={iconFilter}
                  alt={iconFilter}
                />
                Pesquisar somente em livros
              </span>
            </Link>
          </div>
          <Separator>
            <div className="sep">
              <span className="sep-inner" />
            </div>
          </Separator>
        </>
      ) : null}
      {loading && <Spin size="large" style={{ marginTop: '200px' }} />}
      {!loading && results.length === 0 && status === 200 && (
        <div className="message-wrapper">
          <p>Nenhum resultado encontrado para Livros.</p>
        </div>
      )}
      {!loading && status !== 200 && (
        <p className="message-wrapper">Houve um erro ao carregar Livros.</p>
      )}
      {!loading &&
        results.length > 0 &&
        (isSearch ? (
          <div>
            <SearchResults>
              <List items={results} />
            </SearchResults>
            <SearchLinkMoreResults>
              <Link
                className="call-to-list"
                to={linkWithGlobalSearch('book', filter.words, searchScope)}>
                Ver mais resultados de Livros →
              </Link>
            </SearchLinkMoreResults>
            <br />
          </div>
        ) : (
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={30}
            pagination={{
              clickable: true,
              type: 'progressbar'
            }}
            navigation={true}
            modules={[Pagination, Navigation, EffectCoverflow]}
            className="swiper">
            {results.map(mapListBooks).map((el: any, i: number) => (
              <SwiperSlide key={i}>
                <Card
                  id={el.id}
                  edition={el.edition_number}
                  title={el.title}
                  date={el.date}
                  author={el.author}
                  desc={el.desc}
                  link={el.link}
                  contents={el.contents}
                  highlights={el.highlightContents}
                />
              </SwiperSlide>
            ))}
            <SwiperSlide className="call-to-action">
              <Link
                className="call-to-action-text"
                to={linkWithGlobalSearch('book', filter.words, searchScope)}>
                Ver todos os livros
              </Link>
            </SwiperSlide>
          </Swiper>
        ))}
    </Container>
  );
};
