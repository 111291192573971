import { Icon, Badge as badge } from 'antd';
import React, { useContext } from 'react';
import { AdvancedFilter } from './components/filters/AdvancedFilter';
import { CodesNavBarClear } from './styles';
import { CodesFiltersContext } from './CodesFiltersContext';
import { NavbarContext } from '../../../components/_shell/NavbarFilters/NavbarContext';
import { useHistory } from 'react-router';
import styled, { keyframes } from 'styled-components';
import { CodesFilter } from './components/filters/CodesFilter';
import { GlobalFiltersContext } from '../home/GlobalFiltersContext';

const Container = styled.div`
  display: flex;
`;

export const CodesNavBarFilters: React.FC = () => {
  const history = useHistory();

  const { codesData, setCodesData, updateData, setOrder, setPage } =
    useContext(CodesFiltersContext);

  const { advancedData } = useContext(GlobalFiltersContext);

  const { setSearchString } = useContext(NavbarContext);

  const activeFilters = [...(codesData || [])].filter((e) => e.checked).length;

  const advancedFilters = (advancedData || []).filter(
    (e) => e.text.length > 0
  ).length;
  const activeCount = advancedFilters + activeFilters;

  const checkFalse = (e) => ({ ...e, checked: false });
  const clearFilters = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('search');
    history.replace({ search: queryParams.toString() });

    // Global
    setSearchString('');
    // Codes

    setCodesData(codesData.map(checkFalse));
    setOrder('relevance');
    setPage(1);
    updateData();
  };

  return (
    <Container>
      <CodesFilter />
      <CodesNavBarClear>
        <div
          onClick={clearFilters}
          style={{
            cursor: 'pointer'
          }}>
          <Icon
            type="delete"
            style={{ fontSize: 16, marginTop: 8 }}
            className="pointer"
          />{' '}
          <span
            style={{
              fontSize: 12,
              color: '#004c92',
              fontWeight: 'bold',
              cursor: 'pointer'
            }}>
            Limpar filtros à esquerda
          </span>
        </div>
      </CodesNavBarClear>
    </Container>
  );
};
