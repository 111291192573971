import React, { useContext, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { Link } from 'react-router-dom';
import { EffectCoverflow, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSearchFilter } from '../../../pesquisa/hooks/useSearchFilter';
import { Card } from './card';
import { getVideos } from './services/getVideos';
import { Videos } from './types/videos';
import { Video, VideoResponse } from '../../video/interfaces/Video';
import { AxiosResponse } from 'axios';
import { Container } from './styles';
import { linkWithGlobalSearch } from '../../_shared/navigation';
import { NavbarContext } from '../../../../components/_shell/NavbarFilters/NavbarContext';
import { List } from '../../video/components/common/List';
import {
  SearchLinkMoreResults,
  SearchResults,
  Separator
} from '../../_shared/styles';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { GlobalFiltersContext } from '../../home/GlobalFiltersContext';
import iconFilter from '../../../../assets/images/filters.svg';

interface IParams {
  setTotal?: (total: number) => any;
  setIsInSearch?: (value: boolean) => any;
  setLoading?: (value: boolean) => any;
}

export const ContentListVideo = ({
  setTotal,
  setIsInSearch,
  setLoading
}: IParams) => {
  const [results, setResults] = useState<Videos['hits']['hits'] | any>([]);
  const [loading, isLoading] = useState(false);
  const [status, setStatus] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  const { filter } = useSearchFilter();
  const { searchScope } = useContext(NavbarContext);
  const { areaData, authorData, dateData, submit, advancedData } =
    useContext(GlobalFiltersContext);

  useEffect(() => {
    verifyIsInSearch();
  }, []);

  const mapListVideos = (el: Video) => ({
    title: el.title,
    cover: el.image,
    date: el.event_date,
    desc: el.description,
    link: `/conteudo/videos/${el.num_id}`,
    author: el.tags
      .filter((e) => e.prefix === 'author')
      .map((e) => e.title)
      .join(', ')
  });

  useEffect(() => {
    const fetchData = async () => {
      isLoading(true);
      const advanced = advancedData.filter((e) => e.text);
      const dateStart = dateData.filter((e) => e.start);
      const dateEnd = dateData.filter((e) => e.end);
      const filters = {
        advanced,
        area: areaData.filter((e) => e.checked).map((e) => e.key),
        author: authorData.filter((e) => e.checked).map((e) => e.key),
        date: [
          dateStart[0] ? dateStart[0].value : null,
          dateEnd[0] ? dateEnd[0].value : null
        ]
      };
      const data = {
        query: 'processo',
        page: 1,
        pageSize: 5,
        filter: filter.words,
        searchScope
      };

      const response: AxiosResponse<VideoResponse> = await getVideos(
        data,
        filters
      );

      verifyIsInSearch();
      setResults(response.data.hits);
      if (setTotal) setTotal(response.data.total || 0);
      setStatus(response.status);
      isLoading(false);
    };

    fetchData();
  }, [searchScope, filter.words, submit]);

  const verifyIsInSearch = () => {
    const advanced = advancedData.filter((e) => e.text);
    const dateStart = dateData.filter((e) => e.start);
    const dateEnd = dateData.filter((e) => e.end);
    const area = areaData.filter((e) => e.checked).map((e) => e.key);
    const author = authorData.filter((e) => e.checked).map((e) => e.key);
    const dateStartParam = dateStart[0] ? dateStart[0].value : null;
    const dateEndParam = dateEnd[0] ? dateEnd[0].value : null;
    if (setIsInSearch) {
      if (
        `${filter.words}`.trim() !== '' ||
        area.length ||
        author.length ||
        advanced.length
      ) {
        setIsInSearch(true);
        setIsSearch(true);
      } else if (dateStartParam && dateEndParam) {
        setIsInSearch(true);
        setIsSearch(true);
      } else {
        setIsInSearch(false);
        setIsSearch(false);
      }
    }
  };

  useEffect(() => {
    if (setLoading) setLoading(loading);
  }, [loading]);

  return (
    <Container>
      {isSearch ? (
        <>
          <div className="title">
            <h2></h2>
            <Link to={linkWithGlobalSearch('video', filter.words, searchScope)}>
              <span
                className="link link-filter"
                id="element-8"
                style={{ marginTop: 0 }}>
                <img
                  className="icon-filter"
                  width={20}
                  height={20}
                  src={iconFilter}
                  alt={iconFilter}
                />
                Pesquisar somente em vídeos
              </span>
            </Link>
          </div>
          <Separator>
            <div className="sep">
              <span className="sep-inner" />
            </div>
          </Separator>
        </>
      ) : null}
      {loading && <Spin size="large" style={{ marginTop: '200px' }} />}
      {!loading && results.length === 0 && status === 200 && (
        <div className="message-wrapper">
          <p>Nenhum resultado encontrado para Vídeos.</p>
        </div>
      )}
      {!loading && status !== 200 && (
        <p className="message-wrapper">Houve um erro ao carregar Vídeos.</p>
      )}
      {!loading &&
        results.length > 0 &&
        (isSearch ? (
          <div>
            <SearchResults>
              <List items={results} />
            </SearchResults>
            <SearchLinkMoreResults>
              <Link
                className="call-to-list"
                to={linkWithGlobalSearch('video', filter.words, searchScope)}>
                Ver mais resultados de Vídeos →
              </Link>
            </SearchLinkMoreResults>
            <br />
          </div>
        ) : (
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={30}
            pagination={{
              clickable: true,
              type: 'progressbar'
            }}
            navigation={true}
            modules={[Pagination, Navigation, EffectCoverflow]}
            className="swiper">
            {results.map(mapListVideos).map((result: any, i: number) => (
              <SwiperSlide key={i}>
                <Card
                  key={result.id}
                  id={result.id}
                  title={result.title}
                  cover={result.cover}
                  date={result.date}
                  author={result.author}
                  desc={result.desc}
                  link={result.link}
                />
              </SwiperSlide>
            ))}
            <SwiperSlide className="call-to-action">
              <Link
                className="call-to-action-text"
                to={linkWithGlobalSearch('video', filter.words, searchScope)}>
                Ver todos os Vídeos →
              </Link>
            </SwiperSlide>
          </Swiper>
        ))}
    </Container>
  );
};
