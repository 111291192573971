import React, { useState } from 'react';
import { Button } from 'antd';
import { Image } from './Image';
import { Description } from './Description';
import { Codes } from '../../interfaces/Codes';
import styled from 'styled-components';
import { ItemModal } from './ItemModal';
import { ReferenceBtns } from '../../../video/styles';
import { AddToFolder } from './AddToFolder';

const Container = styled.div`
  max-width: 1024px;
  display: flex;
  margin-bottom: 32px;
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const Left = styled.div`
  margin-right: 32px;
`;

const Right = styled.div`
  @media (max-width: 1024px) {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const Infos = styled.div`
  margin-bottom: 16px;
`;

const Title = styled.h2`
  font-size: 16px !important;

  * {
    font-weight: 500 !important;
  }

  @media (max-width: 1024px) {
    font-size: 14px !important;
    text-align: center;
  }
`;

const Highlight = styled.div`
  max-width: 1000px;
  padding-left: 212px;

  em {
    background: rgba(255, 255, 0, 0.3) !important;
  }
`;

const HighlightContainer = styled.div`
  padding: 12px;
  border-left: 3px solid #d7282f;
  margin-bottom: 10px;
  b {
    font-size: 16px;
  }
`;

const HighlightRight = styled.div`
  margin-top: -32px;
  .filter-btn {
    background: none transparent;
    border: 2px solid #003a70;
    color: #003a70;
    min-width: min-content;
    width: auto;
    outline: none;
    border-radius: 12px;
    font-weight: 500;
    padding: 11px 20px;
    margin: 0 10px 0 0;
    height: auto;
    line-height: 1;
    font-size: 14px;
    position: relative;

    &:hover,
    &:focus,
    &:active {
      border: 2px solid #003a70;
      color: #003a70;
    }
  }
  @media (max-width: 1024px) {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const HighlightDiv = styled.div`
  margin-top: -200px;
`;

const ButtonContainer = styled.div`
  padding: 12px;
  padding-left: 0;
  margin-top: 25px;
  margin-bottom: 10px;
`;

export const List = ({ items }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState<any[]>([]);
  const [currentItem, setCurrentItem] = useState<any>({});

  const [showBibliografy, setShowBibliografy] = useState<boolean>(false);
  const [bibliografyItem, setBibliografyItem] = useState<string>('');
  const [showAddToFolder, setShowAddToFolder] = useState<boolean>(false);
  const [folderItem, setFolderItem] = useState<any | unknown>({});

  if (!items) return;

  const link = (item: Codes) => item.url || '';

  const toggleModal = (data: any[], item: Partial<Codes>) => {
    setShowModal(!showModal);
    setModalData(data);
    setCurrentItem(item);
  };

  const codesContents: any[] = [];
  if (items && items.length) {
    for (const item of items) {
      if (item && item.highlight_contents && item.highlight_contents.length) {
        for (const c of item.highlight_contents) {
          const highlights = c.highlight || [];
          if (!highlights.length) continue;
          for (const highlight of highlights) {
            const contentExists = codesContents.find(
              (bc: any) => bc.codes_id === item.id
            );
            if (contentExists) {
              contentExists.txts.push(highlight);
              continue;
            }
            codesContents.push({
              codes_id: item.id,
              page: c.page,
              txts: [highlight]
            });
          }
        }
      }
    }
  }

  const openAddToFolder = (item: any) => {
    setShowAddToFolder(true);
    setFolderItem(item);
  };

  const contentTxtsRelation = [];
  return (
    <>
      <ItemModal
        key={`item-modal`}
        showModal={showModal}
        toggleModal={toggleModal}
        modalData={modalData}
        link={link}
        currentItem={currentItem}
      />
      <AddToFolder
        options={{
          showAddToFolder,
          setShowAddToFolder,
          folderItem
        }}
      />
      {items.map((item: Codes, index: number) => {
        const codesContent =
          codesContents && codesContents.length
            ? codesContents.filter((b) => b.codes_id === item.id)
            : [];
        let isTxtsHide = false;
        let txts = [];
        codesContent.forEach((b) => {
          if (b.txts && b.txts.length) txts = [...txts, ...b.txts];
          if (b.txts && b.txts.length > 3) isTxtsHide = true;
        });
        contentTxtsRelation[index] = txts;
        let styles: any = {};
        if (index > 0) {
          const lastTxts = contentTxtsRelation[index - 1];
          if (lastTxts.length && lastTxts.length == 1) {
            styles = { ...styles, marginTop: '150px' };
          }
          if (lastTxts.length && lastTxts.length == 2) {
            styles = { ...styles, marginTop: '100px' };
          }
          if (lastTxts.length && lastTxts.length == 3) {
            styles = { ...styles, marginTop: '60px' };
          }
          if (lastTxts.length > 3) {
            styles = { ...styles, marginTop: '45px' };
          }
          if (index === 19) {
            styles = { ...styles, marginBottom: '50px' };
          }
        }

        return (
          <div key={index} style={styles} className="code-list">
            <Container>
              <Left>
                <a href={item.url} target="_blank">
                  <Image item={item} />
                </a>
              </Left>
              <Right>
                <Title>
                  <a
                    href={item.url}
                    target="_blank"
                    dangerouslySetInnerHTML={{
                      __html: `${
                        item &&
                        item.highlight_text_1 &&
                        item.highlight_text_1[0]
                          ? item.highlight_text_1[0]
                          : item.text_1
                      }`
                    }}
                    style={{ fontWeight: 'bold', color: '#000000' }}
                  />
                </Title>
                <Description item={item} />
                <ReferenceBtns>
                  <Button onClick={() => openAddToFolder(item)} icon="folder">
                    Adicionar à pasta
                  </Button>
                </ReferenceBtns>
              </Right>
            </Container>
            {codesContent && codesContent.length ? (
              <HighlightRight>
                <HighlightDiv>
                  <Highlight>
                    {codesContent
                      .filter((_, idx) => idx <= 2)
                      .map((bc, i) => (
                        <HighlightContainer key={`highlight-container-${i}`}>
                          {bc.txts
                            .filter((_, idx) => idx <= 2)
                            .map((t, index) => (
                              <div
                                key={`content-${index}`}
                                className="description highlight_description"
                                dangerouslySetInnerHTML={{
                                  __html: `<b>“</b>${t}...<b>”</b>`
                                }}
                              />
                            ))}
                          {bc.txts && bc.txts.length > 3 ? (
                            <div className="description highlight_description">
                              ...
                            </div>
                          ) : (
                            <></>
                          )}
                        </HighlightContainer>
                      ))}
                  </Highlight>
                </HighlightDiv>
              </HighlightRight>
            ) : (
              <></>
            )}
            {(codesContent && codesContent.length && codesContent.length > 3) ||
            isTxtsHide ? (
              <HighlightRight>
                <Highlight>
                  <ButtonContainer>
                    <Button
                      className="filter-btn"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleModal(codesContent, item);
                      }}>
                      Ver mais
                    </Button>
                  </ButtonContainer>
                </Highlight>
              </HighlightRight>
            ) : (
              <></>
            )}
          </div>
        );
      })}
    </>
  );
};
