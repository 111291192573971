import { Icon, Badge as badge } from 'antd';
import React, { useContext } from 'react';
import { AdvancedFilter } from './components/filters/AdvancedFilter';
import { AreaFilter } from './components/filters/AreaFilter';
import { AuthorFilter } from './components/filters/AuthorFilter';
import { DateFilter } from './components/filters/DateFilter';
import { PeriodicNavBarClear } from './styles';
import { PeriodicFiltersContext } from './PeriodicFiltersContext';
import { NavbarContext } from '../../../components/_shell/NavbarFilters/NavbarContext';
import { useHistory } from 'react-router';
import styled, { keyframes } from 'styled-components';
import { PeriodicFilter } from './components/filters/PeriodicFilter';
import { AplicationTypeFilter } from './components/filters/AplicationTypeFilter';
import { GlobalFiltersContext } from '../home/GlobalFiltersContext';

const Container = styled.div`
  display: flex;
`;

const clickAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
`;

export const PeriodicNavBarFilters: React.FC = () => {
  const history = useHistory();

  const {
    areaData,
    authorData,
    periodicData,
    appTypeData,
    dateData,
    setAreaData,
    setAuthorData,
    setPeriodicData,
    setAppTypeData,
    updateData,
    setAreaSearchValue,
    setAuthorSearchValue,
    setDateData,
    setOrder,
    setPage
  } = useContext(PeriodicFiltersContext);

  const { setSearchString } = useContext(NavbarContext);

  const { advancedData, setAdvancedData } = useContext(GlobalFiltersContext);

  const activeFilters = [
    ...(areaData || []),
    ...(authorData || []),
    ...(periodicData || [])
  ].filter((e) => e.checked).length;
  const dateFilter = (dateData || []).filter((e) => e.start || e.end).length
    ? 1
    : 0;
  const advancedFilters = (advancedData || []).filter(
    (e) => e.text.length > 0
  ).length;
  const activeCount = advancedFilters + activeFilters + dateFilter;

  const checkFalse = (e) => ({ ...e, checked: false });
  const checkRangeFalse = (e) => ({ ...e, start: false, end: false });
  const clearFilters = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('search');
    history.replace({ search: queryParams.toString() });

    // Global
    setSearchString('');
    // Periodic
    setAreaSearchValue('');
    setAuthorSearchValue('');
    setAreaData(areaData.map(checkFalse));
    setAuthorData(authorData.map(checkFalse));
    setPeriodicData(periodicData.map(checkFalse));
    setAppTypeData(appTypeData.map(checkFalse));
    setDateData(dateData.map(checkRangeFalse));
    setPage(1);
    updateData();
  };

  return (
    <Container>
      <AreaFilter />
      <AuthorFilter />
      <DateFilter />
      <AplicationTypeFilter />
      <PeriodicFilter />
      <PeriodicNavBarClear>
        <div
          onClick={clearFilters}
          style={{
            cursor: 'pointer'
          }}>
          <Icon
            type="delete"
            style={{ fontSize: 16, marginTop: 8 }}
            className="pointer"
          />{' '}
          <span
            style={{
              fontSize: 12,
              color: '#004c92',
              fontWeight: 'bold',
              cursor: 'pointer'
            }}>
            Limpar filtros à esquerda
          </span>
        </div>
      </PeriodicNavBarClear>
    </Container>
  );
};
