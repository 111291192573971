import { useState, useRef } from 'react';
import { http_client } from '../../../../../util/http_client';
import { Video, VideoIdParam } from '../../interfaces/Video';

export const useGetByIdRequest = () => {
  const areasLoaded = useRef(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState<Partial<Video>>({});

  const request = async (id: VideoIdParam) => {
    try {
      const url = `${process.env.REACT_APP_ENTITY_SEARCH}/video/entity/${id}`;
      const res = await http_client.get<Video>(url);

      setData(res.data);
    } catch {
      setError(true);
    }

    setLoading(false);
    areasLoaded.current = true;

    return data;
  };

  const getVideo = async (id: VideoIdParam) => {
    setError(false);
    setLoading(true);
    setData({});

    return request(id);
  };

  return { loading, error, data, getVideo };
};

export default useGetByIdRequest;
