export const getChapterType = (title: string) => {
  const preTextual = [
    'Folha de Rosto',
    'Expediente',
    'Dedicatória',
    'Agradecimento',
    'Epígrafe',
    'Lista de Ilustrações',
    'Lista de Tabelas',
    'Lista de Abreviaturas e Siglas',
    'Lista de Símbolos',
    'Sumário',
    'Prefácio',
    'Apresentação'
  ];

  const posTextual = [
    'Pósfacio',
    'Referências',
    'Glossário',
    'Apêndice',
    'Anexo',
    'Índices',
    'Sobre os Autores'
  ];

  if (preTextual.includes(title)) return 'Pré textual';
  if (posTextual.includes(title)) return 'Pós textual';

  return 'Capítulo';
};
