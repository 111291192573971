import React from 'react';
import { Image as SCImage } from '../../styles';
import moment from 'moment';

type IImgError = React.SyntheticEvent<HTMLImageElement, Event>;

export const Image = ({ item }) => {
  function getCoverImage() {
    const canvas = document.createElement('canvas');
    canvas.width = 180 * 4;
    canvas.height = 260 * 4;
    const context = canvas.getContext('2d')!;
    context.rect(0, 0, 180 * 4, 260 * 4);
    context.fillStyle = '#024D92';
    context.fill();
    context.font = 'bold 73px "Segoe UI", sans-serif';
    context.fillStyle = 'white';
    const type =
      String(item.type).charAt(0).toUpperCase() + String(item.type).slice(1);
    context.fillText(`Informativo ${type}`, 6 * 4, 110 * 4);
    const date =
      item && item.publish_date
        ? moment(item.publish_date).format('DD/MM/YYYY')
        : '-';
    context.fillText(date, 45 * 4, 140 * 4);
    return canvas.toDataURL('image/png');
  }
  const base = `https://${process.env.REACT_APP_PUBLIC_BUCKET}.s3-sa-east-1.amazonaws.com`;
  const placeholder = `${base}/periodics/cover/no-cover.jpg`;
  const onImgError = (e: IImgError) => (e.currentTarget.src = placeholder);
  return (
    <SCImage src={`${getCoverImage()}`} alt={item.title} onError={onImgError} />
  );
};
