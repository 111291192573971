import React from 'react';
import { Button, Checkbox, Icon, Input, Switch, Select as select } from 'antd';
import { AdvancedOptionsLabel, options } from './GlobalAdvancedFilterTypes';
import { Select, ButtonOk } from './styles';
const { Option } = select;

interface IProps {
  changeTypeFrom: (e: any, i: number) => void;
  changeTextFrom: (e: any, i: number) => void;
  changeCheckboxFrom: (e: any, i: number) => void;
  del: (i: number) => void;
  add: () => void;
  update: () => void;
  i: number;
  d: any;
  showArrow?: boolean;
}

export const GlobalAdvancedFilterForm = ({
  changeTypeFrom,
  changeTextFrom,
  changeCheckboxFrom,
  del,
  add,
  update,
  i,
  d,
  showArrow
}: IProps) => {
  return (
    <div className={`${i > 0 ? 'mt-3' : ''}`}>
      <div className="justify-center" style={i > 1 ? { marginTop: 5 } : {}}>
        <Select
          id={i === 0 ? 'select-type-filter' : ''}
          onSelect={(e) => changeTypeFrom(e, i)}
          filterOption={false}
          defaultValue={d.type}
          style={{
            width: 138,
            top: 1,
            fontSize: 10,
            fontWeight: 'bold'
          }}>
          {options.map((o) => (
            <Option key={i} value={o} title={o} style={{ fontSize: 12 }}>
              {AdvancedOptionsLabel[o]}
            </Option>
          ))}
        </Select>
        <Icon
          type="right"
          style={{
            marginRight: 10,
            marginTop: 5,
            paddingTop: 4
          }}
        />
        <Input
          value={d.text}
          placeholder="Digite a pesquisa desejada + ENTER"
          onChange={(e) => changeTextFrom(e, i)}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              update();
            }
          }}
          style={{
            width: 258,
            border: 0,
            borderBottom: '1px solid #d9d9d9',
            borderRadius: 0
          }}
        />
        <ButtonOk onClick={update}>OK</ButtonOk>

        <span
          id={i === 0 ? 'exact-filter' : ''}
          style={{
            fontSize: 13,
            fontWeight: 'bold'
          }}>
          <Switch
            size="small"
            checked={d.exact}
            onChange={(e) => changeCheckboxFrom(!!e, i)}
            style={{
              marginLeft: 10,
              marginRight: 10
            }}
          />
          Termo exato
        </span>
        {i > 0 && (
          <Icon
            type="delete"
            style={{ marginLeft: 10, color: 'red' }}
            onClick={() => del(i)}
            className="pointer"
          />
        )}
        <span id={i === 0 ? 'icon-plus-filter' : ''} style={{ marginLeft: 10 }}>
          <Icon type="plus" onClick={add} className="pointer" />
        </span>

        {showArrow ? (
          <Icon type="down" style={{ marginLeft: 10 }} className="pointer" />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
