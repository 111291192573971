import React, { useState } from 'react';
import { Periodic } from '../../interfaces/Periodic';

import TagIcon from '../../../../../assets/images/icon-tag.svg';
import {
  PeriodicInfo,
  PeriodicInfoPopover,
  PeriodicInnerFields,
  PeriodicPopoverContent
} from '../../styles';
import { Button, Icon, Popover } from 'antd';

export const Tags = ({ item }) => {
  const tags = (el: Periodic) =>
    el.tags
      .filter((e) => e.id.includes('kwrd'))
      .map((e) => e.title)
      .join(', ');
  const keywords = tags(item);

  const contentPopover = (
    <div>
      <PeriodicPopoverContent>{keywords}</PeriodicPopoverContent>
    </div>
  );

  return keywords ? (
    <Popover
      trigger="click"
      placement="bottom"
      content={contentPopover}
      getPopupContainer={(triggerNode) => triggerNode}>
      <PeriodicInnerFields>
        <PeriodicInfo className="info-keywords">
          <img src={TagIcon} />
          <span>
            {keywords.length > 150 ? `${keywords.slice(0, 150)}...` : keywords}{' '}
          </span>

          <div>
            <Button size="small" color="primary" style={{ marginLeft: 5 }}>
              <Icon type={'eye'} style={{ fontSize: 14, cursor: 'pointer' }} />
            </Button>
          </div>
        </PeriodicInfo>
      </PeriodicInnerFields>
    </Popover>
  ) : (
    <></>
  );
};
