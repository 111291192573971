import React, { useEffect, useReducer } from 'react';
import _ from 'lodash';
import { PeriodicContextModel } from '../../../models/periodic/PeriodicContextModel';
import { PeriodicRequests } from './hooks/PeriodicRequests';

// @ts-ignore
export const PeriodicContext = React.createContext();

interface IProps {
  match: any;
  children: any;
}

export const PeriodicContextProvider = ({ match, children }: IProps) => {
  const periodicId = _.get(match, 'params.periodicId');
  const editionId = _.get(match, 'params.editionId');
  const itemId = _.get(match, 'params.itemId');

  const [state, dispatch]: [PeriodicContextModel, (action: any) => any] =
    useReducer(
      (state, action) => ({ ...state, ...action }),
      new PeriodicContextModel()
    );

  useEffect(() => {
    if (!periodicId) return;
    (async () => {
      try {
        dispatch({
          editionsRequest: state.editionsRequest.setLoading()
        });
        const editions = await PeriodicRequests.getPeriodicEditions(periodicId);
        dispatch({
          editionsRequest: state.editionsRequest.setResult(editions)
        });
      } catch (error) {
        dispatch({
          editionsRequest: state.editionsRequest.setError(error)
        });
      }
    })();
  }, [periodicId]);

  useEffect(() => {
    if (editionId && periodicId) {
      (async () => {
        try {
          dispatch({
            articlesRequest: state.articlesRequest.setLoading()
          });
          const items = await PeriodicRequests.getEditionItems(
            periodicId,
            editionId
          );

          dispatch({
            articlesRequest: state.articlesRequest.setResult(items)
          });
        } catch (error) {
          dispatch({
            articlesRequest: state.articlesRequest.setError(error)
          });
        }
      })();
    }
  }, [editionId, periodicId]);

  useEffect(() => {
    const isValidItemId = Number(itemId);
    if (editionId && periodicId && itemId && Number.isInteger(isValidItemId)) {
      (async () => {
        try {
          dispatch({
            editionItemSignedUrlRequest:
              state.editionItemSignedUrlRequest.setLoading()
          });
          const signedUrl = await PeriodicRequests.getItemSignedUrl(
            periodicId,
            editionId,
            itemId
          );

          dispatch({
            editionItemSignedUrlRequest:
              state.editionItemSignedUrlRequest.setResult(signedUrl)
          });
        } catch (error) {
          dispatch({
            editionItemSignedUrlRequest:
              state.editionItemSignedUrlRequest.setError(error)
          });
        }
      })();
    }
  }, [editionId, periodicId, itemId]);

  return (
    <PeriodicContext.Provider value={{ state, dispatch }}>
      {children}
    </PeriodicContext.Provider>
  );
};
