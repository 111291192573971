import React, { useContext } from 'react';
import { PeriodicFiltersContext } from '../../PeriodicFiltersContext';
import { TimedFetch } from './use-cases/TimedFetch';

export const PeriodicFilter = ({}) => {
  const {
    periodicVisible,
    periodicSearchValue,
    periodicLoading,
    periodicLoaded,
    periodicError,
    periodicData,
    periodicRI,
    setPeriodicVisible,
    setPeriodicSearchValue,
    setPeriodicLoading,
    setPeriodicLoaded,
    setPeriodicError,
    setPeriodicData,
    setPeriodicRI,
    updateData,
    setPage
  } = useContext(PeriodicFiltersContext);

  return (
    <TimedFetch
      segment="periodic"
      placeholder="Revistas"
      visible={periodicVisible}
      searchValue={periodicSearchValue}
      loading={periodicLoading}
      loaded={periodicLoaded}
      error={periodicError}
      data={periodicData}
      ri={periodicRI}
      setVisible={setPeriodicVisible}
      setSearchValue={setPeriodicSearchValue}
      setLoading={setPeriodicLoading}
      setLoaded={setPeriodicLoaded}
      setError={setPeriodicError}
      setData={setPeriodicData}
      setRI={setPeriodicRI}
      updateData={updateData}
      setPage={setPage}
      fetchOnMount={true}
    />
  );
};
