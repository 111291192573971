import styled from 'styled-components';

export const Separator = styled.div`
  width: 100%;
  display: block;
  position: relative;

  .sep {
    width: 100%;
    left: 0;
    top: 0;
    background-color: #a23147;
    height: 5px;
    border-radius: 5px;
    position: relative;

    .sep-inner {
      background-color: #004c92 !important;
      border-radius: 0px !important;
      border: 2px solid #004c92;
      height: 5px;
      left: 0;
      position: absolute;
      text-align: center;
      top: 0;
      transform-origin: left top;
      transform: scale(0);
      transform: translate3d(0, 0, 0);
      transform: translate3d(0px, 0px, 0px) scaleX(0.333333) scaleY(1);
      transition-duration: 300ms;
      transition: 0.3s opacity;
      width: 100%;
      z-index: 5;
    }
  }
`;

export const SearchResults = styled.div`
  margin: 2em 0;
`;

export const SearchLinkMoreResults = styled.div`
  display: flex;

  .call-to-list {
    padding: 8px 16px;
    border-radius: 10px;
    background-color: #e43339;
    color: white;
    border: none;
    transition: all 0.2s ease-in-out;
    margin: 0 auto;
  }
`;
