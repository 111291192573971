/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable indent */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-throw-literal */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */

import _get from 'lodash/get';
import qs from 'query-string';
import React, { useContext, useEffect } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { ErrorMessage } from '../../../components/ErrorMessage';
import { FullscreenOverlay } from '../../../components/_shell/FullscreenOverlay';
import { Loading } from '../../../components/_shell/Loading';
import { ContentType } from '../../../enums/ContentType';
import { PeriodicContextModel } from '../../../models/periodic/PeriodicContextModel';
import { PeriodicEditionItemModel } from '../../../models/periodic/PeriodicEditionItemModel';
import { ContentSearchResult } from '../../../models/ContentSearchResult';
import ReadPage from '../../leitura/ReadPage';
import { PeriodicContext } from './PeriodicContextProvider';
import { PeriodicEditionItemList } from './PeriodicEditionItemList';
import slug from '../_shared/slug';

export const ReadItemView = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const periodicId = _get(match, 'params.periodicId');
  const editionId = _get(match, 'params.editionId');
  const itemId = _get(match, 'params.itemId');
  const queryparams = qs.parse(location.search);

  const ctx = useContext<{ state: PeriodicContextModel }>(
    PeriodicContext as any
  );

  const { articlesRequest, editionsRequest, editionItemSignedUrlRequest } =
    ctx.state;

  useEffect(() => {
    const itemsCurrent = (articlesRequest.result ||
      []) as PeriodicEditionItemModel[];
    if (editionId && itemsCurrent && itemId) {
      const itemCurrent = itemsCurrent.find(
        (e) => String(e.id) === String(itemId)
      );
      if (!itemCurrent) return;
      const queryParams = new URLSearchParams(location.search);
      let queryParamsString = '';
      if (queryParams.get('origin')) {
        queryParamsString = `?origin=${queryParams.get('origin')}`;
      }
      history.replace(
        `/v2/revista/${periodicId}/${editionId}/${itemCurrent.id}${
          itemCurrent.title
            ? `/${slug(itemCurrent.title)}${queryParamsString}`
            : ''
        }`
      );
      return;
    }
  }, [articlesRequest.result]);

  if (articlesRequest.error)
    return <ErrorMessage error={articlesRequest.error} />;

  if (
    articlesRequest.loading ||
    editionsRequest.loading ||
    editionItemSignedUrlRequest.loading
  ) {
    return (
      <FullscreenOverlay style={{ background: '#cecece' }}>
        <Loading />
      </FullscreenOverlay>
    );
  }

  const items = articlesRequest.result as PeriodicEditionItemModel[];
  if (!items) return <ErrorMessage />;

  const result: any =
    editionsRequest && editionsRequest.result ? editionsRequest.result : [];
  const currentEdition = result.find(
    (e) => String(e.editionNumId) === String(editionId || '')
  );

  if (!currentEdition) return <ErrorMessage />;

  const currentIndex = items.findIndex((e) => String(e.id) === String(itemId));

  if (currentIndex < 0) return <ErrorMessage />;

  const currentItem = items[currentIndex];
  const next = items[currentIndex + 1];
  const prev = items[currentIndex - 1];

  const keywords = _get(queryparams, 'keywords', '')
    //@ts-ignore
    .split(' ')
    .filter((k) => !!k);

  const pdfUrl =
    editionItemSignedUrlRequest.result && editionItemSignedUrlRequest.result.url
      ? editionItemSignedUrlRequest.result.url
      : '';

  function getEditionInfo(obj: any) {
    let editionInfo = '';
    if (obj) {
      const startMonth = new Date(obj.editionStartMonth);
      let monthStart = startMonth.toLocaleString('pt-BR', {
        month: 'short',
        timeZone: 'UTC'
      });
      monthStart = monthStart[0].toUpperCase() + monthStart.slice(1);
      const endMonth = new Date(obj.editionEndMonth);
      let monthEnd = endMonth.toLocaleString('pt-BR', {
        month: 'short',
        timeZone: 'UTC'
      });
      monthEnd = monthEnd[0].toUpperCase() + monthEnd.slice(1);
      const year = startMonth.getFullYear();
      const isMontly = obj.editionFrequency === 'Mensal';
      const editionDate =
        monthStart === monthEnd || isMontly
          ? `${monthStart} - ${year}`
          : `${monthStart}/${monthEnd} - ${year}`;

      // TODO remover mock
      editionInfo = ['E52373', 'E52282'].includes(obj.editionNumId)
        ? `Vol. ${String(obj.editionNumber)}, No. ${String(
            obj.editionNumber
          )}, ${
            String(obj.editionNumber) === '1'
              ? 'Janeiro/Junho 2023'
              : 'Julho/Dezembro 2023'
          }`
        : `Número ${`${String(obj.editionNumber)
            .replaceAll('Edição ', '')
            .replaceAll('Número ', '')}`}, Ano ${year}, ${editionDate}`;
    }
    return editionInfo;
  }

  function getContentSearchResult(
    item: PeriodicEditionItemModel,
    periodic: any
  ) {
    const stage = process.env.REACT_APP_STAGE || 'staging';
    const periodicImageSrc = `https://bid1006-${stage}-public.s3.sa-east-1.amazonaws.com/periodics/cover/${periodicId}/${periodicId}.jpg`;
    const editionInfo = getEditionInfo(periodic);

    return new ContentSearchResult(
      ContentType.ARTICLE,
      item.id,
      item.title,
      periodic.periodicTitle,
      periodic.periodicDescription,
      null,
      editionId,
      periodic.periodicNumId,
      periodicImageSrc,
      item.author,
      null,
      editionInfo,
      null,
      item.articleType,
      null,
      `/v2/revista/${periodic.id}/${editionId}/${item.id}`,
      String(item.id)
    );
  }

  return (
    <ReadPage
      //@ts-ignore
      summaryTitle={
        <SummaryTitleContainer>
          <h2>
            <NavLink
              to={`/v2/revista/${periodicId}/${editionsRequest.result[0].editionNumId}`}
              onClick={() => window.scrollTo(0, 0)}>
              {currentEdition.title}
            </NavLink>
          </h2>
          <h3>{getEditionInfo(currentEdition)}</h3>
        </SummaryTitleContainer>
      }
      Summary={
        <PeriodicEditionItemList
          //@ts-ignore
          selectedItemId={String(currentItem.id)}
        />
      }
      keywords={keywords}
      pdfUrl={pdfUrl}
      title={`${currentEdition.periodicTitle} - Número ${currentEdition.editionNumber}`}
      subtitle={currentItem.title}
      nextTitle={next ? next.title : null}
      prevTitle={prev ? prev.title : null}
      nextContentLink={
        next ? `/v2/revista/${periodicId}/${editionId}/${next.id}` : null
      }
      prevContentLink={
        prev ? `/v2/revista/${periodicId}/${editionId}/${prev.id}` : null
      }
      result={getContentSearchResult(currentItem, editionsRequest.result[0])}
    />
  );
};

const SummaryTitleContainer = styled.div`
  h2 {
    margin-bottom: 0.5rem;
    a {
      &:visited {
        color: var(--primary-color) !important;
      }
    }
  }

  h3 {
    margin-bottom: 3rem;
  }
`;
