/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable indent */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-throw-literal */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */

import _get from 'lodash/get';
import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { ErrorMessage } from '../../../components/ErrorMessage';
import { FullscreenOverlay } from '../../../components/_shell/FullscreenOverlay';
import { Loading } from '../../../components/_shell/Loading';
import { ContentType } from '../../../enums/ContentType';
import { ContentSearchResult } from '../../../models/ContentSearchResult';
import ReadPage from '../../leitura/ReadPage';
import moment from 'moment';
import useInformativeRequest from './hooks/search/useInformativeRequest';

export const ReadInformativeView = () => {
  const match = useRouteMatch();
  const informativeType = _get(match, 'params.informativeType');
  const informativeId = _get(match, 'params.informativeId');

  const { data, loading, request } = useInformativeRequest();

  useEffect(() => {
    request(informativeType, informativeId);
  }, []);

  // if (articlesRequest.error)
  //   return <ErrorMessage error={articlesRequest.error} />;

  if (loading) {
    return (
      <FullscreenOverlay style={{ background: '#cecece' }}>
        <Loading />
      </FullscreenOverlay>
    );
  }

  if (!data && !loading) return <ErrorMessage />;

  const pdfUrl = data.pdf || '';

  function getContentSearchResult(data: any) {
    const stage = process.env.REACT_APP_STAGE || 'staging';
    // const imageSrc = `https://bid1006-${stage}-public.s3.sa-east-1.amazonaws.com/periodics/cover/${periodicId}/${periodicId}.jpg`;
    const imageSrc = '';
    const editionInfo = '';

    return new ContentSearchResult(
      ContentType.INFORMATIVE,
      Number(String(data.num_id).replaceAll('-', '')),
      `Informativo ${
        String(data.type).charAt(0).toUpperCase() + String(data.type).slice(1)
      }: ${moment(data.publish_date).format('DD/MM/YYYY')}`,
      `Informativo ${
        String(data.type).charAt(0).toUpperCase() + String(data.type).slice(1)
      }`,
      '',
      null,
      null,
      null,
      imageSrc || null,
      `${
        String(data.type).charAt(0).toUpperCase() + String(data.type).slice(1)
      }`,
      null,
      editionInfo,
      null,
      null,
      null,
      `/v2/informativo/${data.type}/${data.num_id}`,
      String(data.num_id)
    );
  }

  return (
    <ReadPage
      //@ts-ignore
      keywords={[]}
      pdfUrl={pdfUrl}
      title={`Informativo: ${moment(data.publish_date).format('DD/MM/YYYY')}`}
      subtitle={''}
      nextTitle={null}
      prevTitle={null}
      result={getContentSearchResult(data)}
    />
  );
};

const SummaryTitleContainer = styled.div`
  h2 {
    margin-bottom: 0.5rem;
    a {
      &:visited {
        color: var(--primary-color) !important;
      }
    }
  }

  h3 {
    margin-bottom: 3rem;
  }
`;
