import React from 'react';
import { Book } from '../../interfaces/Book';

import CalendarIcon from '../../../../../assets/images/calendar-icon-4.svg';
import { BookInfoImg, BookInnerFields } from '../../styles';

export const Year = ({ item }) => {
  const year = (el: Book) => new Date(el.publish_date).getUTCFullYear();
  const editionNumber =
    item && item.edition_number ? `Edição ${item.edition_number}, Ano ` : '';
  return (
    <BookInnerFields>
      <BookInfoImg src={CalendarIcon}></BookInfoImg>
      <span>
        {editionNumber}
        {year(item)}
      </span>
    </BookInnerFields>
  );
};
