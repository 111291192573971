import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import _get from 'lodash/get';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router';
import { ErrorMessage } from '../../../components/ErrorMessage';
import { FullscreenOverlay } from '../../../components/_shell/FullscreenOverlay';
import { Loading } from '../../../components/_shell/Loading';
import { ContentType } from '../../../enums/ContentType';
import { BookContextModel } from '../../../models/book/BookContextModel';
import { BookEditionChapterModel } from '../../../models/book/BookEditionChapterModel';
import { BookEditionModel } from '../../../models/book/BookEditionModel';
import { ContentSearchResult } from '../../../models/ContentSearchResult';
import { BookContext } from './BookContextProvider';
import { BookEditionChapterList } from './BookEditionChapterList';
import ReadPage from '../../leitura/ReadPage';
import slug from '../_shared/slug';

const SummaryTitleContainer = styled.div`
  h2 {
    margin-bottom: 0.5rem;
    a {
      &:visited {
        color: var(--primary-color) !important;
      }
    }
  }

  h3 {
    margin-bottom: 3rem;
  }
`;

export const ReadChapterView = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const bookId = _get(match, 'params.bookId');
  const editionId = _get(match, 'params.editionId');
  const chapterId = _get(match, 'params.chapterId');
  const queryparams = qs.parse(location.search);

  const ctx = useContext<{ state: BookContextModel }>(BookContext as any);

  const {
    editionChaptersRequest,
    bookEditionsRequest,
    editionChapterSignedUrlRequest
  } = ctx.state;

  useEffect(() => {
    const itemsCurrent = (editionChaptersRequest.result ||
      []) as BookEditionChapterModel[];
    if (editionId && itemsCurrent && chapterId) {
      const itemCurrent = itemsCurrent.find(
        (e) => String(e.id) === String(chapterId)
      );
      if (!itemCurrent) return;
      const queryParams = new URLSearchParams(location.search);
      let queryParamsString = '';
      if (queryParams.get('origin')) {
        queryParamsString = `?origin=${queryParams.get('origin')}`;
      }
      history.replace(
        `/v2/livro/${bookId}/${editionId}/${itemCurrent.id}${
          itemCurrent.title
            ? `/${slug(itemCurrent.title)}${queryParamsString}`
            : ''
        }`
      );
      return;
    }
  }, [editionChaptersRequest.result]);

  if (editionChaptersRequest.error)
    return <ErrorMessage error={editionChaptersRequest.error} />;

  if (
    editionChaptersRequest.loading ||
    bookEditionsRequest.loading ||
    editionChapterSignedUrlRequest.loading
  ) {
    return (
      <FullscreenOverlay style={{ background: '#cecece' }}>
        <Loading />
      </FullscreenOverlay>
    );
  }

  const chapters = editionChaptersRequest.result as BookEditionChapterModel[];
  if (!chapters) return <ErrorMessage />;

  const currentEdition = bookEditionsRequest.result.find(
    (e) => String(e.editionId) === String(editionId || '')
  );

  if (!currentEdition) return <ErrorMessage />;

  const currentIndex = chapters.findIndex(
    (e) => String(e.id) === String(chapterId)
  );

  if (currentIndex < 0) return <ErrorMessage />;

  const currentChapter = chapters[currentIndex];
  const next = chapters[currentIndex + 1];
  const prev = chapters[currentIndex - 1];

  const keywords = _get(queryparams, 'keywords', '')
    //@ts-ignore
    .split(' ')
    .filter((k) => !!k);

  const pdfUrl =
    editionChapterSignedUrlRequest.result &&
    editionChapterSignedUrlRequest.result.url
      ? editionChapterSignedUrlRequest.result.url
      : '';

  function getContentSearchResult(
    chapter: BookEditionChapterModel,
    book: BookEditionModel
  ) {
    return new ContentSearchResult(
      ContentType.CHAPTER,
      chapter.id,
      chapter.title,
      book.title,
      book.description,
      null,
      editionId,
      book.id,
      book.image,
      chapter.author,
      null,
      `Atualizado em: ${book.year}`,
      null,
      null,
      book.areasInterest,
      `/v2/livro/${book.id}/${editionId}/${chapter.id}`,
      chapterId
    );
  }

  return (
    <ReadPage
      //@ts-ignore
      summaryTitle={
        <SummaryTitleContainer>
          <h2>
            <NavLink
              to={`/v2/livro/${bookId}/${bookEditionsRequest.result[0].editionId}`}
              onClick={() => window.scrollTo(0, 0)}>
              {currentEdition.title}
            </NavLink>
          </h2>
          <h3>{`Edição ${currentEdition.editionNumber}, ${currentEdition.editionDate} `}</h3>
        </SummaryTitleContainer>
      }
      Summary={
        <BookEditionChapterList
          //@ts-ignore
          selectedChapterId={String(currentChapter.id)}
        />
      }
      keywords={keywords}
      pdfUrl={pdfUrl}
      title={`${currentEdition.title} - Ed: ${currentEdition.editionNumber}`}
      subtitle={currentChapter.title}
      nextTitle={next ? next.title : null}
      prevTitle={prev ? prev.title : null}
      nextContentLink={
        next ? `/v2/livro/${bookId}/${editionId}/${next.id}` : null
      }
      prevContentLink={
        prev ? `/v2/livro/${bookId}/${editionId}/${prev.id}` : null
      }
      result={getContentSearchResult(
        currentChapter,
        bookEditionsRequest.result[0]
      )}
    />
  );
};
