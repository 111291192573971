import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FooterContainer, FooterLogoImg } from './globalStyles';
import LogoIcon from '../assets/images/footer-logo.svg';
import { useSearchFilter } from '../pages/pesquisa/hooks/useSearchFilter';
import { SearchFilter } from '../models/search/SearchFilter';
import { NavbarContext } from './_shell/NavbarFilters/NavbarContext';
import { BookFiltersContext } from '../pages/v2/book/BookFiltersContext';
import { VideoFiltersContext } from '../pages/v2/video/VideoFiltersContext';
import { PeriodicFiltersContext } from '../pages/v2/periodic/PeriodicFiltersContext';

const LogoButton = () => {
  const history = useHistory();
  const { setSearchString, setSearchScope } = useContext(NavbarContext);
  const { setOrder: setVideoOrder } = useContext(VideoFiltersContext);
  const { setOrder: setBookOrder } = useContext(BookFiltersContext);
  const { setOrder: setPeriodicOrder } = useContext(PeriodicFiltersContext);
  const { changeFilter } = useSearchFilter();
  const handleClick = () => {
    const newFilter = new SearchFilter();
    changeFilter(newFilter);
    setSearchString('');
    setSearchScope(0);
    setVideoOrder('date:desc');
    setBookOrder('date:desc');
    setPeriodicOrder('date:desc');
    setTimeout(() => history.push(`/pesquisa/todos`), 250);
  };
  return <FooterLogoImg src={LogoIcon} onClick={handleClick} />;
};

export const Footer = () => (
  <FooterContainer>
    <LogoButton />
    {/* <nav>
      {links.map((e) => (
        <Link to={e.path}>{e.title}</Link>
      ))}
    </nav> */}
  </FooterContainer>
);
