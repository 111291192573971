import { Icon } from 'antd';
import React, { useContext } from 'react';
import { AreaFilter } from './components/filters/AreaFilter';
import { AuthorFilter } from './components/filters/AuthorFilter';
import { EventFilter } from './components/filters/EventFilter';
import { SerieFilter } from './components/filters/SerieFilter';
import { DateFilter } from './components/filters/DateFilter';
import { VideoNavBarClear } from './styles';
import { VideoFiltersContext } from './VideoFiltersContext';
import styled from 'styled-components';
import { NavbarContext } from '../../../components/_shell/NavbarFilters/NavbarContext';
import { useHistory } from 'react-router';
import { GlobalFiltersContext } from '../home/GlobalFiltersContext';

export const VideoNavBarFilters: React.FC<{}> = () => {
  const history = useHistory();

  const {
    areaData,
    authorData,
    eventData,
    serieData,
    dateData,
    setAreaData,
    setAuthorData,
    setEventData,
    setSerieData,
    updateData,
    setAreaSearchValue,
    setAuthorSearchValue,
    setEventSearchValue,
    setSerieSearchValue,
    setDateData,
    setPage
  } = useContext(VideoFiltersContext);

  const { advancedData, setAdvancedData } = useContext(GlobalFiltersContext);

  const { setSearchString } = useContext(NavbarContext);

  const activeFilters = [
    ...areaData,
    ...authorData,
    ...eventData,
    ...serieData
  ].filter((e) => e.checked).length;
  const dateFilter = dateData.filter((e) => e.start || e.end).length ? 1 : 0;
  const advancedFilters = advancedData.filter((e) => e.text.length > 0).length;
  const activeCount = advancedFilters + activeFilters + dateFilter;

  const checkFalse = (e) => ({ ...e, checked: false });
  const checkRangeFalse = (e) => ({ ...e, start: false, end: false });
  const clearFilters = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('search');
    history.replace({ search: queryParams.toString() });

    // Global
    setSearchString('');
    // Video
    setAreaSearchValue('');
    setAuthorSearchValue('');
    setEventSearchValue('');
    setSerieSearchValue('');
    setAreaData(areaData.map(checkFalse));
    setAuthorData(authorData.map(checkFalse));
    setEventData(eventData.map(checkFalse));
    setSerieData(serieData.map(checkFalse));
    setDateData(dateData.map(checkRangeFalse));
    setPage(1);
    updateData();
  };

  return (
    <div style={{ display: 'flex' }}>
      <AreaFilter />
      <AuthorFilter />
      <EventFilter />
      <SerieFilter />
      <DateFilter />
      <VideoNavBarClear>
        <div
          onClick={clearFilters}
          style={{
            cursor: 'pointer'
          }}>
          <Icon
            type="delete"
            style={{ fontSize: 16, marginTop: 8 }}
            className="pointer"
          />{' '}
          <span
            style={{
              fontSize: 12,
              color: '#004c92',
              fontWeight: 'bold',
              cursor: 'pointer'
            }}>
            Limpar filtros à esquerda
          </span>
        </div>
      </VideoNavBarClear>
    </div>
  );
};
