import React from 'react';
import { ContentSearchResult } from '../models/ContentSearchResult';
import { DefaultContentListItem } from './DefaultContentListItem/DefaultContentListItem';
import { RoutesResolver } from '../util/RoutesResolver';

interface IProps {
  result: ContentSearchResult;
  hideImg?: boolean;
}

export const ChapterListItem = ({ result, hideImg, ...props }: IProps) => {
  function getCoverImage() {
    if (String(result.img).includes('production-public.s3')) return result.img;
    if (result && result.parentId && result.editionId) {
      const id = String(result.parentId).replace('L', '');
      const editionId = String(result.editionId).replace('E', '');
      return `https://bid1006-production-public.s3-sa-east-1.amazonaws.com/books/cover/L${id}/editions/E${editionId}.jpg`;
    }
    return `${result.img}`.match('http')
      ? result.img
      : `https://bid1006-production-public.s3-sa-east-1.amazonaws.com${result.img}`;
  }
  return (
    <DefaultContentListItem
      {...props}
      linkTo={RoutesResolver.getContentRoute(result)}
      linkToParent={`/livro/${result.parentId}/${result.editionId}`}
      result={{
        ...result,
        img: getCoverImage(),
        tags: [{ text: 'Capítulo', color: 'magenta' }]
      }}
      hideImg={hideImg}
    />
  );
};
