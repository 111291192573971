import { Spin } from 'antd';
import React from 'react';

export const InformativesLoading = () => {
  return (
    <div className="loading-container">
      <Spin tip="Carregando" size="large" />
    </div>
  );
};
