import { Button, Icon, Modal } from 'antd';
import React from 'react';
import { FolderSelectionModal } from '../../../../../components/ContentMenu/FolderSelection/FolderSelectionModal';
import { ContentType } from '../../../../../enums/ContentType';
import { ContentSearchResult } from '../../../../../models/ContentSearchResult';
import { ArticleType } from '../../../../pesquisa/enums/ArticleType';
import moment from 'moment';

export const AddToFolder = ({ options }) => {
  const getResult = (it: any) => {
    if (!Object.keys(it).length) return;
    const date =
      it && it.publish_date
        ? moment(it.publish_date).format('DD/MM/YYYY')
        : '-';
    const type =
      String(it.type).charAt(0).toUpperCase() + String(it.type).slice(1);
    return {
      url: `/v2/informativo/${it.type}/${it.id}`,
      subTitle: `Informativo ${type}`,
      formattedDate: '',
      total: 0,
      parentId: it.id,
      editionId: '',
      relevantWords: null,
      contentType: ContentType.INFORMATIVE,
      articleType: ArticleType.TODOS,
      id: Number(String(it.id).replace('-', '')),
      title: `Informativo ${type}: ${date}`,
      text: '',
      tags: [{ text: 'Informativo', color: '' }],
      img: null,
      author: '',
      areasInterest: ''
    };
  };

  const close = () => {
    options.setShowAddToFolder(false);
  };

  const footer = [
    <Button type={'primary'} key={'btn-1'} onClick={close}>
      <Icon type="close" />
      Fechar
    </Button>
  ];

  const result: ContentSearchResult = getResult(options.folderItem);

  return (
    <Modal
      maskClosable={true}
      title="Adicionar à pasta"
      visible={options.showAddToFolder}
      onCancel={close}
      onOk={close}
      footer={footer}
      maskStyle={{ background: 'rgba(0,0,0,.2)' }}>
      <FolderSelectionModal
        result={result}
        visible={options.showAddToFolder}
        onCancel={close}
      />
    </Modal>
  );
};
