import React from 'react';
import { Button, Icon, Modal } from 'antd';

export const Bibliographic = ({ options }) => {
  const close = () => options.setShowBibliografy(false);

  const footer = [
    <Button key="btn-1" type={'primary'} onClick={close}>
      <Icon type="close" />
      Fechar
    </Button>
  ];

  return (
    <Modal
      maskClosable={true}
      title="Referência Bibliográfica"
      visible={options.showBibliografy}
      onCancel={close}
      onOk={close}
      footer={footer}
      maskStyle={{ background: 'rgba(0,0,0,.2)' }}>
      {options.bibliografyItem.length > 0 ? (
        <div
          dangerouslySetInnerHTML={{ __html: options.bibliografyItem }}></div>
      ) : (
        'Carregando...'
      )}
    </Modal>
  );
};
