import React, { useState } from 'react';
import { Book } from '../../interfaces/Book';

import TagIcon from '../../../../../assets/images/icon-tag.svg';
import { BookInfo, BookInnerFields, BookPopoverContent } from '../../styles';
import { Button, Icon, Popover } from 'antd';

export const Tags = ({ item }) => {
  const [keywordsPopover, setKeywordsPopover] = useState(false);
  const handleKeywordsPopover = () => setKeywordsPopover(!keywordsPopover);

  const tags = (el: Book) =>
    el.tags
      .filter((e) => e.id.includes('kwrd'))
      .map((e) => e.title)
      .join(', ');
  const keywords = tags(item);

  const contentPopover = (
    <div>
      <BookPopoverContent>{keywords}</BookPopoverContent>
    </div>
  );

  return keywords ? (
    <Popover
      trigger="click"
      placement="bottom"
      content={contentPopover}
      getPopupContainer={(triggerNode) => triggerNode}>
      <BookInnerFields>
        <BookInfo className="info-keywords">
          <img src={TagIcon} />
          <span>
            {keywords.length > 150 ? `${keywords.slice(0, 150)}...` : keywords}{' '}
          </span>
          <div>
            <Button size="small" color="primary" style={{ marginLeft: 5 }}>
              <Icon type={'eye'} style={{ fontSize: 14, cursor: 'pointer' }} />
            </Button>
          </div>
        </BookInfo>
      </BookInnerFields>
    </Popover>
  ) : (
    <></>
  );
};
