import React from 'react';
import CalendarIcon from '../../../../../assets/images/calendar-icon-4.svg';
import { VideoInnerFields, VideoInfoImg } from '../../styles';
import { dateShort } from '../../../../../util/i18n';

export const Year = ({ item }) => (
  <VideoInnerFields>
    <VideoInfoImg src={CalendarIcon} />
    <span>{dateShort(item.event_date)}</span>
  </VideoInnerFields>
);
