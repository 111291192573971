import React, { useContext } from 'react';
import { Select } from 'antd';
import { PeriodicOrder } from '../../styles';
import { PeriodicFiltersContext } from '../../PeriodicFiltersContext';
import {
  OrderType,
  sortByOptions
} from '../../../../../components/v2/searchHelpers';
import { GlobalFiltersContext } from '../../../home/GlobalFiltersContext';

export const OrderFilter = () => {
  const { Option } = Select;

  const { order, setOrder, updateData, setPage } = useContext(
    PeriodicFiltersContext
  );
  const { advancedData } = useContext(GlobalFiltersContext);

  const validAdvanced =
    advancedData && advancedData.filter
      ? advancedData.filter((e) => e.text.length > 0)
      : [];
  const validAdvanceds = validAdvanced.length > 0;
  sortByOptions[0].show = validAdvanceds;

  const onSelect = (val: OrderType) => {
    if (setOrder) setOrder(val);
    setPage(1);
    if (updateData) updateData();
  };

  return (
    <PeriodicOrder>
      <Select
        optionLabelProp="title"
        filterOption={false}
        onSelect={onSelect}
        value={order}>
        {sortByOptions
          .filter((e) => e.show)
          .map((d, i) => (
            <Option key={i} value={d.value} title={d.title}>
              {d.title}
            </Option>
          ))}
      </Select>
    </PeriodicOrder>
  );
};
